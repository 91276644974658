import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { CMSMainNotification } from '@models/notification';


@Component({
  selector: 'cms-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss']
})
export class NotificationItemComponent {

  @Input()
  notification: CMSMainNotification;

  constructor(private router: Router) { }

  navigateToProperty(): void {
    if (this.notification.model && this.notification.model.id) {
      this.router.navigateByUrl(`/properties/${this.notification.model.id}/pipeline`);
    }
  }
}

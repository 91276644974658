import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'cms-reason-of-on-hold-dialog',
  templateUrl: './reason-of-on-hold-dialog.component.html',
  styleUrls: ['./reason-of-on-hold-dialog.component.scss']
})
export class ReasonOfOnHoldDialogComponent {
  value = '';
  form = new FormGroup({
    reasonFormControl: new FormControl('')
  });

  constructor(private dialogRef: MatDialogRef<ReasonOfOnHoldDialogComponent>) {
    this.form.controls.reasonFormControl.valueChanges.subscribe((v) => {
      this.form.setErrors(v?.length > 255 || v?.length < 10 ? { lengthInvalid: true } : null, {
        emitEvent: true
      });
      this.form.controls.reasonFormControl.setErrors(
        v?.length > 255 || v?.length < 10 ? { lengthInvalid: true } : null,
        { emitEvent: true }
      );
    });
  }

  putOnHold(): void {
    if (
      !this.form.controls.reasonFormControl.errors &&
      this.form.controls.reasonFormControl.dirty
    ) {
      this.dialogRef.close(this.form.controls.reasonFormControl.value);
    }
  }
}

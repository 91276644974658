<div class="modal-header">
  <h3>
    <ng-container *ngIf="isEditMode">Enter new reason message</ng-container>
    <ng-container *ngIf="!isEditMode">Reason of hold</ng-container>
  </h3>
  <mat-icon role="img"
    [mat-dialog-close]
    class="modal-header__close mat-icon notranslate material-icons mat-icon-no-color"
    aria-hidden="true">close</mat-icon>
</div>
<p *ngIf="!isEditMode"
  class="description">{{ this.data?.property?.hold?.description }}</p>
<div *ngIf="isEditMode"
  [formGroup]="form">
  <textarea class="reason-note-textarea"
    formControlName="reasonFormControl"></textarea>
  <mat-error *ngIf="this.form.controls.reasonFormControl.errors"> Reason length must be between 10 and 255 characters
  </mat-error>
</div>
<div class="modal-footer"
  cmsAccessControl
  moduleType="property"
  accessType="PUT_PROPERTY_ON_HOLD">
  <button color="accent"
    class="modal-footer__edit-button mat-flat-button mat-accent"
    (click)="toggleEdit()">
    <span *ngIf="isEditMode"
      class="mat-button-wrapper"> discard Editing </span>
    <ng-container *ngIf="!isEditMode">
      <mat-icon role="img"
        class="mat-icon notranslate mat-tooltip-trigger material-icons mat-icon-no-color"
        aria-hidden="true"> edit </mat-icon><span class="mat-button-wrapper"> Edit reason</span>
    </ng-container>
  </button>
  <button *ngIf="isEditMode"
    color="accent"
    [disabled]="this.form.controls.reasonFormControl.errors || !this.form.controls.reasonFormControl.value?.length "
    class="mat-flat-button mat-accent"
    (click)="editOnHoldMessage()"><span class="mat-button-wrapper"> Save </span>
  </button>
</div>

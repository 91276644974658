import { UserDepartment } from '@models/departments';
import { BaseEntityInfo, GeneralInfo } from './core';

export enum RolePermTitles {
  general = 'General',
  properties = 'Property',
  inspections = 'Inspections',
  projects = 'Projects',
  projectStages = 'Project Stages',
  pipelines = 'Pipeline',
  workSchedule = 'Work Schedule',
  settings = 'Settings'
}

export enum RoleRoutes {
  general = 'general',
  properties = 'properties',
  inspections = 'inspections',
  projects = 'projects',
  projectStages = 'project-stages',
  pipelines = 'pipelines',
  workSchedule = 'work-schedule',
  settings = 'settings'
}

export interface RoleBaseInfo extends BaseEntityInfo {
  order_number: number;
  name: string;
  report_to?: Omit<RoleBaseInfo, 'report_to' | 'department'>;
  department: GeneralInfo;
  count_users: number;
  count_departments: number;
}

export interface RoleShortBaseInfo {
  department_id?: number;
  name?: string;
  report_to?: number;
}

export interface RoleBaseFormState {
  valid: boolean;
  roleName: string;
  department: GeneralInfo;
  reportingRole: GeneralInfo;
}

export interface RolePermission {
  id: number;
  guard_name: string;
  guard_key?: string;
  access_type?: RoleAccessTypes;
  flip_stage?: boolean;
  is_enabled?: boolean;
  is_deleted?: boolean;
}

export enum RoleAccessTypes {
  edit = 'Edit',
  view = 'View',
  none = 'None',
  delete = 'Delete'
}

export interface RolePermissionForField extends RolePermission {
  items?: RolePermission[];
}

export interface RoleWithDepartment extends BaseEntityInfo {
  name: string;
  order_number: number;
  department: UserDepartment;
}

import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { NotificationsDataService } from '@core/notifications/notifications-data.service';
import { CMSFirebaseService } from '@core/firebase.service';
import { debounceTime } from 'rxjs/operators';


@Component({
  selector: 'cms-notification-bell',
  templateUrl: './notification-bell.component.html',
  styleUrls: ['./notification-bell.component.scss']
})
export class NotificationBellComponent implements OnDestroy {

  notificationsCount = 0;

  @Output()
  isClicked = new EventEmitter<void>();

  private subscription: Subscription = new Subscription();

  constructor(
    private notificationsDataService: NotificationsDataService,
    private cmsFirebaseService: CMSFirebaseService
  ) {
    this.subscription.add(
      this.notificationsDataService.unreadCount
        .subscribe((data: number) => this.notificationsCount = data)
    );

    this.notificationsDataService.fetchNotificationsCount();

    this.subscription.add(
      this.cmsFirebaseService.angularFireMessaging.messages
        .pipe(debounceTime(500))
        .subscribe(() => this.notificationsDataService.fetchNotificationsCount())
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { PermissionsService } from '@core/permissions.service';

@Injectable()
export class UserPermissionsResolver implements Resolve<boolean> {

  constructor(
    private permissionsService: PermissionsService
  ) { }

  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.permissionsService.resetPermissions();
  }
}

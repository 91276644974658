import { Injectable } from '@angular/core';
import { User } from '@models/user/user';
import { BehaviorSubject, Observable } from 'rxjs';

import { AssigningFilterValues } from '@models/filters';

@Injectable()
export class AssigningFilterService {

  protected filterSubject: BehaviorSubject<AssigningFilterValues> = new BehaviorSubject(AssigningFilterService.getDefaultValue());
  protected filterTeammatesSubject: BehaviorSubject<User[]> = new BehaviorSubject([]);

  get assigningFilter(): Observable<AssigningFilterValues> {
    return this.filterSubject.asObservable();
  }

  set assignedFilter(filter: AssigningFilterValues) {
    this.filterSubject.next(filter);
    AssigningFilterService.saveIntoStorage(filter);
  }

  get filterTeammates(): Observable<User[]> {
    return this.filterTeammatesSubject.asObservable();
  }

  set filteredTeammates(teammates: User[]) {
    this.filterTeammatesSubject.next(teammates);
  }

  static getFromStorage(): AssigningFilterValues {
    return localStorage.getItem('cms-assigned-to') as AssigningFilterValues;
  }

  static getDefaultValue(
    ignoredValue: AssigningFilterValues = 'team-members',
    replaceTo: AssigningFilterValues = 'team',
    defaultValue: AssigningFilterValues = 'all'
  ): AssigningFilterValues {
    const value: AssigningFilterValues = AssigningFilterService.getFromStorage() || defaultValue;
    return value === ignoredValue ? replaceTo : value;
  }

  protected static saveIntoStorage(value: AssigningFilterValues): void {
    localStorage.setItem('cms-assigned-to', value);
  }
}

import { Injectable } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MainMenuGroup, MainMenuItem } from '@models/main-menu';

@Injectable()
export class MainMenuService {
  readonly menu: MainMenuGroup[] = [
    {
      name: 'Work',
      items: [
        { permission: 'property', name: 'Properties', url: '/properties', icon: { name: 'home_work' } },
        { permission: 'inspections', name: 'Inspections', url: '/inspections', icon: { name: 'find_in_page' } },
        { permission: 'projects', name: 'Projects', url: '/projects', icon: { name: 'folder_special' } },
        { permission: 'pipeline', name: 'Pipeline', url: '/pipelines', icon: { name: 'fact_check'} },
        { permission: 'workSchedule', name: 'Work Schedule', url: '/work-schedule', icon: { name: 'date_range' } },
        { permission: 'FORCE_ALLOWING_PERMISSION', name: 'Daily Images', url: '/daily-images-scheduler', icon: { name: 'photo_library' } },
      ],
      isExpanded: true
    },
    {
      name: 'Management',
      items: [
        { permission: 'settings', name: 'Settings', url: '/settings', icon: { name: 'settings' } }
      ],
      isExpanded: true
    }
  ];

  readonly defaultMenuItem = { name: 'Settings', url: '/settings', icon: { name: 'settings' } }; // TODO: Maybe we need refactor it

  private mainMenuShownSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private activeMenuItemSubject: BehaviorSubject<MainMenuItem> =
    new BehaviorSubject({ permission: 'projects', name: 'Projects', url: '/projects', icon: { name: 'folder_special' } });

  constructor(private router: Router) {
    this.router.events.pipe(filter((event: RouterEvent) => (event instanceof NavigationEnd)))
      .subscribe((event: NavigationEnd) => {
        let activeMenuItem: MainMenuItem = null;

        this.menu.some((group: MainMenuGroup) => {
          activeMenuItem = group.items.find((item: MainMenuItem) =>
            event.urlAfterRedirects.toLowerCase().includes(item.url.toLowerCase()));

          return activeMenuItem || null;
        });

        activeMenuItem = activeMenuItem || this.defaultMenuItem;

        this.setActiveItem(activeMenuItem);
      });
  }

  isMainMenuShown(): Observable<boolean> {
    return this.mainMenuShownSubject.asObservable();
  }

  getActiveItem(): Observable<MainMenuItem> {
    return this.activeMenuItemSubject.asObservable();
  }

  setActiveItem(item: MainMenuItem): void {
    this.activeMenuItemSubject.next(item);
  }

  isItemActive(itemName: string): boolean {
    const activeItem = this.activeMenuItemSubject.value;
    return activeItem && activeItem.name === itemName;
  }

  toggleMainMenu(): void {
    this.mainMenuShownSubject.next(!this.mainMenuShownSubject.value);
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { User } from '@models/user/user';
import { MainMenuService } from '@core/main-menu/main-menu.service';
import { MainMenuItem } from '@models/main-menu';
import { LayoutService } from '@core/layout.service';
import { UserService } from '@core/user.service';
import { AuthService } from '@core/auth/auth.service';
import { AUTOCOLLAPSESCREENWIDTH } from '@properties/property/desktop/desktop-property/desktop-property.component';

@Component({
  selector: 'cms-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit, OnDestroy {
  selfInfo: Partial<User>;
  isDesktop = true;
  isMobile = false;
  isLogOutBtnPressed = false;
  isMainMenuShown = false;
  activeMenuItem: MainMenuItem = null;
  private subscription: Subscription = new Subscription();

  constructor(
    public menuService: MainMenuService,
    private userService: UserService,
    private authService: AuthService,
    private layoutService: LayoutService
  ) {
    this.closeMainMenuIfNeeded(window.innerWidth);
  }

  ngOnInit(): void {
    this.isMobile = this.layoutService.isMobile;

    this.isDesktop = this.layoutService.isDesktop;
    if (!this.isDesktop) {
      this.getSelfInfo();
    }

    this.subscription.add(
      this.menuService.isMainMenuShown().subscribe((isShown: boolean) => {
        this.isMainMenuShown = isShown;
      })
    );

    this.subscription.add(
      this.menuService
        .getActiveItem()
        .subscribe((item: MainMenuItem) => (this.activeMenuItem = item))
    );
  }

  getSelfInfo(): void {
    this.userService
      .loadSelfInfo()
      .subscribe(({ fullName, avatarUrl }) => (this.selfInfo = { fullName, avatarUrl }));
  }

  logOut(): void {
    if (this.isLogOutBtnPressed) {
      return;
    }

    this.isLogOutBtnPressed = true;
    this.authService.logOut();
  }

  clickMainMenuItem(): void {
    if (!this.layoutService.isDesktop) {
      this.menuService.toggleMainMenu();
    }
  }

  closeMainMenuIfNeeded(width: number): void {
    if (width < AUTOCOLLAPSESCREENWIDTH) {
      if (this.isMainMenuShown) { this.menuService.toggleMainMenu(); }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

<div class="modal-header">
  <h3>Put the project on hold. Please note the reason</h3>
  <mat-icon role="img"
    [mat-dialog-close]
    class="modal-header__close mat-icon notranslate material-icons mat-icon-no-color"
    aria-hidden="true">close</mat-icon>
</div>
<div [formGroup]="form">
  <textarea class="reason-note-textarea"
    formControlName="reasonFormControl"></textarea>
  <mat-error *ngIf="this.form.controls.reasonFormControl.errors"> Reason length must be between 10 and 255 characters
  </mat-error>
</div>
<div class="modal-footer">
  <button color="accent"
    [disabled]="this.form.controls.reasonFormControl.errors || ! this.form.controls.reasonFormControl.dirty "
    class="mat-flat-button mat-accent"><span class="mat-button-wrapper"
      (click)="putOnHold()"> Put on hold </span>
  </button>
</div>

import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { ToasterData } from '@models/toaster';

@Component({
  selector: 'cms-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss']
})
export class ToasterComponent {

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: ToasterData,
    private toasterRef: MatSnackBarRef<ToasterComponent>
  ) { }

  close(): void {
    this.toasterRef.dismiss();
  }

  action(): void {
    this.toasterRef.dismissWithAction();
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class HeaderTabsService {

  private currentStatusSubject = new BehaviorSubject<any>(null);

  currentStatus(): Observable<any> {
    return this.currentStatusSubject.asObservable();
  }

  selectStatusTab(status: any): void {
    this.currentStatusSubject.next(status);
  }
}

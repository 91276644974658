import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { LayoutService } from '@core/layout.service';
import { PermissionsService } from '@core/permissions.service';
import { PropertyStateService } from '../../property-state.service';
import { PropertyService } from '../../property.service';
import { AbstractPropertyNotesComponent } from '../abstract-property-notes';


@Component({
  selector: 'cms-property-notes',
  templateUrl: './property-notes.component.html',
  styleUrls: ['../shared-property-notes.scss', './property-notes.component.scss']
})
export class PropertyNotesComponent extends AbstractPropertyNotesComponent {

  moduleType = 'projects';
  addAccessType = 'ADD_NOTES';
  editAccessType = 'EDIT_NOTES';
  deleteAccessType = 'DELETE_NOTES';

  canAddNote = false;
  canBeUpdated = false;

  constructor(
    propertyService: PropertyService,
    layoutService: LayoutService,
    dialog: MatDialog,
    permissionsService: PermissionsService,
    propertyStateService: PropertyStateService
  ) {
    super(propertyService, layoutService, dialog, permissionsService, propertyStateService);
  }

  protected fetchNotes(): void {
    this.canAddNote = this.permissionsService.hasPermission(this.moduleType)(this.addAccessType)();

    this.canBeUpdated = this.permissionsService.hasPermission(this.moduleType)(this.editAccessType)() ||
      this.permissionsService.hasPermission(this.moduleType)(this.deleteAccessType)();

    super.fetchNotes();
  }
}

import { Directive, ElementRef, Input, OnInit, OnDestroy, Renderer2, RendererStyleFlags2 } from '@angular/core';
import { Subscription } from 'rxjs';

import { PermissionsService } from '@core/permissions.service';
import { RoleAccessTypes } from '@models/roles';

@Directive({
  selector: '[cmsAccessControl]'
})
export class AccessControlDirective implements OnInit, OnDestroy {

  @Input()
  moduleType: string;

  @Input()
  accessType?: string | string[];

  @Input()
  specificAccess?: RoleAccessTypes;

  private subscription: Subscription = new Subscription();

  constructor(
    private elementRef: ElementRef,
    private renderer2: Renderer2,
    private permissionsService: PermissionsService
  ) { }

  ngOnInit(): void {
    this.checkPermissions();

    this.subscription.add(
      this.permissionsService.permissionStateReload
        .subscribe(() => this.checkPermissions())
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private checkPermissions(): void {
    // tslint:disable-next-line:no-bitwise
    const flags = RendererStyleFlags2.DashCase | RendererStyleFlags2.Important;
    this.renderer2.setStyle(this.elementRef.nativeElement, 'display', 'none', flags );
    if (this.permissionsService.isPermissionsInited) {
      this.checkAccess();
    } else {
      this.permissionsService.resetPermissions()
        .subscribe(() => this.checkAccess());
    }
  }

  private checkAccess(): void {
    let access = false;

    if (Array.isArray(this.accessType)) {
      access = (this.accessType as Array<string>)
        .some(type => this.permissionsService.hasPermission(this.moduleType)(type)(this.specificAccess));
    } else {
      access = this.permissionsService.hasPermission(this.moduleType)(this.accessType as string)(this.specificAccess);
    }
    // tslint:disable-next-line:no-bitwise
    const flags = RendererStyleFlags2.DashCase | RendererStyleFlags2.Important;
    if ( access || this.accessType === 'FORCE_ALLOWING_PERMISSION' || this.moduleType === 'FORCE_ALLOWING_PERMISSION' ){
      this.renderer2.setStyle(this.elementRef.nativeElement, 'display', 'flex');
    }else{
      this.renderer2.setStyle(this.elementRef.nativeElement, 'display', 'none!important', flags);
    }
  }
}

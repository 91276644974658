import { Component } from '@angular/core';

@Component({
  selector: 'cms-copyright',
  templateUrl: './copyright.component.html',
  styleUrls: ['./copyright.component.scss']
})
export class CopyrightComponent {

  readonly currentYear;

  constructor() {
    this.currentYear = (new Date()).getFullYear();
  }

}

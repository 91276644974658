import { Component } from '@angular/core';
import { ExportFormat } from '@models/core';
import { LayoutService } from '@core/layout.service';

export interface Task {
  preConName: string;
  preConSubtasks?: any;
  conName: string;
  conSubtasks?: any;
  setName: string;
  setSubtasks?: any;
}

@Component({ template: '' })
export abstract class AbstractReportDialogComponent {

  pageTypes = {
    currentStage: 'No data for current stage',
    whole: 'Whole Document',
    specific: 'Specific Stages'
  };

  task: Task = {
    preConName: 'Pre Construction',
    preConSubtasks: [
      { preConName: 'New', completed: false },
      { preConName: 'Drawing', completed: false },
      { preConName: 'Budget', completed: false },
      { preConName: 'Cashout', completed: false }
    ],
    conName: 'Construction',
    conSubtasks: [
      { conName: 'Construction Started', completed: false },
      { conName: 'Purchased', completed: false },
      { conName: 'Listed', completed: false },
      { conName: 'Construction Completed', completed: false }
    ],
    setName: 'Settlement',
    setSubtasks: [
      { setName: 'HI Received', completed: false },
      { setName: 'HI Completed', completed: false },
      { setName: 'Settled', completed: false },
    ]
  };

  allPreConComplete = false;
  allConComplete = false;
  allSetComplete = false;
  selectedPageType: any;
  exportFormat: ExportFormat = ExportFormat.xlsx;
  currentStage: string;

  constructor(
    public layoutService: LayoutService,
  ) {
    this.selectAll();
  }

  updatePreConAllComplete(): void {
    this.allPreConComplete = this.task.preConSubtasks != null && this.task.preConSubtasks.every(t => t.completed);
  }
  updateConAllComplete(): void {
    this.allConComplete = this.task.conSubtasks != null && this.task.conSubtasks.every(t => t.completed);
  }
  updateSetAllComplete(): void {
    this.allSetComplete = this.task.setSubtasks != null && this.task.setSubtasks.every(t => t.completed);
  }

  somePreConComplete(): boolean {
    if (this.task.preConSubtasks == null) {
      return false;
    }
    return this.task.preConSubtasks.filter(t => t.completed).length > 0 && !this.allPreConComplete;
  }
  someConComplete(): boolean {
    if (this.task.conSubtasks == null) {
      return false;
    }
    return this.task.conSubtasks.filter(t => t.completed).length > 0 && !this.allConComplete;
  }
  someSetComplete(): boolean {
    if (this.task.setSubtasks == null) {
      return false;
    }
    return this.task.setSubtasks.filter(t => t.completed).length > 0 && !this.allSetComplete;
  }


  setAllPreCon(completed: boolean): void {
    this.allPreConComplete = completed;
    if (this.task.preConSubtasks == null) {
      return;
    }
    this.task.preConSubtasks.forEach(t => t.completed = completed);
  }
  setAllCon(completed: boolean): void {
    this.allConComplete = completed;
    if (this.task.conSubtasks == null) {
      return;
    }
    this.task.conSubtasks.forEach(t => t.completed = completed);
  }
  setAllSet(completed: boolean): void {
    this.allSetComplete = completed;
    if (this.task.setSubtasks == null) {
      return;
    }
    this.task.setSubtasks.forEach(t => t.completed = completed);
  }

  selectAll(event?: Event): void {
    if (event) {
      event.stopPropagation();
    }

    this.setAllPreCon(true);
    this.setAllCon(true);
    this.setAllSet(true);
  }
  deselectAll(event?: Event): void {
    if (event) {
      event.stopPropagation();
    }

    this.setAllPreCon(false);
    this.setAllCon(false);
    this.setAllSet(false);
  }

  selectFormat(event): void {
    this.exportFormat = event.source.value;
  }

  public prepareConstructionStages(): string[] {
    const params = [];

    this.task.conSubtasks.forEach(item => item.completed && params.push(item.conName));
    this.task.preConSubtasks.forEach(item => item.completed && params.push(item.preConName));
    this.task.setSubtasks.forEach(item => item.completed && params.push(item.setName));

    return params;
  }

  public checkExportEmptyState(): boolean {
    const checkState = this.prepareConstructionStages();
    return checkState.length === 0 && this.selectedPageType !== this.pageTypes.whole;
  }
}

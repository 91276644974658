<div class="number-input-with-arrows" (mouseleave)="onMouseLeave()">
  <button mat-flat-button (click)="onDecrementValue()">
    <mat-icon>keyboard_arrow_down</mat-icon>
  </button>

  <input type="number" matInput [formControl]="control" (change)="onInputChange()" />

  <button mat-flat-button (click)="onIncrementValue()">
    <mat-icon>keyboard_arrow_up</mat-icon>
  </button>
</div>

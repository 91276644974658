import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, flatMap, switchMap, take } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { CMSFirebaseService } from '@core/firebase.service';
import { PermissionsService } from '@core/permissions.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private authService: AuthService,
    private cmsFirebaseService: CMSFirebaseService,
    private permissionsService: PermissionsService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes('login') || !this.authService.isLoggedIn) {
      return next.handle(req);
    }

    return next.handle(this.cloneRequestWithToken(req))
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.status !== 401) {
            return throwError(err);
          }

          if (err.error.message !== 'The access token is expired.') {
            this.removeTokensAndRedirectToLogin();

            return throwError(err);
          }

          const refreshToken = this.authService.refreshToken;
          if (!refreshToken) {
            this.removeTokensAndRedirectToLogin();
          }

          if (this.refreshTokenInProgress) {
            return this.refreshTokenSubject
              .pipe(
                filter(result => result),
                take(1),
                switchMap(() => {
                  return next.handle(this.cloneRequestWithToken(req));
                })
              );
          }

          this.switchRequestTokenState(true);

          return this.authService.refreshTokens(refreshToken)
            .pipe(
              catchError(refreshErr => {
                this.removeTokensAndRedirectToLogin();

                return refreshErr;
              }),
              flatMap(() => {
                this.switchRequestTokenState(false);

                return next.handle(this.cloneRequestWithToken(req))
                  .pipe(
                    catchError((error) => {
                      this.removeTokensAndRedirectToLogin();

                      return throwError(error);
                    })
                  );
                })
            );
        }));
  }

  private switchRequestTokenState(inProgress: boolean, nextValue: boolean = !inProgress): void {
    this.refreshTokenInProgress = inProgress;
    this.refreshTokenSubject.next(nextValue);
  }

  private removeTokensAndRedirectToLogin(): void {
    this.switchRequestTokenState(false, false);

    AuthService.removeTokensFromStorage();
    this.permissionsService.clearPermissionsState();
    this.cmsFirebaseService.deleteDeviceToken();

    this.authService.goToLogin();
  }

  private cloneRequestWithToken(req: HttpRequest<any>): HttpRequest<any> {
    const headers = { Authorization: `Bearer ${this.authService.authToken}` };
    req.headers.keys().forEach(key => headers[key] = req.headers.getAll(key));

    return req.clone({ headers: new HttpHeaders(headers) });
  }
}

<div class="archived-dialog">
  <div class="archived-dialog__header">
    <h3 mat-dialog-title> {{ archived ? 'Unarchiving' : 'Archiving' }} the Property</h3>
    <button mat-icon-button [mat-dialog-close]>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-dialog-content>
    <p> You are about to {{ archived ? 'unarchive' : 'archive' }} the following property:</p>
    <p><strong>{{propertyName}}</strong></p>
    <p>Are you sure?</p>
  </mat-dialog-content>

  <div mat-dialog-actions class="archived-dialog__actions">
    <button mat-stroked-button
            color="accent"
            [mat-dialog-close]>
      Cancel
    </button>
    <button *ngIf="!archived"
            mat-flat-button
            color="accent"
            (click)="archiveUnarchiveProperty('archive')">
      ARCHIVE PROPERTY
    </button>
    <button *ngIf="archived"
            mat-flat-button
            color="accent"
            (click)="archiveUnarchiveProperty('unarchive')">
      UNARCHIVE PROPERTY
    </button>
  </div>
</div>

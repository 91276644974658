import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConvertedFiltersValues } from '@models/filters';

@Injectable()
export class FiltersService {

  private filtersSubject: BehaviorSubject<ConvertedFiltersValues> = new BehaviorSubject({});

  static fetchItemsIds(array: any[]): string[] {
    return array.map(item => item.id.toString());
  }

  static fetchItemsByNames(array: any[]): string[] {
    return array.map(item => item.name.toString());
  }

  get filters(): Observable<ConvertedFiltersValues> {
    return this.filtersSubject.asObservable();
  }

  set newFilters(filters: ConvertedFiltersValues) {
    this.filtersSubject.next(filters);

  }

}

import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';

import { DepartmentsInfo } from '@models/departments';
import { RequestHelperService } from '@shared/request-helper.service';
import { ApiResponseList } from '@models/response';
import { HttpClient } from '@angular/common/http';
import { GeneralInfo } from '@models/core';
import { RoleBaseInfo } from '@models/roles';
import { ToasterService } from '@core/toaster/toaster.service';


@Injectable()
export class RoleBaseFormService {

  constructor(
    private http: HttpClient,
    private toasterService: ToasterService
  ) { }

  fetchDepartments(): Observable<GeneralInfo[]> {
    return this.http.get(RequestHelperService.apiPaths.departments.settingsList)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.status === 403) {
            this.toasterService.showToaster({ message: 'You don\'t have enough permission to get departments' });
          }
          return EMPTY;
        }),
        map((response: ApiResponseList<DepartmentsInfo[]>) =>
          response.data[0].map((department: DepartmentsInfo) => ({ id: department.id, name: department.name }))
        )
      );
  }

  fetchReportingRoles(currentRoleId?: number): Observable<GeneralInfo[]> {
    let paramsObj = {};

    if (currentRoleId || currentRoleId === 0) {
      paramsObj = { roleId: currentRoleId };
    }

    const params = new HttpParams({ fromObject: paramsObj });
    return this.http.get(`${RequestHelperService.apiPaths.departments.settingsList}/roles/available`, { params })
      .pipe(
        map((response: ApiResponseList<Omit<RoleBaseInfo, 'report_to' | 'department'>[]>) =>
          response.data[0].map((role) => ({ id: role.id, name: role.name }))
        )
      );
  }
}

import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { CMSMainNotification, CMSMainNotificationGroup } from '@models/notification';
import { ConvertedFiltersValues, NotificationsTypeValues } from '@models/filters';
import { FiltersService } from '@shared/filters/filters.service';
import { NotificationsService } from '@core/notifications/notifications.service';
import { NotificationsDataService } from '@core/notifications/notifications-data.service';
import { UserService } from '../../core/user.service';
import { filter } from 'rxjs/operators';


@Component({ template: ''})
export abstract class AbstractNotificationsComponent implements OnDestroy {

  notifications: CMSMainNotification[] = [];
  currentNotificationsType: NotificationsTypeValues = 'active';
  protected page = 0;
  protected nextPage = 1;
  protected abstract limit: number;

  protected currentFilters: ConvertedFiltersValues = {};

  protected subscription: Subscription = new Subscription();

  protected constructor(
    protected filtersService: FiltersService,
    protected notificationsService: NotificationsService,
    protected notificationsDataService: NotificationsDataService,
    protected userService: UserService
  ) {
    this.subscription.add(
      this.filtersService.filters
        .pipe(
          filter((filters) => typeof filters === 'object' && Object.keys(filters)?.length > 0) // always must be selected 'related to' so fully empty filters only on init and should be skipped
        )
        .subscribe((filters: ConvertedFiltersValues) => this.applyFilters(filters))
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  abstract loadNotifications(): void;

  protected applyFilters(filters: ConvertedFiltersValues = {}): void {
    this.page = 0;
    this.nextPage = 1;
    this.currentFilters = filters;
    this.notifications = [];
    this.loadNotifications();
  }

  protected markAsRead(notifs: CMSMainNotification[]): void {
    if (notifs?.length < 1) { return; }

    this.userService.loadSelfInfo().subscribe(
      userData => {
        const ids: number[] = notifs.filter( (current) => ! current?.is_read )
        .filter((current) => current.users?.find( user => user?.id === userData?.id ))
        .map((notif: CMSMainNotification) => notif.id);
        if (ids?.length) {
          this.notificationsService.markAsRead(ids)
            .subscribe(() => this.notificationsDataService.fetchNotificationsCount());
        }
      }
    );
  }

  selectNotificationsType(newType: NotificationsTypeValues): void {
    if ( newType !== this.currentNotificationsType ){
      window?.scrollTo(0, 0);
      this.notifications = [];
      this.currentNotificationsType = newType;
      this.page = 0;
      this.loadNotifications();
    }

  }

}

<div class="notification-item"
     [class.notification-item__nav]="notification?.model?.id"
     (click)="navigateToProperty()">
  <div class="notification-item__content notification-item__subtext">
    <div>
      Assigned To:
      <ng-container *ngIf="notification?.responsible_users?.length; else me">
        <span *ngFor="let user of notification.responsible_users; let i = index">
          {{user?.full_name}}{{notification.responsible_users.length > 1 && i < notification.responsible_users.length - 1 ? ',' : ''}}
        </span>
      </ng-container>
      <ng-template #me>
        <span>Me</span>
      </ng-template>
    </div>
    <div *ngIf="notification?.priority" class="notification-item__priority">
      Priority: <span>{{notification?.priority?.name?.toLowerCase()}}</span>
    </div>
  </div>

  <div class="notification-item__content notification-item__text">
    {{notification?.model?.full_address?.length?notification?.model?.full_address+". ":""}}{{notification?.text}}
  </div>

  <div class="notification-item__content notification-item__subtext">
    <div>
      {{notification?.created_at | notificationsDate: 'MMM d, y'}}
    </div>
    <div *ngIf="notification?.resolved_at"
      class="notification-item__subtext__done-wrapper" >
      <span class="notification-item__subtext__done text-green">
        <mat-icon> done </mat-icon>Done 
      </span>
    </div>
    <div>
      Due Date: <span>{{notification?.due_date | date: 'MMM d, y'}}</span>
    </div>
  </div>
</div>

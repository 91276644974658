import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import isEmpty from 'lodash-es/isEmpty';


@Component({
  selector: 'cms-number-input-with-arrows',
  templateUrl: './number-input-with-arrows.component.html',
  styleUrls: ['./number-input-with-arrows.component.scss']
})
export class NumberInputWithArrowsComponent {

  @Input() control: FormControl = new FormControl(0);
  @Input() isMouseleaveWait = false;
  @Input() step = 1;
  @Output() valueChange = new EventEmitter();

  onDecrementValue(): void {
    this.decrementValue();
    if (!this.isMouseleaveWait) {
      this.valueChange.emit(this.control.value);
    }
  }

  onIncrementValue(): void {
    this.incrementValue();
    if (!this.isMouseleaveWait) {
        this.valueChange.emit(this.control.value);
    }
  }

  onMouseLeave(): void {
   if (this.isMouseleaveWait) {
      this.onInputChange();
   }
  }

  onInputChange(): void {
    if (isEmpty(this.control.errors)) {
      this.valueChange.emit(this.control.value);
    }
  }

  private decrementValue(): void {
    this.control.setValue(this.control.value - this.step);

    if (this.control.errors && this.control.errors.min) {
      this.control.setValue(this.control.errors.min.min);
    }
  }

  private incrementValue(): void {
    this.control.setValue(this.control.value + this.step);

    if (this.control.errors && this.control.errors.max) {
      this.control.setValue(this.control.errors.max.max);
    }
  }
}

<cms-main-header>
  <div class="property-header flex-1">
    <div class="property-header__title">
      <h1 matTooltip="{{propertyAddress}}"
        matTooltipPosition="below">{{propertyAddress}}</h1>
      <button *ngIf="!isPropertyArchived"
        class="property-header__archive-btn"
        mat-icon-button
        cmsAccessControl
        moduleType="property"
        accessType="ARCHIVE_PROPERTIES"
        (click)="openPropertyArchiveDialog.emit()">
        <mat-icon class="property-header__archive-btn-icon"
          matTooltip="Archive"
          fontSet="material-icons-outlined"> archive </mat-icon>
      </button>
      <button *ngIf="isPropertyArchived"
        class="property-header__archive-btn"
        mat-icon-button
        cmsAccessControl
        moduleType="property"
        matTooltip="Unarchive"
        accessType="UNARCHIVE_PROPERTIES"
        (click)="openPropertyArchiveDialog.emit()">
        <mat-icon class="property-header__archive-btn-icon"
          fontSet="material-icons-outlined"> unarchive </mat-icon>
      </button>
    </div>
    <ng-content></ng-content>
    <button class="property-header__report-btn"
      mat-icon-button
      color="accent"
      cmsAccessControl
      moduleType="property"
      accessType="PROPERTIES_GENERATE_REPORTS"
      matTooltip="Report"
      matTooltipPosition="below"
      (click)="openPropertyReportDialog.emit()">
      <mat-icon fontSet="material-icons-outlined">description</mat-icon>
    </button>
  </div>
</cms-main-header>

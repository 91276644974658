<div class="page-header">
  <h2>{{title}}</h2>

  <ng-content></ng-content>

  <button mat-flat-button
          color="accent"
          [disabled]="isSaveBtnDisabled"
          (click)="saveChanges()">
    Save Changes
  </button>
</div>

<div class="change-stage-confirmation-dialog">

  <div class="change-stage-confirmation-dialog__header">
    <h3 mat-dialog-title>Flip to {{selectedStage.name}} Stage</h3>
    <button mat-icon-button [mat-dialog-close]>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-dialog-content class="change-stage-confirmation-dialog__content">
    <span>Are you sure you want to flip the project to the <strong>{{selectedStage.name}}</strong> stage? </span>
    <span *ngIf="selectedStage.required.length !== 0">These fields are not filled yet:</span>
    <div class="required-files"
         *ngFor = 'let required of selectedStage.required; let i = index'>
      {{i+1}}. {{mapperInvalidFieldNameToMessage(required)}}
    </div>

    <div *ngIf="selectedStage.required.length">
      <div class="change-stage-confirmation-dialog__label">
        <h4>Reason to override:</h4>
      </div>
      <div class="change-stage-confirmation-dialog__note-textarea-box">
        <textarea class="change-stage-confirmation-dialog__note-textarea"
                  matInput
                  [placeholder]="'Note (Required)'"
                  [formControl]="reasonFormControl">
        </textarea>
      </div>
    </div>
  </mat-dialog-content>

  <div mat-dialog-actions class="change-stage-confirmation-dialog__actions" align="end">
    <button mat-flat-button
            color="accent"
            [disabled]="isDisabled()"
            (click)="flipStage()">
      YES, FLIP
    </button>
  </div>
</div>

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NotificationsService } from '@core/notifications/notifications.service';


@Injectable()
export class NotificationsDataService {

  private unreadCountSubject: BehaviorSubject<number> = new BehaviorSubject(0);

  constructor(private notificationsService: NotificationsService) { }

  get unreadCount(): Observable<number> {
    return this.unreadCountSubject.asObservable();
  }

  get unreadCountValue(): number {
    return this.unreadCountSubject.getValue();
  }

  fetchNotificationsCount(): void {
    this.notificationsService.fetchUnreadNotificationsCount()
      .subscribe((data: number) => this.unreadCountSubject.next(data));
  }
}

import { Component, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { NotificationsDateFilter, NotificationsFilters } from '@models/notification';
import { FiltersService } from '@shared/filters/filters.service';
import { NotificationsAssigningFilterService } from '@shared/notifications/notifications-assigning-filter.service';


@Component({ template: '' })
export abstract class AbstractNotificationsFiltersComponent implements OnDestroy {

  notificationsDates: NotificationsDateFilter[] = [
    { name: 'All Notifications', value: '' },
    { name: 'Today Only', value: 'today' },
    { name: 'Past Due Only', value: 'past' }
  ];
  dateControl = new FormControl(this.notificationsDates[0]);

  protected isTeamMemberFilter = false;
  protected currentFiltersValues: NotificationsFilters = {
    relatedTo: NotificationsAssigningFilterService.getDefaultValue('all', 'team', 'me')
  };

  protected subscription: Subscription = new Subscription();

  labelMapper: (item: NotificationsDateFilter) => string = (item: NotificationsDateFilter) => item.name;

  protected constructor(
    private filtersService: FiltersService,
    private assigningFilterService: NotificationsAssigningFilterService
  ) {

    this.subscription.add(
      this.assigningFilterService.assigningFilter
        .subscribe(filter => {
          this.resetFilters();

          switch (filter) {
            case 'me':
              this.currentFiltersValues.relatedTo = 'me';
              this.filtersService.newFilters = { ...this.currentFiltersValues };
              break;
            case 'team':
              this.currentFiltersValues.relatedTo = 'team';
              this.filtersService.newFilters = { ...this.currentFiltersValues };
              break;
            case 'team-members':
              this.currentFiltersValues.relatedTo = 'team-members';
              return this.isTeamMemberFilter = true;
          }

          this.isTeamMemberFilter = false;
        })
    );

    this.subscription.add(
      this.assigningFilterService.filterTeammates
        .subscribe(teamMembers => {
          if (this.isTeamMemberFilter) {
            this.filtersService.newFilters = {
              ...this.currentFiltersValues,
              relatedTo: 'team-members',
              users: FiltersService.fetchItemsIds(teamMembers)
            };
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  resetFilters(): void {
    this.dateControl.setValue(this.notificationsDates[0]);
    this.selectDate(this.notificationsDates[0]);
  }

  applyDateFilter(date: NotificationsDateFilter): void {
    this.selectDate(date);
    this.filtersService.newFilters = { ...this.currentFiltersValues };
  }

  private selectDate(date: NotificationsDateFilter): void {
    date.value ?
      this.currentFiltersValues.display = date.value :
      delete this.currentFiltersValues.display;
  }
}

import { Component, OnInit } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

import { UserService } from '@core/user.service';
import { AssigningFilterValues } from '@models/filters';
import { User } from '@models/user/user';
import { AssigningFilterService } from '@shared/filters/assigning-filter/assigning-filter.service';

@Component({
  selector: 'cms-assigning-filter',
  templateUrl: './assigning-filter.component.html',
  styleUrls: ['./assigning-filter.component.scss']
})
export class AssigningFilterComponent implements OnInit {

  title = 'Assigned to';
  teamMemberParent = 'team';

  defaultAssignTo: AssigningFilterValues = AssigningFilterService.getDefaultValue();
  assignedTo: AssigningFilterValues = this.defaultAssignTo;

  teamMembers: User[] = [];
  selectedTeamMembers: User[] = [];

  teamMembersMapper: (teamMember: User) => string = (teamMember: User) => teamMember.fullName;

  selectedTeamMembersMapper: (teamMember: User[], allTeamMembers: User[]) => string =
    (selectedTeamMembers: User[], allTeamMembers: User[]) => {
      let teamMembersCount: string | number = 'All';
      let text = 'Team Members';
      if (selectedTeamMembers.length !== allTeamMembers.length) {
        teamMembersCount = selectedTeamMembers.length;
        text = teamMembersCount === 1 ? 'Team Member' : text;
      }

      return `${teamMembersCount} ${text}`;
    }

  constructor(
    private assigningFilterService: AssigningFilterService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    if (this.assignedTo === this.teamMemberParent) {
      this.getTeamMembers();
    }
  }

  getTeamMembers(): void {
    this.userService.fetchTeammates()
      .subscribe(teamMembers => {
        this.teamMembers = teamMembers;

        this.selectTeamMembers(teamMembers);
      });
  }

  selectTeamMembers(teamMembers: User[]): void {
    this.selectedTeamMembers = teamMembers;

    this.assigningFilterService.filteredTeammates = teamMembers;
  }

  selectAssigneeType(matToggle: MatButtonToggleChange): void {
    const filterValue: AssigningFilterValues = matToggle.value;

    this.assignedTo = filterValue;
    this.assigningFilterService.assignedFilter = filterValue;
    if (filterValue === this.teamMemberParent) {
      this.getTeamMembers();
    }
  }
}

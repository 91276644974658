import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cms-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent {

  @Input()
  title: string;

  @Input()
  isSaveBtnDisabled = false;

  @Output()
  saveChangesHandler = new EventEmitter();

  saveChanges(): void {
    this.saveChangesHandler.emit();
  }
}

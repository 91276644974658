<div *ngIf="!isDesktop && isMainMenuShown"
  class="main-menu__overlay"
  (click)="menuService.toggleMainMenu()">
</div>
<div *ngIf="isDesktop"
  class="main-menu__header main-menu__closed-header"
  (window:resize)="closeMainMenuIfNeeded($event.target.innerWidth)">
  <cms-main-menu-dropdown></cms-main-menu-dropdown>
  <cms-main-menu-toggle></cms-main-menu-toggle>
</div>
<nav class="main-menu"
  [class.main-menu__open]="isMainMenuShown"
  [class.mobile-main-menu]="isMobile">
  <div class="main-menu__header main-menu__opened-header">
    <img class="main-menu__logo"
      alt="logo"
      src="/assets/images/logo3.png"
      routerLink="/"
      (click)="clickMainMenuItem()">
    <cms-notification-bell *ngIf="isMobile"
      routerLink="/notifications"
      (click)="clickMainMenuItem()"></cms-notification-bell>
    <cms-main-menu-toggle *ngIf="isDesktop"
      [isMainMenuShown]="true"></cms-main-menu-toggle>
  </div>
  <mat-accordion class="main-menu__panel-box flex-1"
    [displayMode]="'flat'"
    [multi]="true"
    hideToggle>
    <mat-expansion-panel *ngFor="let group of menuService.menu"
      class="main-menu__panel"
      [ngClass]="{'not-desktop-panel': !isDesktop}"
      [expanded]="true"
      (opened)="group.isExpanded = true"
      (closed)="group.isExpanded = false">
      <mat-expansion-panel-header class="main-menu__panel-header">
        <mat-panel-title class="main-menu__panel-title"> {{group.name}} </mat-panel-title>
        <mat-icon *ngIf="!group.isExpanded"
          class="main-menu__icon"> arrow_drop_down </mat-icon>
        <mat-icon *ngIf="group.isExpanded"
          class="main-menu__icon"> arrow_drop_up </mat-icon>
      </mat-expansion-panel-header>
      <mat-list class="main-menu__list"
        role="list">
        <mat-list-item *ngFor="let item of group.items"
          cmsAccessControl
          [moduleType]="item.permission"
          class="main-menu__list-item"
          role="listitem"
          [class.main-menu__list-item__active]="menuService.isItemActive(item.name)"
          matRipple>
          <a class="main-menu__link"
            [routerLink]="item.url"
            (click)="clickMainMenuItem()">
            <mat-icon *ngIf="!item.icon.isSvg"
              class="main-menu__item-icon"
              fontSet="material-icons-outlined"> {{item.icon.name}} </mat-icon>
            <mat-icon *ngIf="item.icon.isSvg"
              class="main-menu__item-icon"
              fontSet="material-icons-outlined"
              [svgIcon]="item.icon.name">
            </mat-icon>
            <span>{{item.name}}</span>
          </a>
        </mat-list-item>
      </mat-list>
    </mat-expansion-panel>
    <mat-list *ngIf="!isDesktop"
      class="main-menu__list main-menu__margin-top"
      role="list">
      <mat-list-item class="main-menu__list-item"
        routerLink="/profile"
        matRipple
        (click)="clickMainMenuItem()">
        <cms-avatar class="main-menu__avatar"
          [avatarUrl]="selfInfo?.avatarUrl"
          #avatarTooltip="matTooltip"
          matTooltip="View profile"
          matTooltipPosition="below">
        </cms-avatar>
        <span class="main-menu__username">{{selfInfo?.fullName}}</span>
      </mat-list-item>
      <mat-list-item class="main-menu__list-item"
        (click)="logOut()"
        matRipple>
        <div class="main-menu__logout">
          <mat-icon class="main-menu__item-icon">logout</mat-icon>
          <span>Log Out</span>
        </div>
      </mat-list-item>
    </mat-list>
  </mat-accordion>
  <div class="main-menu__copyright">
    <cms-copyright></cms-copyright>
  </div>
</nav>

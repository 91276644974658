import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { Router, NavigationEnd } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import { ToasterConfig, ToasterData } from '@models/toaster';
import { ToasterComponent } from './toaster.component';

@Injectable()
export class ToasterService {

  private static readonly defaultToasterConfig: ToasterConfig = {
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top'
  };
  private toasterRef: MatSnackBarRef<ToasterComponent>;

  constructor(private toaster: MatSnackBar, private router: Router) { }

  showToaster(data: ToasterData, config: ToasterConfig = {}): MatSnackBarRef<ToasterComponent> {
    const toasterConfig: ToasterConfig = { ...ToasterService.defaultToasterConfig, ...config };
    this.toasterRef = this.toaster.openFromComponent(
      ToasterComponent,
      {
        data,
        panelClass: 'toaster',
        duration: toasterConfig.duration,
        horizontalPosition: toasterConfig.horizontalPosition,
        verticalPosition: toasterConfig.verticalPosition
      }
    );

    this.router.events.pipe(filter((event) => (event instanceof NavigationEnd)),
      take(1))
      .subscribe(() => {
        this.dismissToaster();
      });

    return this.toasterRef;
  }

  dismissToaster(): void {
    if (this.toasterRef) {
      this.toasterRef.dismiss();
    }
  }
}

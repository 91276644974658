<div>
  <cms-notifications-assigning-filter></cms-notifications-assigning-filter>

  <div class="notifications-filters__select-box">
    <span>Display</span>
    <cms-select class="notifications-filters__cms-select"
                appearance="outline"
                customArrowIcon="keyboard_arrow_down"
                [selectFormControl]="dateControl"
                [labelMapper]="labelMapper"
                [values]="notificationsDates"
                (selectionChanged)="applyDateFilter($event)">
    </cms-select>
  </div>
</div>

import { Component, Input, Output, EventEmitter } from '@angular/core';
import cloneDeep from 'lodash-es/cloneDeep';

export interface MultiSelectableChipsItem {
  label: string;
  value: any;
  isSelected: boolean;
}

type MultiSelectableChipsItemWidthID = MultiSelectableChipsItem & { tempId: number };

@Component({
  selector: 'cms-multi-select-chips',
  templateUrl: './multi-select-chips.component.html',
  styleUrls: ['./multi-select-chips.component.scss']
})
export class MultiSelectChipsComponent {
  @Input() set chips(inputChips: { label: string; value?: any; isSelected?: boolean }[]) {
    this.$chips = cloneDeep(inputChips);
    this.selectableItems = cloneDeep(inputChips).map((obj, i) => {
      return {
        label: obj.label,
        value: obj?.value ?? obj.label,
        tempId: i,
        isSelected: obj?.isSelected ?? false
      };
    });
    if (this.sorting) { this.selectableItems = this.selectableItems.sort(this.sorting); }
  }
  @Input() sorting: (a, b) => number;
  @Output() selectedItems: EventEmitter<MultiSelectableChipsItem[]> = new EventEmitter();
  @Output() selectedValues: EventEmitter<any[]> = new EventEmitter();
  @Output() selectedLabels: EventEmitter<string[]> = new EventEmitter();
  private $chips: { label: string; value?: any; isSelected?: boolean }[];
  public selectableItems: (MultiSelectableChipsItemWidthID)[] = [];

  chipsTracker(index: number, chip: MultiSelectableChipsItemWidthID): number {
    return chip.tempId;
  }

  toggleSelection(itemID: number): void {
    this.selectableItems.forEach((item) => {
      if (item.tempId === itemID) {
        item.isSelected = !item.isSelected;
      }
    });

    const updatedSelectableItems: (MultiSelectableChipsItemWidthID)[] = cloneDeep(
      this.selectableItems
    );
    this.selectedValues.emit(
      updatedSelectableItems.filter((item) => item.isSelected).map((item) => item.value)
    );
    this.selectedLabels.emit(
      updatedSelectableItems.filter((item) => item.isSelected).map((item) => item.label)
    );
    this.selectedItems.emit(
      updatedSelectableItems.map((item) => {
        delete item.tempId;
        return item;
      })
    );
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class PropertyHistorySidebarStateService {
  private historySidebarOpenCloseState = new BehaviorSubject<openCloseStateValue>('opened');

  get isOpened(): boolean {
    return this.historySidebarOpenCloseState.getValue() === 'opened';
  }

  toggleState(): void {
    this.historySidebarOpenCloseState.next(this.isOpened ? 'closed' : 'opened');
  }

  get stateObservable(): Observable<openCloseStateValue> {
    return this.historySidebarOpenCloseState.asObservable();
  }

  setState(newState: openCloseStateValue): void {
    this.historySidebarOpenCloseState.next(newState);
  }
}

type openCloseStateValue = 'opened' | 'closed';

import { BaseEntityInfo } from '@models/core';

export interface UserBasicInfo {
  id?: number;
  avatarUrl: string;
  fullName: string;
}

export interface User {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  avatarUrl: string;
}

export enum UserType {
  employee = 'Employee',
  vendor = 'Vendor'
}

export interface UserInfoResponse extends BaseEntityInfo {
  email: string;
  first_name: string;
  last_name: string;
  full_name: string;
  avatar: string;
}

export interface UserInfoResponseWithStatus extends UserInfoResponse {
  status: {
    status: string;
    name: string;
  };
}

export type UserToValidateStatusType =
  UserToValidateStatuses.noInCRM | UserToValidateStatuses.noInCMS | UserToValidateStatuses.inactiveInCMS | UserToValidateStatuses.ok;

export enum UserToValidateStatuses {
  noInCRM = 'CRM_STATUS_USER_IS_EMPTY',
  noInCMS = 'CRM_STATUS_NO_USER',
  inactiveInCMS = 'CRM_STATUS_INACTIVE_USER',
  ok = 'CRM_STATUS_USER_OK'
}

export enum UserToValidateStatusMessages {
  'CRM_STATUS_USER_IS_EMPTY' = 'Update this field in the CRM',
  'CRM_STATUS_NO_USER' = 'This user doesn\'t exist in CMS',
  'CRM_STATUS_INACTIVE_USER' = 'This user is inactive in CMS',
}

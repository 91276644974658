<form class="role-base-form" [formGroup]="formGroup">
  <mat-form-field class="input-form-field dialog-form-field role-base-form__name-box role-base-form__field"
                  appearance="outline">
    <mat-label class="role-base-form__name-label">Role Name</mat-label>
    <input matInput
           type="text"
           name="RoleName"
           formControlName="roleName">
    <mat-error *ngIf="formGroup.controls.roleName.hasError('required')">
      Role Name is required
    </mat-error>
  </mat-form-field>

  <cms-select class="role-base-form__field"
              appearance="outline"
              label="Department"
              customArrowIcon="keyboard_arrow_down"
              [selectFormControl]="formGroup.controls.department"
              [values]="departments"
              [labelMapper]="dataMapper">
  </cms-select>

  <cms-select class="role-base-form__field"
              appearance="outline"
              label="Reporting Role"
              customArrowIcon="keyboard_arrow_down"
              [selectFormControl]="formGroup.controls.reportingRole"
              [values]="reportingRoles"
              [labelMapper]="dataMapper">
  </cms-select>
</form>


import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { SpinnerService } from './spinner.service';

export class SpinnerInterceptor implements HttpInterceptor {
  static spinnerParamName = 'showSpinner';

  constructor(private spinnerService: SpinnerService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.shouldNotShowSpinner(req)) {
      return next.handle(this.removeSpinnerParam(req));
    }

    this.spinnerService.requestStarted();
    return next.handle(req)
      .pipe(
        tap((event) => {
          if (!(event instanceof HttpResponse)) {
            return;
          }
          this.spinnerService.requestFinished();
        }),
        catchError((err) => {
          this.spinnerService.error();
          return throwError(err);
        })
      );
  }

  private removeSpinnerParam(req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({
      headers: req.headers.delete(SpinnerInterceptor.spinnerParamName)
    });
  }

  private shouldNotShowSpinner(req: HttpRequest<any>): boolean {
    return req.headers.get(SpinnerInterceptor.spinnerParamName) === 'false';
  }
}

import { Component, Input } from '@angular/core';

import { SearchInputService } from '@shared/search-input/search-input.service';

@Component({
  selector: 'cms-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent {

  @Input()
  placeholder = 'Search...';

  constructor(private searchService: SearchInputService) { }

  onSearch(event: KeyboardEvent): void {
    const input = event.target as HTMLInputElement;
    this.searchService.searchValue = input.value;
  }

}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'cms-needs-update-in-crm',
  templateUrl: './needs-update-in-crm.component.html',
  styleUrls: ['./needs-update-in-crm.component.scss']
})
export class NeedsUpdateInCrmComponent {
  hasValue: boolean;

  @Input()
  private set needsUpdate(value: any) {
    this.hasValue = (value !== undefined) && (value !== null) && (value !== '');
  }

}

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';
import { MatExpansionPanel } from '@angular/material/expansion';

import { LayoutService } from '@core/layout.service';
import { PermissionsService } from '@core/permissions.service';
import { FlipStageValidationData } from '@models/property/property';
import { PropertyService } from '../property.service';
import {
  ChangeStageConfirmationDialogComponent
} from './change-stage-confirmation-dialog/change-stage-confirmation-dialog.component';
import { mapperInvalidFieldNameToMessage } from '../mapperOfInvalidFieldsOnFlipStage';

@Component({
  selector: 'cms-change-stage',
  templateUrl: 'change-stage-dialog.component.html',
  styleUrls: ['./change-stage-dialog.component.scss']
})
export class ChangeStageDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ChangeStageDialogComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data: { propertyId: number, activeStage: string },
    private propertyService: PropertyService,
    private permissionsService: PermissionsService,
    private layoutService: LayoutService
  ) {
    this.isMobile = this.layoutService.isMobile;

    this.propertyId = data.propertyId;
    this.activeStage = data.activeStage;
  }

  readonly propertyId: number;
  readonly activeStage: string;

  selectedStage: FlipStageValidationData;
  stages: FlipStageValidationData[];
  isMobile = false;

  isFlipDisabled = false;

  mapperInvalidFieldNameToMessage = mapperInvalidFieldNameToMessage;

  ngOnInit(): void {
    this.fetchFlpStageValidationData();
  }

  updateSelectedStage(stage: FlipStageValidationData): void {
    this.selectedStage = stage;

    const hasOverridePermission = this.permissionsService.hasPermission('projects')('OVERRIDE_STAGES')();
    this.isFlipDisabled = !hasOverridePermission && this.selectedStage?.required?.length > 0;
  }

  toggleRequiredFields(panel: MatExpansionPanel): void {
    if (this.isMobile) {
      panel.expanded = !panel.expanded;
    }
  }

  private fetchFlpStageValidationData(): void {
    this.propertyService.fetchFlipStageValidationData(this.propertyId)
      .subscribe((info: FlipStageValidationData[]) => this.stages = info);
  }

  flipStage(): void {
    if (!this.selectedStage) {
      this.dialogRef.close();
      return;
    }

    const dialogRef = this.dialog.open(ChangeStageConfirmationDialogComponent, {
      data: { propertyId: this.propertyId, selectedStage: this.selectedStage },
      panelClass: this.isMobile ? 'cms-dialog-mobile' : 'cms-dialog',
      autoFocus: false
    });

    dialogRef.afterClosed()
      .pipe(filter(confirmed => confirmed))
      .subscribe(() => this.dialogRef.close());
  }
}

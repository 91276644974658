import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { SpinnerService } from './spinner.service';

@Component({
  selector: 'cms-spinner',
  templateUrl: './spinner.component.html'
})
export class SpinnerComponent implements OnDestroy {

  show = false;

  private subscription: Subscription;

  constructor(
    private spinnerService: SpinnerService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.subscription = this.spinnerService.spinnerState
      .subscribe((state) => {
        this.show = state;
        this.changeDetectorRef.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

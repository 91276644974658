export interface Paginator {
  page: number;
  limit: number;
}

export interface Sort {
  sortBy: string;
  sortOrder: 'asc' | 'desc' | '';
}

export enum PageMode {
  view = 'view',
  edit = 'edit'
}

export enum ExportFormat {
  pdf = 'pdf',
  xlsx = 'xlsx'
}

export interface GeneralInfo {
  id: number | string;
  name: string;
}

export interface NavLink {
  label: string;
  path: string;
  permission?: { moduleType: string; accessType: string; };
}

export interface WarningInfoObj {
  count: number;
  text: string;
}

export interface BaseEntityInfo {
  id: number;
  created_at: string;
  updated_at: string;
}

export interface FilePath {
  nameWithExtention?: string;
  url: string;
}


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { OverlayModule } from '@angular/cdk/overlay';
import { RouterModule } from '@angular/router';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';

import { AccessControlDirective } from '@shared/access-control/access-control.directive';
import { CircleProgressComponent } from '@shared/circle-progress/circle-progress.component';
import { CopyrightComponent } from '@shared/copyright/copyright.component';
import { MainHeaderComponent } from '@shared/main-header/main-header.component';
import { AvatarComponent } from '@shared/avatar/avatar.component';
import { PageHeaderComponent } from '@shared/page-header/page-header.component';
import { SelectComponent } from '@shared/select/select.component';
import { SearchInputComponent } from '@shared/search-input/search-input.component';
import { AssigningFilterComponent } from '@shared/filters/assigning-filter/assigning-filter.component';
import { SelectFilterComponent } from '@shared/filters/select-filter/select-filter.component';
import { HeaderWithTabsComponent } from '@shared/header-with-tabs/header-with-tabs.component';
import { YesNoComponent } from '@shared/yes-no/yes-no.component';
import { NeedsUpdateInCrmComponent } from '@shared/needs-update-in-crm/needs-update-in-crm.component';
import { VerticalNavBarComponent } from './vertical-nav-bar/vertical-nav-bar.component';
import { PhonePipe } from './phone-pipe/phone.pipe';
import { RoleBaseFormComponent } from '@shared/role/role-base-form/role-base-form.component';
import { RoleBaseFormService } from '@shared/role/role-base-form.service';
import { WarningInfoTextComponent } from '@shared/warning-info-text/warning-info-text.component';
import { SelectableChipsComponent } from '@shared/selectable-chips/selectable-chips.component';
import { NumberInputWithArrowsComponent } from './number-input-with-arrows/number-input-with-arrows.component';
import { GeneralDateService } from '@shared/date/general-date.service';
import { NotificationsPanelComponent } from '@shared/notifications/notifications-panel/notifications-panel.component';
import { NotificationItemComponent } from '@shared/notifications/notification-item/notification-item.component';
import { NotificationsPanelFiltersComponent } from '@shared/notifications/notifications-panel/notifications-panel-filters/notifications-panel-filters.component';
import { NotificationBellComponent } from '@shared/notifications/notification-bell/notification-bell.component';
import { AddToProjectsService } from '@shared/add-to-projects/add-to-projects.service';
import { AddToProjectDialogComponent } from '@shared/add-to-projects/add-to-project-dialog/add-to-project-dialog.component';
import { AddToProjectsDialogService } from '@shared/add-to-projects/add-to-projects-dialog.service';
import { NotificationsDatePipe } from '@shared/notifications/notifications-date.pipe';
import { IsOutOfBoundDirective } from './is-out-of-bound/is-out-of-bound.directive';
import { UserCheckComponent } from '@shared/user-check/user-check.component';
import { CrmUserInfoViewComponent } from '@shared/crm-fields-info-view/crm-user-info-view/crm-user-info-view.component';
import { CrmFieldInfoViewComponent } from '@shared/crm-fields-info-view/crm-field-info-view/crm-field-info-view.component';
import { MultiSelectChipsComponent } from './multi-select-chips/multi-select-chips.component';
import { CrmFieldInfoEditComponent } from '../shared/crm-fields-info-edit/crm-field-info-edit/crm-field-info-edit.component';
import { CrmUserInfoEditComponent } from './crm-fields-info-edit/crm-user-info-edit/crm-user-info-edit.component';
import { ContentOrAbsentInCRMComponent } from './content-or-absent-in-crm/content-or-absent-in-crm.component';
import { SelectEliminatedFilterComponent } from './filters/select-eliminated-filter/select-eliminated-filter.component';
import { DropTimeZoneDatePipe } from './drop-time-zone-date.pipe';
import { CalendarComponent } from './calendar/calendar.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BambooHrVacationsService } from '@shared/bamboo-hr-vacations.service';
import { EmptyComponent } from './empty/empty.component';
import { YesNoAutoHideableComponent } from './yes-no-auto-hideable/yes-no-auto-hideable.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatCardModule,
    MatListModule,
    OverlayModule,
    MatRippleModule,
    MatDialogModule,
    MatDatepickerModule,
    MatProgressSpinnerModule
  ],
  declarations: [
    CopyrightComponent,
    MainHeaderComponent,
    AvatarComponent,
    SelectComponent,
    SearchInputComponent,
    AssigningFilterComponent,
    SelectFilterComponent,
    SelectEliminatedFilterComponent,
    HeaderWithTabsComponent,
    YesNoComponent,
    YesNoAutoHideableComponent,
    NeedsUpdateInCrmComponent,
    VerticalNavBarComponent,
    RoleBaseFormComponent,
    WarningInfoTextComponent,
    PageHeaderComponent,
    PhonePipe,
    DropTimeZoneDatePipe,
    AddToProjectDialogComponent,
    SelectableChipsComponent,
    NumberInputWithArrowsComponent,
    CircleProgressComponent,
    AccessControlDirective,
    NotificationsPanelComponent,
    NotificationItemComponent,
    NotificationsPanelFiltersComponent,
    NotificationBellComponent,
    NotificationsDatePipe,
    IsOutOfBoundDirective,
    UserCheckComponent,
    CrmFieldInfoViewComponent,
    CrmUserInfoViewComponent,
    MultiSelectChipsComponent,
    CrmFieldInfoEditComponent,
    CrmUserInfoEditComponent,
    ContentOrAbsentInCRMComponent,
    CalendarComponent,
    EmptyComponent
  ],
  providers: [
    RoleBaseFormService,
    GeneralDateService,
    AddToProjectsService,
    AddToProjectsDialogService,
    BambooHrVacationsService
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatRippleModule,
    MatListModule,
    MatDialogModule,
    MatButtonToggleModule,
    CopyrightComponent,
    MainHeaderComponent,
    AvatarComponent,
    SelectComponent,
    SearchInputComponent,
    AssigningFilterComponent,
    SelectFilterComponent,
    SelectEliminatedFilterComponent,
    HeaderWithTabsComponent,
    YesNoComponent,
    YesNoAutoHideableComponent,
    NeedsUpdateInCrmComponent,
    VerticalNavBarComponent,
    RoleBaseFormComponent,
    WarningInfoTextComponent,
    PageHeaderComponent,
    PhonePipe,
    DropTimeZoneDatePipe,
    AddToProjectDialogComponent,
    SelectableChipsComponent,
    NumberInputWithArrowsComponent,
    CircleProgressComponent,
    AccessControlDirective,
    NotificationItemComponent,
    NotificationBellComponent,
    NotificationsDatePipe,
    IsOutOfBoundDirective,
    UserCheckComponent,
    CrmFieldInfoViewComponent,
    CrmUserInfoViewComponent,
    MultiSelectChipsComponent,
    CrmFieldInfoEditComponent,
    CrmUserInfoEditComponent,
    ContentOrAbsentInCRMComponent,
    CalendarComponent,
    EmptyComponent
  ]
})
export class SharedModule {}

import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { SharedModule } from '@shared/shared.module';
import { AuthService } from '@core/auth/auth.service';
import { GlobalErrorHandler } from '@core/global-error-handler/global-error-handler';
import { IconRegistryService } from '@core/icon-registry.service';
import { LayoutService } from '@core/layout.service';
import { MainMenuComponent } from '@core/main-menu/main-menu.component';
import { MainMenuService } from '@core/main-menu/main-menu.service';
import { PermissionsService } from '@core/permissions.service';
import { SpinnerComponent } from '@core/spinner/spinner.component';
import { SpinnerInterceptor } from '@core/spinner/spinner.interceptor';
import { SpinnerService } from '@core/spinner/spinner.service';
import { ToasterComponent } from '@core/toaster/toaster.component';
import { ToasterService } from '@core/toaster/toaster.service';
import { UserPermissionsResolver } from '@core/user-permissions.resolver';
import { UserService } from '@core/user.service';
import { MainMenuDropdownComponent } from './main-menu/main-menu-dropdown/main-menu-dropdown.component';
import { MainMenuToggleComponent } from './main-menu/main-menu-toggle/main-menu-toggle.component';
import { WithoutPermissionsComponent } from '@core/without-permissions/without-permissions.component';
import { NotificationsComponent } from '@core/notifications/notifications/notifications.component';
import { NotificationsService } from '@core/notifications/notifications.service';
import { NotificationsFiltersComponent } from '@core/notifications/notifications-filters/notifications-filters.component';
import { NotificationsAssigningFilterComponent } from '@core/notifications/notifications-assigning-filter/notifications-assigning-filter.component';
import { NotificationsDataService } from '@core/notifications/notifications-data.service';
import { FileDownloaderService } from '@core/file-downloader.service';


@NgModule({
  imports: [
    CommonModule,
    MatSnackBarModule,
    MatButtonModule,
    MatIconModule,
    MatExpansionModule,
    MatDividerModule,
    MatRippleModule,
    LayoutModule,
    SharedModule,
    RouterModule,
    InfiniteScrollModule
  ],
  declarations: [
    SpinnerComponent,
    ToasterComponent,
    MainMenuComponent,
    MainMenuDropdownComponent,
    MainMenuToggleComponent,
    WithoutPermissionsComponent,
    NotificationsComponent,
    NotificationsFiltersComponent,
    NotificationsAssigningFilterComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true, deps: [SpinnerService] },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    AuthService,
    SpinnerService,
    ToasterService,
    UserService,
    MainMenuService,
    LayoutService,
    IconRegistryService,
    PermissionsService,
    UserPermissionsResolver,
    NotificationsService,
    NotificationsDataService,
    FileDownloaderService
  ],
  exports: [
    CommonModule,
    InfiniteScrollModule,
    MatSnackBarModule,
    SpinnerComponent,
    MainMenuComponent,
    WithoutPermissionsComponent
  ],
  entryComponents: [ToasterComponent]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}

<span>{{title}}</span>
<mat-button-toggle-group name="AssignedTo"
                         aria-label="Assigned to"
                         (change)="selectAssigneeType($event)">
  <mat-button-toggle value="me" [checked]="defaultAssignTo === 'me'">
    Me
  </mat-button-toggle>
  <mat-button-toggle value="team" [checked]="defaultAssignTo === 'team'">
    Team
  </mat-button-toggle>
  <mat-button-toggle value="all" [checked]="defaultAssignTo === 'all'">
    All
  </mat-button-toggle>
</mat-button-toggle-group>

<cms-select-filter *ngIf="assignedTo === teamMemberParent"
                   class="assigning-filter__select-members"
                   title="Team Members"
                   [values]="teamMembers"
                   [defaultSelectedValues]="selectedTeamMembers"
                   [itemTemplate]="itemTemplate"
                   [labelMapper]="teamMembersMapper"
                   [selectedLabelMapper]="selectedTeamMembersMapper"
                   (selectionChanged)="selectTeamMembers($event)">
</cms-select-filter>

<ng-template #itemTemplate
             let-item="item">
  <div class="assigning-filter__member">
    <cms-avatar class="assigning-filter__avatar" [avatarUrl]="item.avatarUrl"></cms-avatar>
    <span class="assigning-filter__name">{{item.fullName}}</span>
  </div>
</ng-template>

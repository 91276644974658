import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';

import { PropertyStateService } from '@property/shared/property-state.service';
import { PropertyService } from '@property/shared/property.service';

@Component({
  selector: 'cms-archive-dialog',
  templateUrl: './archive-dialog.component.html',
  styleUrls: ['./archive-dialog.component.scss']
})
export class ArchiveDialogComponent {

  propertyId: number;
  archived: boolean;
  propertyName: string;
  requestAlreadySent = false;

  constructor(
    private dialogRef: MatDialogRef<ArchiveDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: { propertyId: number, archived: boolean, propertyName: string },
    private propertyService: PropertyService,
    private propertyStateService: PropertyStateService
  ) {
    this.propertyId = data.propertyId;
    this.archived = data.archived;
    this.propertyName = data.propertyName;
  }

  archiveUnarchiveProperty(action: string): void {
    this.propertyService.archiveUnarchiveProperty(this.propertyId, action)
      .pipe(
        finalize(() => this.requestAlreadySent = false )
      )
      .subscribe(() => {
        this.propertyStateService.updateArchivedStage(!this.archived);
        this.dialogRef.close({ isNowArchived: !this.archived });
      });
  }
}

import { Component } from '@angular/core';
import { AbstractCrmUserInfoComponent } from '../../abstract-crm-user-info';


@Component({
  selector: 'cms-crm-user-info-view',
  templateUrl: './crm-user-info-view.component.html',
  styleUrls: ['../crm-fields-info-view.scss']
})
export class CrmUserInfoViewComponent extends AbstractCrmUserInfoComponent {

  constructor() {
    super();
  }
}

<div class="change-stage-dialog">
  <div class="change-stage-dialog__header">
    <h3 mat-dialog-title>Change Stage</h3>
    <button mat-icon-button [mat-dialog-close]>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-dialog-content class="change-stage-dialog__content">
    <mat-accordion hideToggle="true">
      <mat-expansion-panel disabled
                            #mep="matExpansionPanel"
                            *ngFor="let stage of stages">
        <mat-expansion-panel-header class="accordion-header">
          <mat-radio-button class="stage-radio-btn" [disabled]="!stage.isActive"
                            [ngClass]="{'disabled-radio-btn': !stage.isActive}"
                            (change)="updateSelectedStage(stage)"
                            [checked]="activeStage === stage.name">
            <span class="header-stage-name">
              {{stage.name}}
            </span>
          </mat-radio-button>
          <div class="info-container" (click)="toggleRequiredFields(mep)">
            <div class="required-container" *ngIf="stage.required.length !== 0">
              <mat-icon class="required-icon">
                info_outline
              </mat-icon>
              <div class="required-text">
                {{stage.required.length}} fields needed
              </div>
            </div>
            <ng-container *ngIf="stage.required.length !== 0">
              <ng-container *ngIf="isMobile">
                <mat-icon *ngIf="!mep.expanded" class="required-icon">
                  arrow_drop_down
                </mat-icon>
                <mat-icon *ngIf="mep.expanded" class="required-icon">
                  arrow_drop_up
                </mat-icon>
              </ng-container>
              <ng-container *ngIf="!isMobile">
                <button mat-button
                        *ngIf="!mep.expanded"
                        color="accent"
                        (click)="mep.expanded = !mep.expanded">
                  SHOW
                </button>
                <button mat-button
                        class="hide-btn"
                        *ngIf="mep.expanded"
                        color="accent"
                        (click)="mep.expanded = !mep.expanded">
                  Hide
                </button>
              </ng-container>
            </ng-container>
          </div>
        </mat-expansion-panel-header>
        <div class="required-list-container" *ngFor = "let required of stage.required">
          {{mapperInvalidFieldNameToMessage(required)}}
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-dialog-content>

  <div mat-dialog-actions class="change-stage-dialog__actions" align="end">
    <button mat-flat-button
            color="accent"
            [disabled]="isFlipDisabled"
            (click)="flipStage()">
      FLIP
    </button>
  </div>
</div>

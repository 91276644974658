<div class="property-details-header">
  <div class="property-details-header__image"
    [style.backgroundImage]="bgImageStyle"></div>
  <div class="property-details-header__main"
    [ngClass]="{'on-main-menu-open': isMainMenuShown}">
    <div class="table-and-progress">
      <div class="property-details-header__main-table">
        <div class="property-details-header__main-list">
          <div class="property-details-header__main-item">
            <div class="property-details-header__main-title">County</div>
            <div class="property-details-header__main-value">{{property?.county?.name}}</div>
          </div>
          <div class="property-details-header__main-item">
            <div class="property-details-header__main-title">Market</div>
            <div class="property-details-header__main-value">{{property?.market?.name}}</div>
          </div>
        </div>
        <div class="property-details-header__main-list">
          <div *ngIf="property?.job_type"
            class="property-details-header__main-item">
            <div class="property-details-header__main-title">Job Type</div>
            <div class="property-details-header__main-value">
              {{property?.job_type === jobTypes.asIs ? property?.job_type : property?.job_type +
              (property?.project_data2?.permitting === 'Yes' ? ', Permitted' : property?.project_data2?.permitting === 'No' ? ', Not Permitted' : '')}} </div>
          </div>
          <div *ngIf="property?.stage && !property?.finished"
            class="property-details-header__main-item">
            <div class="property-details-header__main-title">Stage</div>
            <div class="property-details-header__main-value">{{property?.stage}}</div>
          </div>
          <div *ngIf="property?.finished"
            class="property-details-header__main-item">
            <div class="property-details-header__main-title">Act Repairs</div>
            <div class="property-details-header__main-value">
              <span> {{property?.construction?.actual_repairs | currency : 'USD' : 'symbol' : '1.0-0'}} </span>
              <span [ngClass]="{'text-green': property?.construction.actual_repairs - property?.budget_cap >= 0,
                              'text-red': property?.construction.actual_repairs - property?.budget_cap < 0}">
                {{(property?.construction?.actual_repairs - property?.budget_cap) | currency : 'USD' : 'symbol' : '1.0-0'}}
              </span>
            </div>
          </div>
        </div>
        <div class="property-details-header__main-list">
          <div class="property-details-header__main-item">
            <div class="property-details-header__main-title">REC</div>
            <div class="property-details-header__main-value property-details-user">
              <cms-avatar *ngIf="property.rec"
                class="property-details-user__avatar"
                [avatarUrl]="property.rec?.avatar"></cms-avatar>
              <span>{{property.rec?.full_name || property?.crm_rec?.full_name}}</span>
              <cms-crm-user-info-view class="property-user-info"
                [user]="property?.crm_rec"></cms-crm-user-info-view>
            </div>
          </div>
          <div *ngIf="property?.budget_cap"
            class="property-details-header__main-item">
            <div class="property-details-header__main-title">Budget Cap</div>
            <div class="property-details-header__main-value">
              {{property?.budget_cap | currency : 'USD' : 'symbol' : '1.0-0'}}</div>
          </div>
        </div>
      </div>
      <div class="property-details-header__progress-container">
        <div class="progress-box">
          <button *ngIf="!finished && !archived && isProject"
            class="property-details-header__flip-btn"
            mat-flat-button
            color="accent"
            (click)="openChangeStageDialog()"> Flip Stage </button>
          <cms-circle-progress [progress]="property?.progress"
            [diameter]="40"
            [finished]="property?.finished"
            [strokeWidth]="4"></cms-circle-progress>
        </div>
      </div>
    </div>
    <div class="property-details-header__nav-container"
      [ngClass]="{
        'spaceForHistory': isHistorySideBarShown && !isMainMenuShown,
        'spaceForMainMenu': isMainMenuShown && !isHistorySideBarShown,
        'spaceForBoth': isMainMenuShown && isHistorySideBarShown
      }">
      <nav class="property-details-header__nav-container__nav-tabs"
        mat-tab-nav-bar
        color="accent">
        <a class="property-details-header__nav-container__nav-link"
          mat-tab-link
          *ngFor="let navLink of navLinks"
          [routerLink]="navLink.path"
          [active]="router.routerState.snapshot.url.includes(navLink.path)"> {{navLink.label}} </a>
      </nav>
      <div *ngIf="property?.hold?.description"
        class="property-details-header__nav-container__on-hold-message"
        (click)="openEditOnHoldDialog()">
        <span class="bold">Property is on Hold: </span>{{property?.hold?.description}} </div>
    </div>
  </div>
</div>

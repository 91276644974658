import { Component } from '@angular/core';

import { FiltersService } from '@shared/filters/filters.service';
import { LayoutService } from '@core/layout.service';
import { AbstractNotificationsFiltersComponent } from '@shared/notifications/abstract-notifications-filters';
import { NotificationsAssigningFilterService } from '@shared/notifications/notifications-assigning-filter.service';


@Component({
  selector: 'cms-notifications-filters',
  templateUrl: './notifications-filters.component.html',
  styleUrls: ['./notifications-filters.component.scss']
})
export class NotificationsFiltersComponent extends AbstractNotificationsFiltersComponent {

  isMobile = false;

  constructor(
    private layoutService: LayoutService,
    filtersService: FiltersService,
    assigningFilterService: NotificationsAssigningFilterService
  ) {
    super(filtersService, assigningFilterService);
    this.isMobile = this.layoutService.isMobile;
  }
}

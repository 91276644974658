import { Component } from '@angular/core';

import { AbstractCrmUserInfoComponent } from '../../abstract-crm-user-info';


@Component({
  selector: 'cms-crm-user-info-edit',
  templateUrl: './crm-user-info-edit.component.html',
  styleUrls: ['../crm-fields-info-edit.scss']
})
export class CrmUserInfoEditComponent extends AbstractCrmUserInfoComponent {

  constructor() {
    super();
  }
}

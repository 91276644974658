<div class="notifications">
  <cms-main-header>
    <h1 [class.notifications__mobile-header]="isMobile">Notifications</h1>
    <mat-button-toggle-group class="notifications__type-toggle-group" 
                            name="notificationsType"
                           aria-label="notificationsType"
                           (change)="selectNotificationsType($event?.value)">
    <mat-button-toggle [checked]="currentNotificationsType === 'active'" value="active">
      Active
    </mat-button-toggle>
    <mat-button-toggle [checked]="currentNotificationsType === 'archived'" value="archived">
      Archived
    </mat-button-toggle>
  </mat-button-toggle-group>
  </cms-main-header>

  <div class="page-content">
    <cms-notifications-filters></cms-notifications-filters>

    <div class="notifications__list"
         infiniteScroll
         [infiniteScrollDistance]="2"
         (scrolled)="onScroll()">
      <ng-container *ngFor="let item of notifications" >
        <div *ngIf="item" class="notifications__group">
          <div class="notifications__items"
               [ngClass]="{'resolved' : item?.resolved_at}">
            <cms-avatar class="notifications__avatar" [avatarUrl]="item.sender?.avatar"></cms-avatar>

            <div class="flex-1">
              <cms-notification-item [notification]="item"></cms-notification-item>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

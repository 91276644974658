import { Component, Input } from '@angular/core';
import { WarningInfoObj } from '@models/core';

@Component({
  selector: 'cms-warning-info-text',
  templateUrl: './warning-info-text.component.html',
  styleUrls: ['./warning-info-text.component.scss']
})
export class WarningInfoTextComponent {

  @Input()
  set warningInfo(warningInfo: WarningInfoObj[]) {
    this.formattedWarningInfo = warningInfo.map((info: WarningInfoObj) => this.formatWarningInfoObj(info));
  }
  get warningInfo(): WarningInfoObj[] {
    return this.formattedWarningInfo;
  }
  private formattedWarningInfo: WarningInfoObj[];

  private formatWarningInfoObj({ count, text }: WarningInfoObj): WarningInfoObj {
    return { count, text: `${text}${count > 1 ? 's' : ''}` };
  }
}

import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { LayoutService } from '@core/layout.service';
import { AbstractPropertyComponent } from '@property/shared/abstract-property';
import { PropertyStateService } from '@property/shared/property-state.service';
import { ToasterService } from '@core/toaster/toaster.service';
import { Subject } from 'rxjs';
import { PropertyHistorySidebarStateService } from '../property-history-sidebar-state.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

export const AUTOCOLLAPSESCREENWIDTH = 900;

@Component({
  selector: 'cms-desktop-property',
  styleUrls: ['./desktop-property.component.scss'],
  templateUrl: './desktop-property.component.html'
})
export class DesktopPropertyComponent extends AbstractPropertyComponent {
  propertyOnHoldChanged = new Subject<boolean>();
  onHoldState = !!this.property?.hold || false;

  constructor(
    layoutService: LayoutService,
    dialog: MatDialog,
    propertyStateService: PropertyStateService,
    router: Router,
    protected toasterService: ToasterService,
    private propertyHistorySidebarStateService: PropertyHistorySidebarStateService
  ) {
    super(layoutService, dialog, propertyStateService, toasterService);

    this.closeNotesMenuIfNeeded(window.innerWidth);

    router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      propertyStateService.needUpdateHistory.emit(true);
      propertyStateService.needUpdateNotesList.emit(true);
    });
  }

  toggleHistoryMenu(): void {
    this.propertyHistorySidebarStateService.toggleState();
  }

  get isHistoryMenuOpened(): boolean {
    return this.propertyHistorySidebarStateService.isOpened;
  }

  closeNotesMenuIfNeeded(width: number): void {
    if (width < AUTOCOLLAPSESCREENWIDTH) {
      this.propertyHistorySidebarStateService.setState('closed');
    }
  }
}

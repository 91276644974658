<div class="notifications-panel__overlay"
     (click)="closePanel()">
</div>

<div class="notifications-panel" (click)="$event.stopPropagation()">
  <div class="notifications-panel__header">
    <h2>Notifications</h2>
    <button class="notifications-panel__close-btn"
            mat-icon-button
            (click)="closePanel()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <cms-notifications-panel-filters></cms-notifications-panel-filters>

  <div class="notifications-panel__content">
    <ng-container *ngFor="let item of notifications">
      <div *ngIf="item" class="notifications-panel__group">
        <cms-notification-item
                               class="notifications-panel__item"
                               [notification]="item">
        </cms-notification-item>
      </div>
    </ng-container>
  </div>

  <div class="notifications-panel__actions">
    <button mat-button
            color="accent"
            (click)="seeAllNotifs()">
      See all
    </button>
  </div>
</div>

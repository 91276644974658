import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestHelperService } from './request-helper.service';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { BambooHRVacationPeriod } from '@models/holidays';
import { ApiResponseItem } from '@models/response';

@Injectable()
export class BambooHrVacationsService {
  constructor(private http: HttpClient) {}

  fetchUserBambooHRVacations(userId: number): Observable<BambooHRVacationPeriod[]> {
    const url = RequestHelperService.apiPaths.users.userBambooVacations(userId);
    return this.http.get<ApiResponseItem<{ vacations: BambooHRVacationPeriod[] }>>(url).pipe(
      first(),
      map((response) => response?.data?.vacations)
    );
  }

  fetchUserBambooHRVacationsSync(): Observable<{ success: boolean }> {
    const url = RequestHelperService.apiPaths.users.userBambooVacationsSync;
    return this.http.get<ApiResponseItem<{ success: boolean }>>(url).pipe(
      first(),
      map((response) => response?.data)
    );
  }
}

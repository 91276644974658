import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';

import { NotificationsDateFilter } from '@models/notification';
import { FiltersService } from '@shared/filters/filters.service';
import { AbstractNotificationsFiltersComponent } from '@shared/notifications/abstract-notifications-filters';
import { NotificationsAssigningFilterService } from '@shared/notifications/notifications-assigning-filter.service';


@Component({
  selector: 'cms-notifications-panel-filters',
  templateUrl: './notifications-panel-filters.component.html',
  styleUrls: ['./notifications-panel-filters.component.scss']
})
export class NotificationsPanelFiltersComponent extends AbstractNotificationsFiltersComponent {

  notificationsDates: NotificationsDateFilter[] = [
    { name: 'All Notifications', value: '' },
    { name: 'Today Only', value: 'today' },
    { name: 'Past Due Only', value: 'past' }
  ];

  dateControl = new FormControl(this.notificationsDates[0]);

  constructor(
    filtersService: FiltersService,
    assigningFilterService: NotificationsAssigningFilterService
  ) {
    super(filtersService, assigningFilterService);
  }
}

import { Component, OnInit } from '@angular/core';

import { AuthService } from '@core/auth/auth.service';
import { IconRegistryService } from '@core/icon-registry.service';
import { LayoutService } from '@core/layout.service';
import { MainMenuService } from '@core/main-menu/main-menu.service';

@Component({
  selector: 'cms-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  isMainMenuShown = true;

  constructor(
    public authService: AuthService,
    public layoutService: LayoutService,
    private menuService: MainMenuService,
    private iconRegistryService: IconRegistryService
  ) { }

  ngOnInit(): void {
    this.menuService.isMainMenuShown()
      .subscribe((isShown: boolean) => this.isMainMenuShown = isShown);

    this.iconRegistryService.registerIcon('logout', '../assets/icons/logout.svg');
  }
}

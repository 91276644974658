import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { RequestHelperService } from '@shared/request-helper.service';
import { environment } from '../../../environments/environment';
import { CMSFirebaseService } from '@core/firebase.service';
import { PermissionsService } from '@core/permissions.service';


@Injectable()
export class AuthService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private cmsFirebaseService: CMSFirebaseService,
    private permissionsService: PermissionsService
  ) { }

  get isLoggedIn(): boolean {
    return Boolean(this.authToken);
  }

  get authToken(): string {
    return localStorage.getItem('cms-authToken') || '';
  }

  get refreshToken(): string {
    return localStorage.getItem('cms-refreshToken') || '';
  }

  static readonly clientId = environment.backend.clientId;
  static readonly clientSecret = environment.backend.clientSecret;

  static removeTokensFromStorage(): void {
    localStorage.removeItem('cms-authToken');
    localStorage.removeItem('cms-refreshToken');
    localStorage.removeItem('cms-expiresIn');
  }

  private static saveTokens({ access_token, refresh_token, expires_in }: GenerateTokenResponse): void {
    localStorage.setItem('cms-authToken', access_token);
    localStorage.setItem('cms-refreshToken', refresh_token);

    const expiredDateTime = new Date();
    expiredDateTime.setSeconds(expiredDateTime.getSeconds() + expires_in);
    localStorage.setItem('cms-expiresIn', expiredDateTime.toISOString());
  }

  logIn(username: string, password: string): Observable<HttpResponse<void>> {
    return this.fetchAuthTokens({
      grant_type: GrantType.password,
      username,
      password
    });
  }

  refreshTokens(token: string): Observable<HttpResponse<void>> {
    return this.fetchAuthTokens({
      grant_type: GrantType.refreshToken,
      refresh_token: token
    });
  }

  logOut(): void {
    this.http.delete<void>(RequestHelperService.apiPaths.auth.logout).subscribe();

    this.cmsFirebaseService.deleteDeviceToken();
    AuthService.removeTokensFromStorage();
    this.permissionsService.clearPermissionsState();

    this.goToLogin();
  }

  resetPassword(email: string): Observable<void> {
    return this.http.post<void>(RequestHelperService.apiPaths.auth.resetPassword, { email });
  }

  canResetPassword(email: string): Observable<void> {
    return this.http.post<void>(RequestHelperService.apiPaths.auth.canResetPassword, { email });
  }

  changePassword(email: string, password: string, token: string): Observable<void> {
    return this.http.post<void>(RequestHelperService.apiPaths.auth.changePassword, { email, password, token });
  }

  goToLogin(): Promise<boolean> {
    return this.router.navigateByUrl('/login');
  }

  private fetchAuthTokens(payload: Partial<AuthPayload>): Observable<HttpResponse<void>> {
    return this.http.post<any>(RequestHelperService.apiPaths.auth.login, {
      scope: '*',
      client_id: AuthService.clientId,
      client_secret: AuthService.clientSecret,
      ...payload
    })
      .pipe(
        tap(({ data }) => {
          AuthService.saveTokens(data);
        })
      );
  }
}

interface GenerateTokenResponse {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  token_type: 'Bearer';
}

enum GrantType {
  password = 'password',
  refreshToken = 'refresh_token'
}

interface AuthPayload {
  scope: string;
  client_id: string;
  client_secret: string;
  grant_type: GrantType;
  username?: string;
  password?: string;
  refresh_token?: string;
}

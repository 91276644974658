import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';

import { PropertiesNote } from '@models/property/property';
import { PropertyService } from '../../property.service';

@Component({
  selector: 'cms-add-note-dialog',
  templateUrl: './add-note-dialog.component.html',
  styleUrls: ['./add-note-dialog.component.scss'],
})
export class AddNoteDialogComponent {

  title = 'Add Note';
  mainBtnText = 'Add Note';

  noteId: number;
  noteValue = '';

  stage: string;
  requestAlreadySent = false;

  propertyId: number;

  constructor(
    private dialogRef: MatDialogRef<AddNoteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: { propertyId: number, stage: string, note?: PropertiesNote },
    private propertyService: PropertyService
  ) {
    if (data) {
      this.propertyId = data.propertyId;
      this.stage = data.stage;

      if (data.note) {
        this.title = 'Edit Note';
        this.mainBtnText = 'Update Note';
        this.noteId = data.note.id;
        this.noteValue = data.note.text;
      }
    }
  }

  checkValidNote(): boolean {
    return this.noteValue === '';
  }

  handleNote(): void {
    if (this.requestAlreadySent === false){
      this.requestAlreadySent = true;
      const preparedNoteData = { stage: this.stage, text: this.noteValue };

      if (this.noteId) {
        this.propertyService.editPropertyNote(this.propertyId, preparedNoteData, this.noteId)
          .pipe(finalize(() => this.requestAlreadySent = false))
          .subscribe(() => this.dialogRef.close(true));
        return;
      }

      this.propertyService.addPropertyNote(this.propertyId, preparedNoteData)
        .pipe(finalize(() => this.requestAlreadySent = false))
        .subscribe(() => this.dialogRef.close(true));
    }
  }

}

<div class="add-to-project-dialog">
  <div class="add-to-project-dialog__header">
    <h3 mat-dialog-title>Add to Projects</h3>
    <button mat-icon-button [mat-dialog-close]>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-dialog-content>
    <div>{{selectedInspection?.full_address}}</div>
      <mat-list class="add-to-project-dialog__list">
        <mat-list-item class="add-to-project-dialog__item">
          <div class="add-to-project-dialog__item-title">Market</div>
          <cms-needs-update-in-crm [needsUpdate]="selectedInspection.market">
            <span>{{selectedInspection?.market}}</span>
          </cms-needs-update-in-crm>
        </mat-list-item>

        <mat-list-item class="add-to-project-dialog__item">
          <div class="add-to-project-dialog__item-title">County</div>
          <cms-needs-update-in-crm [needsUpdate]="selectedInspection?.county">
            <span>{{selectedInspection?.county}}</span>
          </cms-needs-update-in-crm>
        </mat-list-item>
        <mat-list-item class="add-to-project-dialog__item">
          <div class="add-to-project-dialog__item-title">CRM Stage</div>
          <cms-needs-update-in-crm [needsUpdate]="selectedInspection?.crm_stage">
            <span>{{selectedInspection?.crm_stage}}</span>
          </cms-needs-update-in-crm>
        </mat-list-item>

        <mat-divider class="add-to-project-dialog__divider"></mat-divider>

        <mat-list-item class="add-to-project-dialog__item">
          <div class="add-to-project-dialog__item-title">REC</div>
          <cms-user-check [user]="selectedInspection?.crm_rec">
            <div class="avatar-box">
              <cms-avatar class="avatar" [avatarUrl]="selectedInspection?.rec?.avatar_url"></cms-avatar>
              <span>{{selectedInspection?.rec?.full_name || selectedInspection?.crm_rec?.full_name}}</span>
            </div>
          </cms-user-check>
        </mat-list-item>

        <mat-list-item class="add-to-project-dialog__item">
          <div class="add-to-project-dialog__item-title">Deal Type</div>
          <cms-needs-update-in-crm [needsUpdate]="selectedInspection?.deal_type">
            <span>{{selectedInspection?.deal_type}}</span>
          </cms-needs-update-in-crm>
        </mat-list-item>

        <mat-list-item class="add-to-project-dialog__item">
          <div class="add-to-project-dialog__item-title">Property Type</div>
          <cms-needs-update-in-crm [needsUpdate]="selectedInspection?.property_type">
            <span>{{selectedInspection?.property_type}}</span>
          </cms-needs-update-in-crm>
        </mat-list-item>

        <mat-divider class="add-to-project-dialog__divider"></mat-divider>

        <mat-list-item class="add-to-project-dialog__item">
          <div class="add-to-project-dialog__item-title">SC Acquisition</div>
          <cms-user-check [user]="selectedInspection?.crm_sc_acquisition">
            <div class="avatar-box">
              <cms-avatar class="avatar" [avatarUrl]="selectedInspection?.sc_acquisition?.avatar_url"></cms-avatar>
              <span>{{selectedInspection?.sc_acquisition?.full_name || selectedInspection?.crm_sc_acquisition?.full_name}}</span>
            </div>
          </cms-user-check>
        </mat-list-item>

        <mat-list-item class="add-to-project-dialog__item">
          <div class="add-to-project-dialog__item-title">SC Disposition</div>
          <cms-user-check [user]="selectedInspection?.crm_sc_outsale">
            <div class="avatar-box">
              <cms-avatar class="avatar" [avatarUrl]="selectedInspection?.sc_outsale?.avatar_url"></cms-avatar>
              <span>{{selectedInspection?.sc_outsale?.full_name || selectedInspection?.crm_sc_outsale?.full_name}}</span>
            </div>
          </cms-user-check>
        </mat-list-item>

        <mat-list-item class="add-to-project-dialog__item">
          <div class="add-to-project-dialog__item-title">Basement</div>
          <cms-needs-update-in-crm [needsUpdate]="selectedInspection?.basement_type">
            <cms-yes-no [yes]="selectedInspection?.basement_type === 'Yes'"></cms-yes-no>
          </cms-needs-update-in-crm>
        </mat-list-item>

        <mat-list-item class="add-to-project-dialog__item">
          <div class="add-to-project-dialog__item-title">Basement Finish</div>
          <cms-needs-update-in-crm [needsUpdate]="selectedInspection?.basement_finish">
            <span>{{selectedInspection?.basement_finish}}</span>
          </cms-needs-update-in-crm>
        </mat-list-item>

        <mat-list-item class="add-to-project-dialog__item">
          <div class="add-to-project-dialog__item-title">Condo or Small Townhouse?</div>
          <cms-needs-update-in-crm [needsUpdate]="selectedInspection?.condo_or_small_townhouse">
            <cms-yes-no [yes]="selectedInspection?.condo_or_small_townhouse === 'Yes'"></cms-yes-no>
          </cms-needs-update-in-crm>
          </mat-list-item>

          <mat-list-item class="add-to-project-dialog__item">
            <div class="add-to-project-dialog__item-title">Above Grade Sq Ft</div>
            <cms-needs-update-in-crm [needsUpdate]="selectedInspection?.above_grade_sq_ft">
              <span>{{selectedInspection?.above_grade_sq_ft}}</span>
            </cms-needs-update-in-crm>
          </mat-list-item>
        </mat-list>
  </mat-dialog-content>

  <div mat-dialog-actions class="add-to-project-dialog__actions">
    <button class="add-to-project-dialog-cancel-btn"
            mat-stroked-button
            color="accent"
            [mat-dialog-close]>
      Cancel
    </button>
    <button mat-flat-button
            color="accent"
            [disabled]="!isInspectionFulfilled"
            (click)="addToProject()">
      ADD TO PROJECTS
    </button>
  </div>
</div>

<span>{{title}}</span>
<div class="assigning-filter__content">
  <mat-button-toggle-group name="RelatedTo"
                           aria-label="Related to"
                           (change)="selectAssigneeType($event)">
    <mat-button-toggle [checked]="defaultAssignTo === 'me'" value="me">
      Me
    </mat-button-toggle>
    <mat-button-toggle [checked]="defaultAssignTo === 'team'" value="team">
      Team
    </mat-button-toggle>
    <mat-button-toggle [checked]="defaultAssignTo === 'team-members'" value="team-members">
      Team Member
    </mat-button-toggle>
  </mat-button-toggle-group>

  <cms-select-filter *ngIf="assignedTo === teamMemberParent"
                     class="assigning-filter__select-members"
                     title="Team Members"
                     [values]="teamMembers"
                     [defaultSelectedValues]="selectedTeamMembers"
                     [itemTemplate]="itemTemplate"
                     [labelMapper]="teamMembersMapper"
                     [selectedLabelMapper]="selectedTeamMembersMapper"
                     (selectionChanged)="selectTeamMembers($event)">

  </cms-select-filter>
</div>

<ng-template #itemTemplate
             let-item="item">
  <div class="assigning-filter__member">
    <cms-avatar class="assigning-filter__avatar" [avatarUrl]="item.avatarUrl"></cms-avatar>
    <span class="assigning-filter__name">{{item.fullName}}</span>
  </div>
</ng-template>

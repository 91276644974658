import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RequestHelperService } from '@shared/request-helper.service';
import { ApiResponseList } from '@models/response';
import { User, UserInfoResponse } from '@models/user/user';
import { RolePermission } from '@models/roles';

@Injectable()
export class UserService {

  constructor(private http: HttpClient) { }

  static translateUserSelfInfoResponseToUser(
    { id, email, first_name, last_name, avatar, full_name}: UserInfoResponse
  ): User {
    return {
      id,
      email,
      firstName: first_name,
      lastName: last_name,
      avatarUrl: avatar,
      fullName: full_name
    };
  }

  loadSelfInfo(): Observable<User> {
    return this.http.get<ApiResponseList<UserInfoResponse>>(RequestHelperService.apiPaths.users.self)
      .pipe(
        map(({ data }) => UserService.translateUserSelfInfoResponseToUser(data[0]))
      );
  }

  fetchTeammates(): Observable<User[]> {
    return this.http.get<ApiResponseList<[UserInfoResponse]>>(RequestHelperService.apiPaths.users.teammates)
      .pipe(
        map(({ data }) => data[0].map(UserService.translateUserSelfInfoResponseToUser))
      );
  }

  fetchAllUsers(): Observable<User[]> {
    return this.http.get<ApiResponseList<[UserInfoResponse]>>(RequestHelperService.apiPaths.users.all)
      .pipe(
        map(({ data }) => data[0].map(UserService.translateUserSelfInfoResponseToUser))
      );
  }

  fetchMyPermissions(): Observable<RolePermission[]> {
    return this.http.get<ApiResponseList<RolePermission[]>>(RequestHelperService.apiPaths.users.myPermissions)
      .pipe(
        map(({ data }: ApiResponseList<RolePermission[]>) => data[0])
      );
  }
}

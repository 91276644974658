<div class="property-history">
    <div class="single-note-container" *ngFor="let item of history">
      <div class="single-note-container__header">
        <div class="name-container">
          <cms-avatar class="avatar" [avatarUrl]="item.user?.avatar"></cms-avatar>
          <div class="name">{{item.user?.full_name || 'CRM'}}</div>
        </div>
        <div class="date">{{item.created_at | date:'short'}}</div>
      </div>

      <div class="single-note-container__content">
        <ng-container *ngIf="(item.text?.old_value || item.text?.new_value) && item?.text?.action !== 'ACTION_UPDATED_NOTE'">
          Changed <span class="property-history__bold">{{item.text?.field}}</span> from
          <span class="property-history__bold">{{isDate(item.text?.old_value) ? (item.text?.old_value | date: 'MM/dd/yyyy') : item.text?.old_value}}</span> to
          <span class="property-history__bold">{{isDate(item.text?.new_value) ? (item.text?.new_value | date: 'MM/dd/yyyy') : item.text?.new_value}}</span>
        </ng-container>

        <ng-container *ngIf="item.text?.textArray.length">
          <span *ngFor="let text of item.text?.textArray" [class.property-history__bold]="text.isBold">
            {{isDate(text.text) ? (text.text | date: 'MM/dd/yyyy') : text.text}}
          </span>
        </ng-container>

        <ng-container *ngIf="isOnHoldTypeText( item.text )">
          <span>{{item?.text?.field}}</span>
        </ng-container>

        <ng-container *ngIf="isSummaryOfScopeTypeText(item?.text)">
          <ng-container [ngSwitch]="item?.text?.action">
            <ng-container *ngSwitchCase="'ACTION_UPDATED_NOTE'">Changed <span class="property-history__bold">Summary of scope</span> from: <span class="property-history__bold">{{item?.text?.old_value || ''}}</span> to: <span class="property-history__bold">{{item?.text?.new_value || ''}}</span>.</ng-container>
            <ng-container *ngSwitchCase="'ACTION_DELETED_NOTE'">Deleted Summary of scope</ng-container>
            <ng-container *ngSwitchCase="'ACTION_CREATED_NOTE'">Added Summary fo scope</ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
</div>

import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map, debounceTime, finalize } from 'rxjs/operators';

import { PermissionsState } from '@core/permissions-state';
import { UserService } from '@core/user.service';
import { RoleAccessTypes, RolePermission } from '@models/roles';

@Injectable()
export class PermissionsService {

  private static readonly requiredPermissions = {
    property: ['VIEW_AVAILABLE_PROPERTIES', 'VIEW_ARCHIVED_PROPERTIES'],
    inspections: ['VIEW_ASSIGNED_INSPECTIONS', 'VIEW_UNASSIGNED_INSPECTIONS'],
    projects: ['VIEW_ACTIVE_PROJECTS', 'VIEW_POTENTIAL_PROJECTS', 'VIEW_COMPLETED_PROJECTS'],
    pipeline: ['VIEW_NOT_PERMITTED_PROJECTS', 'VIEW_PERMITTED_PROJECTS', 'VIEW_AS_IS_PROJECTS']
  };
  private fetchingPermissions: ReplaySubject<boolean>;

  constructor(private userService: UserService) {
    this.permissionsState = new PermissionsState();
  }

  get isPermissionsInited(): boolean {
    return this.permissionsState.currentStateValue !== null;
  }

  get permissionStateReload(): Observable<boolean> {
    return this.permissionsState.currentStateObservable
      .pipe(
        debounceTime(500),
        map(() => true));
  }

  private readonly permissionsState: PermissionsState;

  private static hasSomeAccess(permission: RolePermission): boolean {
    return permission.is_enabled || (permission.access_type && permission.access_type !== RoleAccessTypes.none);
  }

  hasModulePermissions(moduleName: string): boolean {
    if (!this.isPermissionsInited) {
      return false;
    }

    const modulePermissions = this.permissionsState.currentStateValue[moduleName] || [];
    let hasAccess = false;

    if (PermissionsService.requiredPermissions.hasOwnProperty(moduleName)) {
      hasAccess = modulePermissions.some((permission: RolePermission) => {
        const isRequiredPerm = PermissionsService.requiredPermissions[moduleName].includes(permission.guard_key);
        return isRequiredPerm && permission.is_enabled;
      });
    } else {
      hasAccess = modulePermissions
        .filter(PermissionsService.hasSomeAccess)
        .length > 0;
    }

    return hasAccess;
  }

  hasPermission(moduleName: string): (accessControl?: string) => (accessType?: RoleAccessTypes) => boolean {
    return (accessControl?: string): (accessType?: RoleAccessTypes) => boolean => {
      if (!accessControl) {
        return () => this.hasModulePermissions(moduleName);
      }

      const permissionsData = this.permissionsState.currentStateValue[moduleName] || [];
      const permission = permissionsData.find(
        item => item.guard_key === accessControl || item.guard_name === accessControl
      ) || {};

      return (accessType?: RoleAccessTypes): boolean => {
        if (!accessType) {
          return PermissionsService.hasSomeAccess(permission);
        }

        return accessType === RoleAccessTypes.delete ? permission.is_deleted : permission.access_type === accessType;
      };
    };
  }

  resetPermissions(): Observable<boolean> {
    if (!this.fetchingPermissions || this.fetchingPermissions.isStopped) {
      this.fetchingPermissions = new ReplaySubject(1);
      this.userService.fetchMyPermissions()
        .pipe(finalize(() => this.fetchingPermissions.complete()))
        .subscribe((data) => {
          this.permissionsState.updateState(data);
          this.fetchingPermissions.next(true);
        }, () => this.fetchingPermissions.next(false));
    }

    return this.fetchingPermissions.asObservable();
  }

  clearPermissionsState(): void {
    this.permissionsState.clearState();
  }
}

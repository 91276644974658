import { Component, Input } from '@angular/core';
import { MainMenuService } from '@core/main-menu/main-menu.service';

@Component({
  selector: 'cms-main-menu-toggle',
  templateUrl: './main-menu-toggle.component.html',
  styleUrls: ['./main-menu-toggle.component.scss', '../main-menu.component.scss']
})
export class MainMenuToggleComponent {

  @Input()
  isMainMenuShown = false;

  constructor(public menuService: MainMenuService) { }

  get toggleBtnText(): string {
    return this.isMainMenuShown ? 'Hide Menu' : 'Show Menu';
  }
}

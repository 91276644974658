import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { LayoutService } from '@core/layout.service';
import { PropertyService } from '@property/shared/property.service';
import { AbstractReportDialogComponent } from '@shared/abstract-report-dialog/abstract-report-dialog.component';

@Component({
  selector: 'cms-property-report-dialog',
  templateUrl: './property-report-dialog.component.html',
  styleUrls: ['./property-report-dialog.component.scss'],
})
export class PropertyReportDialogComponent extends AbstractReportDialogComponent {

  private propertyId: number;

  constructor(
    public layoutService: LayoutService,
    private propertyService: PropertyService,
    private dialogRef: MatDialogRef<PropertyReportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: { propertyId: number, currentStage: string },
  ) {
    super(layoutService);

    this.propertyId = data.propertyId;
    if (data) {
      this.currentStage = data.currentStage;
      this.pageTypes.currentStage = `Current Stage (${this.currentStage})`;
    }

    this.selectAll();
    this.selectedPageType = this.pageTypes.currentStage;
  }

  clickOnExport(): void {
    if (this.selectedPageType === this.pageTypes.whole) {
      this.selectedPageType = null;
    } else if (this.selectedPageType === this.pageTypes.currentStage) {
      this.selectedPageType = [this.currentStage];
    } else {
      this.selectedPageType = this.prepareConstructionStages();
    }

    this.propertyService.makeReport(
      this.propertyId,
      this.exportFormat,
      this.selectedPageType,
    ).subscribe(() => this.dialogRef.close(true));
  }
}

import { Injectable } from '@angular/core';
import { monthFirstSlashDateFormat } from '@shared/date/datepicker-config';
import { DateError } from '@models/date';

@Injectable()
export class GeneralDateService {

  static getGeneralErrors(dateFormat: string = monthFirstSlashDateFormat): DateError[] {
    return [
      { name: 'matDatepickerParse', message: `Provide date in correct format, e.g. '${dateFormat}'` },
      { name: 'matDatepickerFilter', message: 'Provide not day off date' }
    ];
  }
}

<div class="select-filter__header"
     cdkOverlayOrigin
     #selectFilterBodyTrigger="cdkOverlayOrigin"
     (click)="isBodyShown = !isBodyShown">
  <span class="select-filter__header__text">{{headerText}}</span>
  <mat-icon *ngIf="!isBodyShown" class="select-filter__header__icon">keyboard_arrow_down</mat-icon>
  <mat-icon *ngIf="isBodyShown" class="select-filter__header__icon">keyboard_arrow_up</mat-icon>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="selectFilterBodyTrigger"
  [cdkConnectedOverlayOpen]="isBodyShown"
  [cdkConnectedOverlayHasBackdrop]="true"
  cdkConnectedOverlayBackdropClass="overlay__transparent"
  (backdropClick)="cancel()">
  <mat-card *ngIf="isBodyShown" 
            class="select-filter__body"
            [ngClass]="{'aligned-in-center': isMobile}">
    <mat-card-title class="select-filter__body__title">
      <span class="select-filter__body__title-text">{{title}}</span>
      <button *ngIf="selectedValues.length < values.length"
              mat-button
              class="select-filter__body__all-btn"
              color="accent"
              (click)="selectAll()">
        Select all
      </button>
      <button *ngIf="selectedValues.length === values.length"
              mat-button
              class="select-filter__body__all-btn"
              color="accent"
              (click)="deselectAll()">
        Deselect all
      </button>
    </mat-card-title>
    <mat-card-content class="select-filter__body__content">
      <mat-selection-list #listItems
                          class="select-filter__body__list"
                          [(ngModel)]="selectedValues">
        <mat-list-option *ngFor="let item of values"
                         class="select-filter__body__list-item"
                         [value]="item"
                         [checkboxPosition]="checkboxPosition">
          <ng-container [ngTemplateOutlet]="itemTemplate ? itemTemplate : defaultItemTemplate"
                        [ngTemplateOutletContext]="{ item: item }">
          </ng-container>

          <ng-template #defaultItemTemplate
                       let-item="item">
            <span>{{labelMapper ? labelMapper(item) : item}}</span>
          </ng-template>
        </mat-list-option>
      </mat-selection-list>
    </mat-card-content>
    <mat-card-actions class="select-filter__body__actions">
      <button mat-stroked-button
              color="accent"
              (click)="cancel()">
        Cancel
      </button>
      <button mat-flat-button
              color="accent"
              [disabled]="!selectedValues.length"
              (click)="apply()">
        Apply
      </button>
    </mat-card-actions>
  </mat-card>
</ng-template>

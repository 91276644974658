import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  private static formatPhoneNumber(phone: string): string {
    const cleaned = phone.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{0-2})?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      if (match[1]) {
        return `+${match[1]} ${match[2]} ${match[3]} ${match[4]}`; // International format
      } else {
        return `(${match[2]}) ${match[3]}-${match[4]}`;  // US format
      }
    }
    return phone;
  }

  transform(value: string): string {
    try {
      return PhonePipe.formatPhoneNumber(value);
    } catch (error) {
      return value;
    }
  }

}

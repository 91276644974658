import { Component, Input } from '@angular/core';
import { NavLink } from '@models/core';

@Component({
  selector: 'cms-vertical-nav-bar',
  templateUrl: './vertical-nav-bar.component.html',
  styleUrls: ['./vertical-nav-bar.component.scss']
})
export class VerticalNavBarComponent {

  @Input()
  navLinks: NavLink[] = [];
}

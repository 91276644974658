import { Component, Input } from '@angular/core';
import { UserToValidateStatuses, UserToValidateStatusMessages } from '@models/user/user';
import { CRMUser } from '@models/inspections';


@Component({
  selector: 'cms-user-check',
  templateUrl: './user-check.component.html',
  styleUrls: ['./user-check.component.scss']
})
export class UserCheckComponent {

  @Input()
  user: CRMUser;

  readonly possibleStatuses = { ...UserToValidateStatuses };
  readonly possibleMessages: { [key: string]: string } = { ...UserToValidateStatusMessages };
}

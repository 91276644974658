import { Injectable } from '@angular/core';
import * as moment from 'moment';
import * as JSZip from 'jszip';
import * as JSZipUtils from 'jszip-utils';
import { saveAs } from 'file-saver-es';

import { FilePath } from '@models/core';
import { ToasterService } from './toaster/toaster.service';

@Injectable()
export class FileDownloaderService {

  constructor(private toasterService: ToasterService){}

  tryDownloadZip(files: FilePath[] = [], zipFileName): void {
    if (zipFileName.includes('.zip') && navigator.userAgent.includes('CriOS')){
      console.warn('FileDownloaderService: downloading of ZIP files not supported by IOS Chrome');
      this.toasterService.showToaster({message: 'Unable to download on chrome - please try on Safari'});
      return;
    }
    return this.downloadZip(files, zipFileName);
  }

  downloadZip(files: FilePath[] = [], zipFileName): void {
    this.toasterService.showToaster({message: 'Loading is started'});
    const zip = new JSZip();
    const zipName = zipFileName || `cms-${moment().format('YYYY-MM-DD-HH-mm-ss')}.zip`;
    let count = 0;

    files.forEach((fileInfo: FilePath) => {
      const splitUrl = fileInfo.url.split('/');
      const fileNameFromUrl = splitUrl[splitUrl.length - 1];
      const fileName = fileInfo.nameWithExtention || fileNameFromUrl;

      JSZipUtils.getBinaryContent(fileInfo.url, (err, file) => {
        if (err) {
          throw err;
        }

        zip.file(fileName, file, { binary: true });
        ++count;

        if (count === files.length) {
          zip.generateAsync({ type: 'blob' }).then((content) => {
            saveAs(content, zipName);
            this.toasterService.showToaster({message: 'Loading is finished'});
          });
        }
      });
    });
  }

  downloadOneFile(fileInfo: FilePath): void {
    this.toasterService.showToaster({message: 'Loading is started'});
    const splitUrl = fileInfo.url.split('/');
    const fileNameFromUrl = splitUrl[splitUrl.length - 1];
    const fileName = fileInfo.nameWithExtention || fileNameFromUrl;
    saveAs(fileInfo.url, fileName);
    this.toasterService.showToaster({message: 'Loading is finished'});
  }
}

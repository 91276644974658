import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';

import { AuthGuard } from '@core/auth/auth.guard';
import { UnauthorizedGuard } from '@core/auth/unauthorized.guard';
import { LayoutService } from '@core/layout.service';
import { UserPermissionsResolver } from '@core/user-permissions.resolver';
import { WithoutPermissionsComponent } from '@core/without-permissions/without-permissions.component';
import { NotificationsComponent } from '@core/notifications/notifications/notifications.component';
import { INSPECTIONS_HEADER_HEIGHT } from './shared/inspections-constants';


const mainRoutes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./auth/login/login.module').then(m => m.LoginModule),
    canActivate: [UnauthorizedGuard]
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./auth/reset-password/reset-password.module').then(m => m.ResetPasswordModule),
    canActivate: [UnauthorizedGuard]
  },
  {
    path: 'new-password',
    loadChildren: () => import('./auth/new-password/new-password.module').then(m => m.NewPasswordModule),
    canActivate: [UnauthorizedGuard]
  },
  {
    path: 'no-permissions',
    component: WithoutPermissionsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'notifications',
    component: NotificationsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'projects',
    canActivate: [AuthGuard]
  }
];

const desktopRoutes: Routes = [
  {
    path: 'projects',
    loadChildren: () => import('./projects/desktop/desktop-projects.module').then(m => m.DesktopProjectsModule)
  },
  {
    path: 'pipelines',
    loadChildren: () => import('./pipeline/desktop/desktop-pipeline.module').then(m => m.DesktopPipelineModule)
  },
  {
    path: 'properties',
    loadChildren: () => import('./properties/desktop/desktop-properties.module').then(m => m.DesktopPropertiesModule)
  },
  {
    path: 'properties/:id',
    loadChildren: () => import('./properties/property/desktop/desktop-property.module').then(m => m.DesktopPropertyModule)
  },
  {
    path: 'settings/roles/:id',
    loadChildren: () => import('./settings/role/desktop/desktop-role.module').then(m => m.DesktopRoleModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/desktop/desktop-settings.module').then(m => m.DesktopSettingsModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./user-profile/desktop/desktop-user-profile.module').then(m => m.DesktopUserProfileModule)
  },
  {
    path: 'inspections',
    loadChildren: () => import('./inspections/desktop/desktop-inspections.module').then(m => m.DesktopInspectionsModule),
  },
  {
    path: 'work-schedule',
    loadChildren: () => import('./work-schedule/desktop/desktop-work-schedule.module').then(m => m.DesktopWorkScheduleModule)
  },
  {
    path: 'daily-images-scheduler',
    loadChildren: () =>
      import('./daily-images-scheduler/desktop/desktop-daily-images-scheduler.module').then(m => m.DesktopDailyImagesSchedulerModule)
  }
];

const tabletRoutes: Routes = [
  {
    path: 'projects',
    loadChildren: () => import('./projects/desktop/desktop-projects.module').then(m => m.DesktopProjectsModule)
  },
  {
    path: 'pipelines',
    loadChildren: () => import('./pipeline/desktop/desktop-pipeline.module').then(m => m.DesktopPipelineModule)
  },
  {
    path: 'properties',
    loadChildren: () => import('./properties/desktop/desktop-properties.module').then(m => m.DesktopPropertiesModule)
  },
  {
    path: 'properties/:id',
    loadChildren: () => import('./properties/property/tablet/tablet-property.module').then(m => m.TabletPropertyModule)
  },
  {
    path: 'settings/roles/:id',
    loadChildren: () => import('./settings/role/desktop/desktop-role.module').then(m => m.DesktopRoleModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/desktop/desktop-settings.module').then(m => m.DesktopSettingsModule)
  },
  {
    path: 'inspections',
    loadChildren: () => import('./inspections/desktop/desktop-inspections.module').then(m => m.DesktopInspectionsModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./user-profile/desktop/desktop-user-profile.module').then(m => m.DesktopUserProfileModule)
  },
  {
    path: 'work-schedule',
    loadChildren: () => import('./work-schedule/tablet/tablet-work-schedule.module').then(m => m.TabletWorkScheduleModule)
  },
  {
    path: 'daily-images-scheduler',
    loadChildren: () =>
      import('./daily-images-scheduler/tablet/tablet-daily-images-scheduler.module').then(m => m.TabletDailyImagesSchedulerModule)
  }
];

const mobileRoutes: Routes = [
  {
    path: 'projects',
    loadChildren: () => import('./projects/mobile/mobile-projects.module').then(m => m.MobileProjectsModule)
  },
  {
    path: 'pipelines',
    loadChildren: () => import('./pipeline/mobile/mobile-pipeline.module').then(m => m.MobilePipelineModule)
  },
  {
    path: 'settings/roles/:id',
    loadChildren: () => import('./settings/role/mobile/mobile-role.module').then(m => m.MobileRoleModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/mobile/mobile-settings.module').then(m => m.MobileSettingsModule)
  },
  {
    path: 'inspections',
    loadChildren: () => import('./inspections/mobile/mobile-inspections.module').then(m => m.MobileInspectionsModule)
  },
  {
    path: 'properties',
    loadChildren: () => import('./properties/mobile/mobile-properties.module').then(m => m.MobilePropertiesModule)
  },
  {
    path: 'properties/:id',
    loadChildren: () => import('./properties/property/mobile/mobile-property.module').then(m => m.MobilePropertyModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./user-profile/desktop/desktop-user-profile.module').then(m => m.DesktopUserProfileModule)
  },
  {
    path: 'work-schedule',
    loadChildren: () => import('./work-schedule/mobile/mobile-work-schedule.module').then(m => m.MobileWorkScheduleModule)
  },
  {
    path: 'daily-images-scheduler',
    loadChildren: () =>
      import('./daily-images-scheduler/mobile/mobile-daily-images-scheduler.module').then(m => m.MobileDailyImagesSchedulerModule)
  }
];

const prepareResolutionRoutes = (basicRoutes: any[], resolutionRoutes: any[]) => {
  return [
    ...basicRoutes,
    {
      path: '',
      canActivate: [AuthGuard],
      resolve: { isPermissionsFetched: UserPermissionsResolver },
      children: [
        ...resolutionRoutes,
        {
          path: '',
          pathMatch: 'full',
          redirectTo: 'projects',
        }
      ]
    }
  ];
};

@NgModule({
  imports: [
  RouterModule.forRoot(prepareResolutionRoutes(mainRoutes, desktopRoutes), {
      anchorScrolling: 'enabled',
      scrollOffset: [0, INSPECTIONS_HEADER_HEIGHT],
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
  public constructor(
    private router: Router,
    private layoutService: LayoutService
  ) {
    if (layoutService.isTablet) {
      router.resetConfig(prepareResolutionRoutes(mainRoutes, tabletRoutes));
    }
    if (layoutService.isMobile) {
      router.resetConfig(prepareResolutionRoutes(mainRoutes, mobileRoutes));
    }
  }
}

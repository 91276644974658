import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RequestHelperService } from '@shared/request-helper.service';
import { ApiResponse2, ApiResponseItem, ApiResponseList, ParsedPaginatedApiResponse } from '@models/response';
import { CMSMainNotification, CMSMainNotificationData, CMSMainNotificationGroup } from '@models/notification';
import { Paginator } from '@models/core';
import { ConvertedFiltersValues, NotificationsTypeValues } from '@models/filters';
import { User, UserInfoResponse } from '@models/user/user';
import { UserService } from '@core/user.service';


@Injectable()
export class NotificationsService {

  constructor(private http: HttpClient) { }

  fetchNotifications(
    { page, limit }: Paginator,
    filters?: ConvertedFiltersValues,
    type: NotificationsTypeValues = 'active'
  ): Observable<ParsedPaginatedApiResponse<CMSMainNotification[]>> {
    let paramsObj = { page: page.toString(), limit: limit.toString() };

    if (filters) {
      paramsObj = { ...paramsObj, ...RequestHelperService.prepareFilters(filters) };
    }

    const params = new HttpParams({ fromObject: paramsObj });

    const url = type === 'active'
      ? RequestHelperService.apiPaths.notifications.available
      : RequestHelperService.apiPaths.notifications.archived;

    return this.http.get<ApiResponse2<CMSMainNotification>>(url, { params })
      .pipe(
        map(item => {
          const response = RequestHelperService.parseApiResponseWithPagination<CMSMainNotificationData>(item.data[0]);
          const data: CMSMainNotification[] = response?.data;

          return { ...response, data };
        })
      );
  }

  fetchTeammates(): Observable<User[]> {
    return this.http.get<ApiResponseList<[UserInfoResponse]>>(RequestHelperService.apiPaths.notifications.teamMembers)
      .pipe(
        map(({ data }) => data[0].map(UserService.translateUserSelfInfoResponseToUser))
      );
  }

  fetchUnreadNotificationsCount(): Observable<number> {
    return this.http
    .get<ApiResponseItem<{ count: number }>>(
      RequestHelperService.apiPaths.notifications.unreadCount,
      { headers: { showSpinner: 'false' } }
    )
    .pipe(
      map(({ data }) => data.count)
    );
  }

  markAsRead(ids: number[]): Observable<void> {
    return this.http.put<void>(RequestHelperService.apiPaths.notifications.markAsRead, { data: ids });
  }
}

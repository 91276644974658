import { Component } from '@angular/core';

import { UserToValidateStatuses } from '@models/user/user';
import { UserCheckComponent } from '@shared/user-check/user-check.component';


@Component({ template: '' })
export abstract class AbstractCrmUserInfoComponent extends UserCheckComponent {

  protected constructor() {
    super();
    this.possibleMessages[UserToValidateStatuses.noInCRM] = 'The field is missing. Please update it in the CRM.';
  }
}

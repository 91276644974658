import { Component } from '@angular/core';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';

import { LayoutService } from '@core/layout.service';
import { ParsedCMSNotificationData } from '@models/notification';
import { NotificationsService } from '@core/notifications/notifications.service';
import { FiltersService } from '@shared/filters/filters.service';
import { ParsedPaginatedApiResponse } from '@models/response';
import { AbstractNotificationsComponent } from '@shared/notifications/abstract-notifications';
import { NotificationsDataService } from '@core/notifications/notifications-data.service';
import { NotificationsAssigningFilterService } from '@shared/notifications/notifications-assigning-filter.service';
import { UserService } from '../../user.service';


@Component({
  selector: 'cms-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  providers: [FiltersService, NotificationsAssigningFilterService]
})
export class NotificationsComponent extends AbstractNotificationsComponent {

  isMobile = false;
  protected limit = 25;

  constructor(
    private layoutService: LayoutService,
    notificationsService: NotificationsService,
    filtersService: FiltersService,
    notificationsDataService: NotificationsDataService,
    private router: Router,
    protected userService: UserService
  ) {
    super(filtersService, notificationsService, notificationsDataService, userService);

    this.isMobile = layoutService.isMobile;

    this.subscription.add(
      this.router.events
        .pipe(
          filter((event: RouterEvent) => (event instanceof NavigationStart))
        )
        .subscribe(() => this.markAsRead(this.notifications))
    );
  }

  onScroll(): void {
    if (this.nextPage) {
      this.loadNotifications();
    }
  }

  loadNotifications(): void {
    const page = this.page + 1;
    const limit = this.limit || 25;

    this.notificationsService.fetchNotifications({ page, limit }, this.currentFilters, this.currentNotificationsType)
      .subscribe((response: ParsedPaginatedApiResponse<ParsedCMSNotificationData>) => {
        this.page = page;
        this.nextPage = response.next;
        this.notifications = [...this.notifications, ...(response?.data || [])];
      });
  }
}

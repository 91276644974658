import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable()
export class LayoutService {

  constructor(private deviceService: DeviceDetectorService) { }

  get isDesktop(): boolean {
    return this.deviceService.isDesktop();
  }

  get isTablet(): boolean {
    return this.deviceService.isTablet();
  }

  get isMobile(): boolean {
    return this.deviceService.isMobile();
  }
}

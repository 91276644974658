<div class="desktop-property"
  (window:resize)="closeNotesMenuIfNeeded($event.target.innerWidth)">
  <cms-property-header [propertyAddress]="property?.full_address"
    [isPropertyArchived]="property?.archived"
    (openPropertyArchiveDialog)="openPropertyArchiveDialog()"
    (openPropertyReportDialog)="openPropertyReportDialog()">
    <div class="on-hold-wrapper"
      *ngIf="property?.stage !=='Completed' && ! property?.archived"
      cmsAccessControl
      moduleType="property"
      accessType="PUT_PROPERTY_ON_HOLD">
      <span class="on-hold-wrapper__label">ON HOLD</span>
      <mat-slide-toggle color="accent"
        [(ngModel)]="onHoldState"
        (change)="propertyOnHoldChanged.next(true)">
      </mat-slide-toggle>
    </div>
  </cms-property-header>
  <div class="page-content">
    <cms-desktop-property-header [triggerOnHoldChange]="propertyOnHoldChanged"
      (onHoldToggleState)="onHoldState = $event"></cms-desktop-property-header>
    <div class="page-content__content-wrapper">
      <button class="page-content__content-wrapper__history-toggle mat-elevation-z0"
        [ngClass]="{'revert': !isHistoryMenuOpened}"
        mat-mini-fab
        (click)="toggleHistoryMenu()">
        <mat-icon aria-hidden="false"
          aria-label="Toggle history menu">{{isHistoryMenuOpened ? 'keyboard_arrow_right': 'keyboard_arrow_left'}}
        </mat-icon>
      </button>
      <router-outlet></router-outlet>
    </div>
    <div class="page-content__notes-wrapper"
      [ngClass]="{'shrinked': !isHistoryMenuOpened}">
      <cms-desktop-property-notes-history [ngClass]="{'hidden': !isHistoryMenuOpened}">
      </cms-desktop-property-notes-history>
    </div>
  </div>
</div>

<div class="notifications-panel-filters">
  <cms-select class="notifications-panel-filters__cms-select"
              appearance="outline"
              customArrowIcon="keyboard_arrow_down"
              [selectFormControl]="dateControl"
              [labelMapper]="labelMapper"
              [values]="notificationsDates"
              (selectionChanged)="applyDateFilter($event)">
  </cms-select>
</div>

import { Component } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'cms-desktop-property-notes-history',
  templateUrl: './desktop-property-notes-history.component.html',
  styleUrls: ['desktop-property-notes-history.component.scss'],
})
export class DesktopPropertyNotesAndHistoryComponent {

  propertyNotes = PropertyNotes;
  readonly tabs: PropertyNotes[] = Object.values(PropertyNotes);
  activeTab = PropertyNotes.notes;

  changeActiveTab(event: MatTabChangeEvent): void {
    this.activeTab = this.tabs[event.index];
  }
}

enum PropertyNotes {
  notes = 'Notes',
  history = 'History'
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AssigningFilterValues } from '@models/filters';
import { AssigningFilterService } from '../filters/assigning-filter/assigning-filter.service';

@Injectable()
export class NotificationsAssigningFilterService extends AssigningFilterService {

  protected filterSubject: BehaviorSubject<AssigningFilterValues> =
    new BehaviorSubject(NotificationsAssigningFilterService.getDefaultValue('all', 'team', 'me'));

  constructor() {
    super();
  }
}

<mat-form-field class="select-menu"
                color="accent"
                [appearance]="appearance" [class.with-custom-arrow]="customArrowIcon">
  <mat-label *ngIf="label" class="select-menu__label">{{label}}</mat-label>
  <mat-select [panelClass]="customArrowIcon ? 'select-menu__select-panel with-custom-arrow' : 'select-menu__select-panel'"
              [compareWith]="compareWith"
              disableOptionCentering
              [formControl]="selectFormControl"
              (valueChange)="onSelectionChanged($event)">
    <mat-option *ngIf="defaultSelectedValue"
                class="select-menu__option"
                [value]="defaultSelectedValue">
      <cms-avatar *ngIf="withImage" class="select-menu__option__image" [avatarUrl]="defaultSelectedValue[imageLinkKey]"></cms-avatar>
      <span>{{labelMapper ? labelMapper(defaultSelectedValue) : defaultSelectedValue}}</span>
    </mat-option>

    <ng-container *ngIf="!needPermission">
      <mat-option *ngFor="let value of values"
                  class="select-menu__option"
                  [value]="value">
        <cms-avatar *ngIf="withImage" class="select-menu__option__image" [avatarUrl]="value[imageLinkKey]"></cms-avatar>
        <span>{{labelMapper ? labelMapper(value) : value}}</span>
      </mat-option>
    </ng-container>

    <ng-container *ngIf="needPermission">
      <mat-option *ngFor="let value of values"
                  class="select-menu__option"
                  [value]="value"
                  cmsAccessControl="value.permission ? '' : null"
                  [moduleType]="value.module"
                  [accessType]="value.permission">
        <cms-avatar *ngIf="withImage" class="select-menu__option__image" [avatarUrl]="value[imageLinkKey]"></cms-avatar>
        <span>{{labelMapper ? labelMapper(value) : value}}</span>
      </mat-option>
    </ng-container>
  </mat-select>
  <span *ngIf="customArrowIcon" matSuffix class="select-menu__arrow">
    <mat-icon class="select-menu__arrow-icon">{{customArrowIcon}}</mat-icon>
  </span>
</mat-form-field>

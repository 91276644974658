import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

@Pipe({
  name: 'notificationsDate'
})
export class NotificationsDatePipe extends DatePipe implements PipeTransform {

  transform(value: any, format?: string, timezone?: string, locale?: string): string | null {
    return moment().isSame(value, 'day') ?
      `Today, ${super.transform(value, 'hh:mm a', timezone, locale)}` :
      super.transform(value, format, timezone, locale);
  }
}

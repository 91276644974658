import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'cms-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent {

  @Input()
  avatarUrl: string;

  private placeholderUrl = '/assets/images/avatar-placeholder.svg';

  constructor(private sanitizer: DomSanitizer) { }

  get bgImageStyle(): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(`url(${this.avatarUrl || this.placeholderUrl})`);
  }
}

<div class="delete-note-dialog">
  <div class="delete-note-dialog__header">
    <h3 mat-dialog-title>Delete Note</h3>
    <button mat-icon-button [mat-dialog-close]>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-dialog-content class="delete-note-dialog__content">
    <p>You are about to delete the note</p>
    <p><strong>This action cannot be undone!</strong></p>
    <p>Are you sure?</p>
  </mat-dialog-content>

  <div mat-dialog-actions class="delete-note-dialog__actions">
    <button mat-stroked-button
            color="accent"
            [mat-dialog-close]>
      Cancel
    </button>
    <button mat-flat-button
            color="accent"
            (click)="deleteNote()">
      Delete
    </button>
  </div>
</div>

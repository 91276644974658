<div class="main-header"
     [class.main-header__with-margin]="isDesktop"
     [ngClass]="{'tablet-main-header': isTablet, 'mobile-main-header': isMobile}">
  <button *ngIf="!isDesktop"
          mat-icon-button
          class="main-header__menu-btn"
          color="primary"
          aria-label="menu button"
          (click)="menuService.toggleMainMenu()">
    <mat-icon>menu</mat-icon>
  </button>
  <ng-content></ng-content>

  <div class="main-header__profile-stuff">
    <div *ngIf="!isMobile" [class.main-header__notif-btn]="isDesktop">
      <cms-notification-bell (isClicked)="toggleNotifPanel()"></cms-notification-bell>
    </div>

    <cms-avatar *ngIf="isDesktop"
                class="main-header__avatar"
                [avatarUrl]="selfInfo?.avatarUrl"
                routerLink="/profile"
                #avatarTooltip="matTooltip"
                matTooltip="View profile"
                matTooltipPosition="below">
    </cms-avatar>

    <button *ngIf="isDesktop"
            mat-icon-button
            class="main-header__logout-btn"
            #logoutTooltip="matTooltip"
            matTooltip="Log out"
            matTooltipPosition="below"
            [disabled]="isLogOutBtnPressed"
            (click)="logOut()">
      <mat-icon>logout</mat-icon>
    </button>
  </div>
</div>

<cms-notifications-panel *ngIf="isNotifPanelShown" (panelIsClosed)="toggleNotifPanel()"></cms-notifications-panel>

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

import { PropertyService } from '@property/shared/property.service';

import { FlipStageValidationData, PropertyStageRoutes, PropertyRoutes } from '@models/property/property';
import { FormControl, Validators } from '@angular/forms';
import { mapperInvalidFieldNameToMessage } from '../../mapperOfInvalidFieldsOnFlipStage';

@Component({
  selector: 'cms-change-stage-confirmation',
  templateUrl: 'change-stage-confirmation-dialog.component.html',
  styleUrls: ['./change-stage-confirmation-dialog.component.scss']
})
export class ChangeStageConfirmationDialogComponent {

  readonly propertyId: number;
  readonly selectedStage: FlipStageValidationData;

  requestAlreadySent = false;
  reasonFormControl = new FormControl(
      '',
      [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(250)
      ]
  );

  constructor(
    public dialogRef: MatDialogRef<ChangeStageConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: { propertyId: number, selectedStage: FlipStageValidationData },
    private propertyService: PropertyService,
    private router: Router
  ) {
    this.propertyId = data.propertyId;
    this.selectedStage = data.selectedStage;
    this.reasonFormControl.markAsTouched();
  }

  mapperInvalidFieldNameToMessage = mapperInvalidFieldNameToMessage;

  public isDisabled(): boolean {
    return (this.selectedStage.required.length) ? !this.reasonFormControl.valid : false;
  }

  public flipStage(): void {
    const preparedStageData = { stage: this.selectedStage.name, reason: this.reasonFormControl.value};

    this.propertyService.flipToSelectedStage(this.propertyId, preparedStageData)
      .pipe(
        finalize(() => this.requestAlreadySent = false)
      )
      .subscribe(() => {
        const path = PropertyStageRoutes[PropertyService.toCamelCase(this.selectedStage.name)];

        this.router.navigate(['properties', this.propertyId, PropertyRoutes.pipeline, path])
          .then(() => window.location.reload());

        this.dialogRef.close(true);
      });
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { User } from '@models/user/user';
import { MainMenuService } from '@core/main-menu/main-menu.service';
import { LayoutService } from '@core/layout.service';
import { UserService } from '@core/user.service';
import { AuthService } from '@core/auth/auth.service';

@Component({
  selector: 'cms-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss']
})
export class MainHeaderComponent implements OnInit, OnDestroy {

  selfInfo: Partial<User>;
  isDesktop = true;
  isMobile = false;
  isTablet = false;
  isLogOutBtnPressed = false;
  isNotifPanelShown = false;

  private subscription: Subscription = new Subscription();

  constructor(
    public menuService: MainMenuService,
    private layoutService: LayoutService,
    private userService: UserService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.isDesktop = this.layoutService.isDesktop;
    this.isMobile = this.layoutService.isMobile;
    this.isTablet = this.layoutService.isTablet;

    if (this.isDesktop) {
      this.getSelfInfo();
    }
  }

  getSelfInfo(): void {
    this.userService.loadSelfInfo()
      .subscribe(({ fullName, avatarUrl}) => this.selfInfo = { fullName, avatarUrl });
  }

  toggleNotifPanel(): void {
    this.isNotifPanelShown = !this.isNotifPanelShown;
  }

  logOut(): void {
    if (this.isLogOutBtnPressed) {
      return;
    }

    this.isLogOutBtnPressed = true;
    this.authService.logOut();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

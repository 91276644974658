import { Component, Input } from '@angular/core';

@Component({
  selector: 'cms-crm-field-info-view',
  templateUrl: './crm-field-info-view.component.html',
  styleUrls: ['../crm-fields-info-view.scss']
})
export class CrmFieldInfoViewComponent {

  @Input()
  value: any;

  @Input()
  fieldName = 'This';
}

<cms-main-header>
  <div class="header-tabs" [class.header-tabs__not-desktop]="!isDesktop">
    <h2 *ngIf="isShowTitle(title)">{{title}}</h2>
    <div *ngIf="!isMobile && statusesTabs?.length > 0" class="header-tabs__toggles-box">
      <mat-button-toggle-group name="Statuses"
                               aria-label="Statuses">
        <mat-button-toggle *ngFor="let status of statusesTabs"
                           cmsAccessControl="status.permission ? '' : null"
                           [moduleType]="status.module"
                           [accessType]="status.permission"
                           [value]="status.value"
                           [checked]="status.value === this.currentStatus"
                           (click)="navigateTo(status.link)">
          {{status.value}}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <cms-select *ngIf="isMobile && statusesTabs?.length > 0"
                class="header-tabs__select-status"
                appearance="outline"
                [selectFormControl]="selectFormControl"
                [values]="statusesTabs"
                [labelMapper]="tabsMapper"
                [needPermission]="true"
                (selectionChanged)="navigateTo($event.link)">
    </cms-select>

    <ng-content></ng-content>
  </div>
</cms-main-header>

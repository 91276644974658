import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { LayoutService } from '@core/layout.service';
import { PermissionsService } from '@core/permissions.service';
import { PropertiesNote } from '@models/property/property';
import { RoleAccessTypes } from '@models/roles';
import { PropertyService } from '../property.service';
import { PropertyStateService } from '../property-state.service';
import { AddNoteDialogComponent } from './add-note-dialog/add-note-dialog.component';
import { DeleteNoteDialogComponent } from './delete-note-dialog/delete-note-dialog.component';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({ template: '' })
export abstract class AbstractPropertyNotesComponent implements OnInit, OnDestroy {
  @Input()
  stage: string = null;

  notes: PropertiesNote[];

  abstract moduleType: string;
  abstract addAccessType: string;
  abstract editAccessType: string;
  abstract deleteAccessType: string;
  specificAccess: RoleAccessTypes = null;
  private subscription: Subscription = new Subscription();
  private propertyId: number;

  protected constructor(
    private propertyService: PropertyService,
    private layoutService: LayoutService,
    private dialog: MatDialog,
    protected permissionsService: PermissionsService,
    protected propertyStateService: PropertyStateService
  ) {
    this.propertyId = this.propertyStateService.propertyId;
  }

  ngOnInit(): void {
    this.fetchNotes();
    this.subscription.add(
      this.propertyStateService.needUpdateNotesList
        .pipe(filter((event) => event === true))
        .subscribe(() => {
          this.propertyId = this.propertyStateService.propertyId;
          this.fetchNotes();
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  protected fetchNotes(): void {
    this.propertyService
      .fetchPropertiesNotes(this.propertyId, this.stage)
      .subscribe((info) => (this.notes = info));
  }

  openAddNoteDialog(note?: PropertiesNote): void {
    if (
      note &&
      !this.permissionsService.hasPermission(this.moduleType)(this.editAccessType)(
        this.specificAccess
      )
    ) {
      return;
    }

    if (
      !note &&
      !this.permissionsService.hasPermission(this.moduleType)(this.addAccessType)(
        this.specificAccess
      )
    ) {
      return;
    }

    const dialogRef = this.dialog.open(AddNoteDialogComponent, {
      data: { propertyId: this.propertyId, stage: this.stage, note },
      panelClass: this.layoutService.isMobile ? 'cms-dialog-mobile' : 'cms-dialog',
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(() => {
      this.fetchNotes();
      this.propertyStateService.needUpdateHistory.emit(true);
    });
  }

  openDeleteNoteDialog(noteId: number): void {
    if (
      !this.permissionsService.hasPermission(this.moduleType)(this.deleteAccessType)(
        this.specificAccess
      )
    ) {
      return;
    }

    const dialogRef = this.dialog.open(DeleteNoteDialogComponent, {
      data: { propertyId: this.propertyId, stage: this.stage, noteId },
      panelClass: 'cms-dialog',
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(() => {
      this.fetchNotes();
      this.propertyStateService.needUpdateHistory.emit(true);
    });
  }
}

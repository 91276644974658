import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatSelectionList } from '@angular/material/list';
import { LayoutService } from '../../../core/layout.service';

@Component({
  selector: 'cms-select-eliminated-filter',
  templateUrl: './select-eliminated-filter.component.html',
  styleUrls: ['./select-eliminated-filter.component.scss']
})
export class SelectEliminatedFilterComponent implements OnInit {
  headerText = '';
  isBodyShown = false;
  @ViewChild('listItems', { static: false })
  listItems: MatSelectionList;
  @Input()
  title: string;
  @Input()
  itemTemplate: TemplateRef<{ item: any }>;
  @Input()
  checkboxPosition: 'before' | 'after' = 'after';
  @Input()
  labelMapper: (item: any) => string;
  @Input()
  selectedLabelMapper: (selectedItems: any[], allItems: any[], title: string) => string;
  @Output()
  selectionChanged = new EventEmitter<any[]>();
  @Input() set eliminatedValues(values: { label?: string; values: any[] }[]) {
    this.mergedValues = values.reduce((mergedValues, group) => {
      mergedValues = [...mergedValues, ...group?.values];
      return mergedValues;
    }, []);

    this.groups = values;
  }

  groups: { label?: string; values: any[] }[] = [];

  mergedValues: any[] = [];
  selectedValues: any[] = [];
  @Input()
  get defaultSelectedValues(): any[] {
    return this.defaultSelected;
  }
  set defaultSelectedValues(values: any[]) {
    this.defaultSelected = values;
    this.selectedValues = values;
    this.updateHeaderText();
  }
  private defaultSelected: any[] = [];
  get isMobile(): boolean {
    return this.layoutService.isMobile;
  }

  constructor(public layoutService: LayoutService){}

  ngOnInit(): void {
    this.updateHeaderText();
  }

  updateHeaderText(): void {
    this.headerText = this.selectedLabelMapper
      ? this.selectedLabelMapper(this.selectedValues, this.mergedValues, this.title)
      : (this.headerText = this.selectedValues.join(', '));
  }

  selectAll(): void {
    this.selectedValues = this.mergedValues;
    this.listItems.selectAll();
  }

  deselectAll(): void {
    this.selectedValues = [];
    this.listItems.deselectAll();
  }

  apply(): void {
    this.updateHeaderText();
    this.selectionChanged.emit(this.selectedValues);
    this.isBodyShown = false;
  }

  cancel(): void {
    this.isBodyShown = false;
    this.selectedValues = this.defaultSelectedValues;
    this.updateHeaderText();
  }
}

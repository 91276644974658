import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MainMenuService } from '@core/main-menu/main-menu.service';
import { MainMenuItem } from '@models/main-menu';

@Component({
  selector: 'cms-main-menu-dropdown',
  templateUrl: './main-menu-dropdown.component.html',
  styleUrls: ['./main-menu-dropdown.component.scss', '../main-menu.component.scss']
})
export class MainMenuDropdownComponent implements OnInit, OnDestroy {

  isMenuDropdownShown = false;
  activeMenuItem: MainMenuItem = { permission: 'projects', name: 'Projects', url: '/projects', icon: { name: 'folder_special' } };
  private subscription: Subscription = new Subscription();

  constructor(public menuService: MainMenuService) { }

  ngOnInit(): void {
    this.subscription.add(
      this.menuService.getActiveItem().subscribe((item: MainMenuItem) => this.activeMenuItem = item)
    );
  }

  toggleMenuDropDown(): void {
    this.isMenuDropdownShown = !this.isMenuDropdownShown;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

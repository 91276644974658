import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cms-property-header',
  templateUrl: './property-header.component.html',
  styleUrls: ['./property-header.component.scss']
})
export class PropertyHeaderComponent {

  @Input()
  propertyAddress = '';
  @Input()
  isPropertyArchived = false;

  @Output()
  openPropertyArchiveDialog = new EventEmitter<void>();
  @Output()
  openPropertyReportDialog = new EventEmitter<void>();

}

import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin, Subscription } from 'rxjs';

import { GeneralInfo } from '@models/core';
import { RoleBaseFormState, RoleBaseInfo } from '@models/roles';
import { RoleBaseFormService } from '@shared/role/role-base-form.service';

@Component({
  selector: 'cms-role-base-form',
  templateUrl: './role-base-form.component.html',
  styleUrls: ['./role-base-form.component.scss']
})
export class RoleBaseFormComponent implements OnDestroy {

  departments: GeneralInfo[] = [];
  reportingRoles: GeneralInfo[] = [];

  formGroup: FormGroup;
  @Output()
  formChanged = new EventEmitter<RoleBaseFormState>();

  @Input()
  set roleBaseInfo(roleInfo: RoleBaseInfo) {
    if (!(this.roleInfo && this.roleInfo.id)) {
      this.roleInfo = { ...roleInfo };
      this.roleNameControl.setValue(this.roleInfo.name);
    }

    this.loadData();
  }
  get roleBaseInfo(): RoleBaseInfo {
    return this.roleInfo;
  }
  private roleInfo: RoleBaseInfo;

  private subscription: Subscription = new Subscription();

  dataMapper: (data: GeneralInfo) => string = (data: GeneralInfo) => data.name;

  constructor(
    private formBuilder: FormBuilder,
    private roleBaseFormService: RoleBaseFormService
  ) {
    this.formGroup = this.formBuilder.group({
      roleName: ['', [Validators.required]],
      department: [],
      reportingRole: []
    });

    this.subscription.add(
      this.formGroup.valueChanges.subscribe((value) => {
        this.formChanged.emit({
          valid: this.formGroup.valid,
          ...value
        });
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private loadData(): void {
    const currenRoleId = this.roleInfo ? this.roleInfo.id : null;

    forkJoin<GeneralInfo[], GeneralInfo[]>(
      [this.roleBaseFormService.fetchDepartments(), this.roleBaseFormService.fetchReportingRoles(currenRoleId)]
    ).subscribe(([departments, roles]: [GeneralInfo[], GeneralInfo[]]) => {
      this.departments = departments;
      this.reportingRoles = roles;

      if (this.roleInfo && this.roleInfo.department) {
        this.departmentControl.setValue(this.departments
          .find((department: GeneralInfo) => department.id === this.roleInfo.department.id));
      }

      if (this.roleInfo && this.roleInfo.report_to) {
        this.reportingRoleControl.setValue(this.reportingRoles
          .find((role: GeneralInfo) => role.id === this.roleInfo.report_to.id));
      }
    });
  }

  private get roleNameControl(): AbstractControl {
    return this.formGroup.controls.roleName;
  }

  private get departmentControl(): AbstractControl {
    return this.formGroup.controls.department;
  }

  private get reportingRoleControl(): AbstractControl {
    return this.formGroup.controls.reportingRole;
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class SearchInputService {
  private searchSubject: BehaviorSubject<string> = new BehaviorSubject('');

  get search(): Observable<string> {
    return this.searchSubject.asObservable();
  }

  set searchValue(value: string) {
    this.searchSubject.next(value);
  }
}

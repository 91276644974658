<div class="main-menu-toggle__box">
  <mat-divider *ngIf="!isMainMenuShown" class="main-menu-toggle__divider"></mat-divider>
  <div class="main-menu-toggle" (click)="menuService.toggleMainMenu()">
    <span class="mat-caption">{{toggleBtnText}}</span>
    <mat-icon *ngIf="isMainMenuShown" class="main-menu__icon">
      arrow_drop_up
    </mat-icon>
    <mat-icon *ngIf="!isMainMenuShown" class="main-menu__icon">
      arrow_drop_down
    </mat-icon>
  </div>
  <mat-divider *ngIf="!isMainMenuShown" class="main-menu-toggle__divider"></mat-divider>
</div>

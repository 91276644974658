// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  backend: {
    baseURL: 'https://api-cms-dev.cloud-hboa.net',
    clientId: 2,
    clientSecret: 'sq9srqr5904lIepks6KK7HoleSxgwnpadVxzQkVe'
  },
  firebase: {
    apiKey: 'AIzaSyBAyvQ7uwBeqcXaeXEpEzB2DQ4OBWKkYh0',
    authDomain: 'cms-portal-c7897.firebaseapp.com',
    databaseURL: 'https://cms-portal-c7897.firebaseio.com',
    projectId: 'cms-portal-c7897',
    storageBucket: 'cms-portal-c7897.appspot.com',
    messagingSenderId: '517620528360',
    appId: '1:517620528360:web:5160998f9a6c553ea5055d',
    measurementId: 'G-WMHZCBS263'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { Component, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { LayoutService } from '@core/layout.service';
import { MainMenuService } from '@core/main-menu/main-menu.service';
import { PermissionsService } from '@core/permissions.service';
import { AbstractPropertyHeaderComponent } from '@property/shared/abstract-property-header';
import { PropertyStateService } from '@property/shared/property-state.service';
import { PropertyService } from '@property/shared/property.service';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { PropertyHistorySidebarStateService } from '../property-history-sidebar-state.service';

@Component({
  selector: 'cms-desktop-property-header',
  templateUrl: './desktop-property-header.component.html',
  styleUrls: ['../../shared/shared-property.scss', './desktop-property-header.component.scss']
})
export class DesktopPropertyHeaderComponent
  extends AbstractPropertyHeaderComponent
  implements OnInit
{
  isMainMenuShown = false;
  isHistorySideBarShown = false;
  @Input() triggerOnHoldChange: Subject<boolean>;
  @Output() get onHoldToggleState(): Observable<boolean> {
    return this.onHoldControl.valueChanges;
  }

  constructor(
    router: Router,
    sanitizer: DomSanitizer,
    dialog: MatDialog,
    layoutService: LayoutService,
    propertyStateService: PropertyStateService,
    propertyService: PropertyService,
    private menuService: MainMenuService,
    protected permissionsService: PermissionsService,
    private propertyHistorySidebarStateService: PropertyHistorySidebarStateService
  ) {
    super(
      router,
      sanitizer,
      dialog,
      layoutService,
      propertyStateService,
      propertyService,
      permissionsService
    );

    this.subscription.add(
      this.menuService
        .isMainMenuShown()
        .subscribe((isShown: boolean) => (this.isMainMenuShown = isShown))
    );

    this.subscription.add(
      this.propertyHistorySidebarStateService.stateObservable.subscribe(
        (state) => (this.isHistorySideBarShown = state === 'opened')
      )
    );
  }

  ngOnInit(): void {
    this.subscription.add(
      this.triggerOnHoldChange
        .asObservable()
        .pipe(filter((event) => event))
        .subscribe(() => this.toggleOnChange())
    );
  }
}

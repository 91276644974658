import { Component, Input } from '@angular/core';

@Component({
  selector: 'cms-yes-no-auto-hideable',
  templateUrl: './yes-no-auto-hideable.component.html',
  styleUrls: ['./yes-no-auto-hideable.component.scss']
})
export class YesNoAutoHideableComponent {
  @Input() value: boolean | string | null;
  get isHaveToBeHidden(): boolean {
    if (this.value === null || this.value === undefined) { return true; }
    return false;
  }

  get valueCastedToBoolean(): boolean {
    if (typeof this.value === 'boolean') { return this.value === true; }
    else { return this.value?.toLocaleLowerCase() === 'yes'; }
  }
}

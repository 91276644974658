import { BehaviorSubject, Observable } from 'rxjs';

import { RolePermission } from '@models/roles';

export class PermissionsState {

  private state: BehaviorSubject<PermissionsByModule> = new BehaviorSubject(null);
  private state$: Observable<PermissionsByModule> = this.state.asObservable();

  get currentStateObservable(): Observable<PermissionsByModule> {
    return this.state$;
  }
  get currentStateValue(): PermissionsByModule {
    return this.state.getValue();
  }

  clearState(): void {
    this.state.next(null);
  }

  updateState(data: RolePermission[]): void {
    this.state.next(this.preparePermissions(data));
  }

  private preparePermissions(permissionsData: RolePermission[]): PermissionsByModule {
    const permissions: PermissionsByModule = {
      property: [],
      inspections: [],
      projects: [],
      pipeline: [],

      settings: [],

      propertyPipeline: [],

      propertyPipelineNew: [],
      propertyPipelineDrawing: [],
      propertyPipelineBudget: [],
      propertyPipelineCashout: [],

      propertyPipelineConstructionStarted: [],
      propertyPipelinePurchased: [],
      propertyPipelineListed: [],
      propertyPipelineConstructionCompleted: [],

      propertyPipelineHIReceived: [],
      propertyPipelineHICompleted: [],
      propertyPipelineSettled: [],

      workSchedule: [],
      testPermissions: []
    };

    permissionsData.forEach(permission => {
      switch (permission.guard_key) {
        case 'VIEW_AVAILABLE_PROPERTIES':
        case 'VIEW_ARCHIVED_PROPERTIES':
        case 'ADDING_PROPERTIES_FROM_CRM':
        case 'ARCHIVE_PROPERTIES':
        case 'UNARCHIVE_PROPERTIES':
        case 'PROPERTIES_GENERATE_REPORTS':
        case 'PUT_PROPERTY_ON_HOLD':
        case 'DELETE_DAILY_IMAGE_PICTURE':
        case 'EDIT_DAILY_IMAGE_PICTURE':
          return permissions.property.push(permission);
        case 'ASSIGNING_INSPECTION':
        case 'VIEW_ASSIGNED_INSPECTIONS':
        case 'VIEW_UNASSIGNED_INSPECTIONS':
          return permissions.inspections.push(permission);
        case 'VIEW_ACTIVE_PROJECTS':
        case 'VIEW_POTENTIAL_PROJECTS':
        case 'VIEW_COMPLETED_PROJECTS':
        case 'ADDING_NEW_PROJECT':
        case 'STARTING_NEW_PROJECT':
        case 'OVERRIDE_STAGES':
        case 'ADD_NOTES':
        case 'EDIT_NOTES':
        case 'DELETE_NOTES':
        case 'MOVE_TO_INSPECTION':
          return permissions.projects.push(permission);
        case 'VIEW_NOT_PERMITTED_PROJECTS':
        case 'VIEW_PERMITTED_PROJECTS':
        case 'VIEW_AS_IS_PROJECTS':
        case 'PIPELINE_GENERATE_REPORTS':
          return permissions.pipeline.push(permission);
        case 'PIPELINE':
        case 'MARKETS':
        case 'COUNTIES':
        case 'ROLES':
        case 'DEPARTMENTS':
        case 'USERS':
        case 'HOLIDAYS':
          return permissions.settings.push(permission);
        case 'NEW':
        case 'DRAWING':
        case 'BUDGET':
        case 'CASH_OUT':
        case 'CONSTRUCTION_STARTED':
        case 'PURCHASED':
        case 'LISTED':
        case 'CONSTRUCTION_COMPLETED':
        case 'HI_RECEIVED':
        case 'HI_COMPLETED':
        case 'SETTLED':
          return permissions.propertyPipeline.push(permission);
        case 'NEW_DEAL_TYPE':
        case 'NEW_JOB_TYPE':
        case 'NEW_FIELD_MANAGER':
        case 'NEW_SPEC_LEVEL':
        case 'NEW_PERMITTING':
        case 'NEW_ARCHITECT_SITE_VISIT':
        case 'NEW_ARCHITECT':
        case 'NEW_DRAWING_START':
        case 'NEW_PURCHASING_SITE_VISIT':
        case 'NEW_PURCHASING':
        case 'NEW_BUDGET_START':
        case 'NEW_ADD_SUMMARY_SCOPE_OF_WORK':
        case 'NEW_EDIT_SUMMARY_SCOPE_OF_WORK':
        case 'NEW_DELETE_SUMMARY_SCOPE_OF_WORK':
        case 'COMBO_LB_ONE_INSTALLED':
        case 'COMBO_LB_TWO_INSTALLED':
          return permissions.propertyPipelineNew.push(permission);
        case 'DRAWING_ESTIMATED_DURATION':
        case 'DRAWING_ACTUAL_START':
        case 'DRAWING_ACTUAL_END':
        case 'DRAWING_DRAWING_FINAL_APPROVAL':
          return permissions.propertyPipelineDrawing.push(permission);
        case 'BUDGET_SCOPE_CHECKLIST_SCANNED':
        case 'BUDGET_GC_BID':
        case 'BUDGET_PO_LANDSCAPING':
        case 'BUDGET_PRE_CONSTRUCTION_CHECKLIST_SIGNED':
        case 'BUDGET_CASHOUT_WALKTHROUGH':
        case 'BUDGET_CASHOUT':
        case 'BUDGET_BUILDING_PERMIT':
        case 'BUDGET_JOB_START':
        case 'BUDGET_PO_ELECTRICAL':
        case 'BUDGET_PO_PLUMBING':
        case 'BUDGET_PO_HVAC':
        case 'BUDGET_PURCHASING_FLOORING':
        case 'BUDGET_PURCHASING_WINDOWS':
        case 'BUDGET_PURCHASING_CABS':
        case 'BUDGET_PURCHASING_GRANITE':
        case 'BUDGET_LETTER_OF_NOTIFICATION_SENT':
        case 'BUDGET_MANUAL_J':
        case 'BUDGET_PLUMBING_RISER':
        case 'BUDGET_ENGINE_STAMPS':
          return permissions.propertyPipelineBudget.push(permission);
        case 'CASHOUT_SEPTIC_REPORT_STATUS':
        case 'CASHOUT_WELL_REPORT_COMPLETED':
        case 'CASHOUT_TITLE_CLEAR':
        case 'CASHOUT_PAYOFF_BELOW_ACQUISITION_PRICE':
          return permissions.propertyPipelineCashout.push(permission);
        case 'CONSTRUCTION_STARTED_JOB_START_MEETING':
        case 'CONSTRUCTION_STARTED_APPROVED_SCHEDULE_TO_SUBS':
        case 'CONSTRUCTION_STARTED_NEW_UPGRADED_SVC_ORDERED':
        case 'CONSTRUCTION_STARTED_TFP_INSPECTION_PASSED':
        case 'CONSTRUCTION_STARTED_REALTOR_BOX_INSTALLED':
        case 'CONSTRUCTION_STARTED_SIGN_INSTALLED':
        case 'CONSTRUCTION_STARTED_LIST_DATE':
          return permissions.propertyPipelineConstructionStarted.push(permission);
        case 'PURCHASED_FLYER_BOX_INSTALLED':
        case 'PURCHASED_PURCH_QCC_PASSED':
          return permissions.propertyPipelinePurchased.push(permission);
        case 'LISTED_OUTSALE_SETTLEMENT':
        case 'LISTED_PP_POST_QCC_SIGNED_OFF':
        case 'LISTED_PROFESSIONAL_PICTURES':
        case 'LISTED_HI_ITEMS':
          return permissions.propertyPipelineListed.push(permission);
        case 'HI_RECEIVED_HI_INITIAL_REQ_RECD':
        case 'HI_RECEIVED_HI_INITIAL_REST_SENT':
        case 'HI_RECEIVED_FINAL_HI_LIST_RECD':
        case 'HI_RECEIVED_HI_LIST_COMPLETE':
          return permissions.propertyPipelineHIReceived.push(permission);
        case 'HI_COMPLETED_HI_REMOVAL_ADDEN_SENT':
        case 'HI_COMPLETED_HI_REMOVAL_SIGNED':
          return permissions.propertyPipelineHICompleted.push(permission);
        case 'SETTLED_REMOVED_SIGN':
        case 'SETTLED_REMOVED_COMBO_BOXES':
        case 'SETTLED_REMOVED_REALTOR_BOX':
        case 'SETTLED_REMOVED_FLYER_BOX':
        case 'SETTLED_REMOVED_EQUIPMENT':
        case 'SETTLED_PP_POST_SALE_SIGNED_OFF_PURCHASING':
        case 'SETTLED_PP_POST_SALE_SIGNED_OFF_ACCOUNTING':
          return permissions.propertyPipelineSettled.push(permission);
        case 'VIEW_WORK_SCHEDULE':
          return permissions.workSchedule.push(permission);
        case 'TEST_RULE':
          return permissions.testPermissions.push(permission);
      }
    });

    return permissions;
  }
}

interface PermissionsByModule {
  property: RolePermission[];
  inspections: RolePermission[];
  projects: RolePermission[];
  pipeline: RolePermission[];

  settings: RolePermission[];

  propertyPipeline: RolePermission[];

  propertyPipelineNew: RolePermission[];
  propertyPipelineDrawing: RolePermission[];
  propertyPipelineBudget: RolePermission[];
  propertyPipelineCashout: RolePermission[];

  propertyPipelineConstructionStarted: RolePermission[];
  propertyPipelinePurchased: RolePermission[];
  propertyPipelineListed: RolePermission[];
  propertyPipelineConstructionCompleted: RolePermission[];

  propertyPipelineHIReceived: RolePermission[];
  propertyPipelineHICompleted: RolePermission[];
  propertyPipelineSettled: RolePermission[];

  workSchedule: RolePermission[];
  testPermissions: RolePermission[];
}

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Moment } from 'moment';

@Component({
  selector: 'cms-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent {
  @Output() selectedChange = new EventEmitter();
  @Output() monthSelected = new EventEmitter();
  @Input() set filterDates(filter: (date: Date) => boolean) {
    if (filter && !this.dateFilter) {
      this.dateFilter = filter;
    }
  }
  @Input() selectedDate: Moment;
  @Input() isDisabledDatePicking = false;
  @Input() startAt: Moment;

  dateFilter: (date: Date) => boolean;

  onDateChange(changed): void {
    if (!this.isDisabledDatePicking) {
      this.selectedChange.emit(changed);
    }
  }

  onMonthChange(changed): void {
    this.monthSelected.emit(changed);
  }
}

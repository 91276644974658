import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';

import { InspectionFieldsToValidate, InspectionValidation } from '@models/inspections';
import { AddToProjectsService } from '../add-to-projects.service';
import { UserToValidateStatuses } from '@models/user/user';

@Component({
  selector: 'cms-add-to-project-dialog',
  templateUrl: './add-to-project-dialog.component.html',
  styleUrls: ['./add-to-project-dialog.component.scss']
})
export class AddToProjectDialogComponent {

  requestAlreadySent = false;
  isInspectionFulfilled = false;
  selectedInspection: InspectionFieldsToValidate;

  constructor(
    private dialogRef: MatDialogRef<AddToProjectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: { inspection: InspectionValidation },
    private addToProjectsService: AddToProjectsService
  ) {
    this.selectedInspection = data.inspection.fields;

    if (!this.selectedInspection.basement_finish && this.selectedInspection.basement_type !== 'Yes') {
      this.selectedInspection.basement_finish = ' ';
    }

    this.isInspectionFulfilled = Object.keys(this.selectedInspection).every(
      (key: string) => {
        switch (key) {
          case 'deal_type':
            return true;
          case 'rec':
            return this.selectedInspection.crm_rec && this.selectedInspection.crm_rec.status === UserToValidateStatuses.ok;
          case 'sc_acquisition':
            return this.selectedInspection.crm_sc_acquisition &&
              this.selectedInspection.crm_sc_acquisition.status === UserToValidateStatuses.ok;
          case 'sc_outsale':
            return this.selectedInspection.crm_sc_outsale && this.selectedInspection.crm_sc_outsale.status === UserToValidateStatuses.ok;
          default:
            return (this.selectedInspection[key] !== undefined) && (this.selectedInspection[key] !== null) &&
            this.selectedInspection[key] !== '';
        }
      });
  }

  addToProject(): void {
    this.addToProjectsService.addToProject(this.selectedInspection.id)
      .pipe(finalize(() => this.requestAlreadySent = false))
      .subscribe(() => this.dialogRef.close(true));
  }
}

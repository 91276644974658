import { Injectable } from '@angular/core';
import { NavigationCancel, Router } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { debounce, debounceTime, filter, map, scan, startWith } from 'rxjs/operators';

type SpinnerAction = 'INITIAL' | 'SHOW' | 'HIDE' | 'HIDE_ON_ERROR' | 'HIDE_ON_CANCEL';

class SpinnerAccumulator {
  constructor(private readonly action: SpinnerAction,
              private readonly requestCount: number
  ) {}

  static initial(): SpinnerAccumulator {
    return new SpinnerAccumulator('INITIAL', 0);
  }

  isFirstRequest(): boolean {
    return this.action === 'SHOW' && this.requestCount === 1;
  }

  isLastRequest(): boolean {
    return this.action === 'HIDE' && this.requestCount === 0;
  }

  apply(action: SpinnerAction): SpinnerAccumulator {
    switch (action) {
      case 'SHOW':
        return new SpinnerAccumulator(action, this.requestCount + 1);
      case 'HIDE':
        return new SpinnerAccumulator(action, Math.max(this.requestCount - 1, 0));
      case 'HIDE_ON_ERROR':
        return new SpinnerAccumulator(action, 0);
      case 'HIDE_ON_CANCEL':
        return new SpinnerAccumulator(action, 0);
      /* istanbul ignore next */
      default: return;
    }
  }

  get visible(): boolean {
    return this.requestCount > 0;
  }
}

@Injectable()
export class SpinnerService {
  private static readonly firstRequestDelay = 1000;
  private static readonly lastRequestDelay = 200;

  private spinnerSubject = new Subject<SpinnerAction>();

  private spinnerObservable = this.spinnerSubject.asObservable();

  constructor(private router: Router) {
    this.router.events.pipe(
      filter(e => e instanceof NavigationCancel)
    ).subscribe(
      () => this.navigationCancel()
    );
  }

  public spinnerState = this.spinnerObservable.pipe(
    scan(
      (accumulator: SpinnerAccumulator, action: SpinnerAction) => accumulator.apply(action),
      SpinnerAccumulator.initial()
    ),
    debounce(accumulator => this.debounceSelector(accumulator)),
    map(accumulator => accumulator.visible)
  );

  requestStarted(): void {
    this.spinnerSubject.next('SHOW');
  }

  requestFinished(): void {
    this.spinnerSubject.next('HIDE');
  }

  error(): void {
    this.spinnerSubject.next('HIDE_ON_ERROR');
  }

  private navigationCancel(): void {
    this.spinnerSubject.next('HIDE_ON_CANCEL');
  }

  private debounceSelector(accumulator: SpinnerAccumulator): Observable<SpinnerAction | {}> {
    if (accumulator.isFirstRequest()) {
      return this.delayer(SpinnerService.firstRequestDelay);
    }
    if (accumulator.isLastRequest()) {
      return this.delayer(SpinnerService.lastRequestDelay);
    }
    return of({});
  }

  private delayer(dueTime: number): Observable<SpinnerAction | {}> {
    return this.spinnerObservable.pipe(
      startWith({}),
      debounceTime(dueTime)
    );
  }
}

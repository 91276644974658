<div class="yes-no">
  <div class="yes-no__yes" *ngIf="yes">
    <mat-icon>done</mat-icon>
    <span>Yes</span>
  </div>

  <div class="yes-no__no" *ngIf="!yes">
    <mat-icon>clear</mat-icon>
    <span>No</span>
  </div>
</div>

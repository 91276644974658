import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { InspectionValidation } from '@models/inspections';
import { LayoutService } from '@core/layout.service';
import { AddToProjectDialogComponent } from '@shared/add-to-projects/add-to-project-dialog/add-to-project-dialog.component';
import { AddToProjectsService } from './add-to-projects.service';


@Injectable()
export class AddToProjectsDialogService {

  constructor(
    private addToProjectsService: AddToProjectsService,
    private layoutService: LayoutService,
    private dialog: MatDialog,
    private router: Router
  ) { }


  openAddToProjectDialog(id: number, handler: () => void): void {
    this.addToProjectsService.fetchFieldsToValidate(id)
      .subscribe((inspection: InspectionValidation) => {
        const dialogRef = this.dialog.open(AddToProjectDialogComponent, {
          data: { inspection },
          panelClass: this.layoutService.isMobile ? 'cms-dialog-mobile' : 'cms-dialog',
          autoFocus: false
        });

        dialogRef.afterClosed()
          .pipe(filter(confirmed => confirmed))
          .subscribe(() => {
            handler();
            this.router.navigateByUrl(`/properties/${id}/pipeline/new`);
          });
      });
  }
}

<div class="modal-header">
  <h3>Are you sure you want to remove hold this project ?</h3>
  <mat-icon role="img"
    [mat-dialog-close]
    class="modal-header__close mat-icon notranslate material-icons mat-icon-no-color"
    aria-hidden="true">close</mat-icon>
</div>
<div class="modal-footer">
  <button color="accent"
    class="mat-flat-button mat-accent"
    (click)="unhold()"><span class="mat-button-wrapper"> Remove Hold </span>
  </button>
</div>

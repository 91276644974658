import { Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { LayoutService } from '@core/layout.service';
import { PropertyDetailsGeneral } from '@models/property/property';
import { ArchiveDialogComponent } from '@property/shared/archive-dialog/archive-dialog.component';
import { PropertyReportDialogComponent } from '@property/shared/property-report-dialog/property-report-dialog.component';
import { PropertyStateService } from '@property/shared/property-state.service';
import { ToasterService } from '@core/toaster/toaster.service';


@Component({ template: '' })
export abstract class AbstractPropertyComponent implements OnDestroy {

  property: PropertyDetailsGeneral;

  protected subscription: Subscription = new Subscription();

  protected constructor(
    protected layoutService: LayoutService,
    protected dialog: MatDialog,
    protected propertyStateService: PropertyStateService,
    protected toasterService: ToasterService
  ) {
    this.subscription.add(
      this.propertyStateService.stateObservable
        .subscribe(property => this.property = property)
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  openPropertyReportDialog(): void {
    const dialogRef = this.dialog.open(PropertyReportDialogComponent, {
      data: {
        propertyId: this.property.id,
        currentStage: this.property.stage
      },
      panelClass: this.layoutService.isMobile ? 'cms-dialog-mobile' : 'cms-dialog',
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe( attemptToDownload => {
      if (attemptToDownload){
        console.log('Successful Report');
        this.toasterService.showToaster({ message: 'Downloading is finished' });
      }
    });
  }

  openPropertyArchiveDialog(): void {
    const dialogRef = this.dialog.open(ArchiveDialogComponent, {
      data: {
        propertyId: this.property.id,
        archived: this.property.archived,
        propertyName: this.property.full_address
      },
      panelClass: this.layoutService.isMobile ? 'cms-dialog-mobile' : 'cms-dialog',
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(
      ({ isNowArchived }) => {
        console.log(`Successful Property ${this.property.archived ? 'Una' : 'A'}rchivation`);
        this.propertyStateService.updateArchivedStage(isNowArchived);
      }
    );
  }
}

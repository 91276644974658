import { Component, EventEmitter, Output } from '@angular/core';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';

import { FiltersService } from '@shared/filters/filters.service';
import { NotificationsService } from '@core/notifications/notifications.service';
import { AbstractNotificationsComponent } from '@shared/notifications/abstract-notifications';
import { ParsedPaginatedApiResponse } from '@models/response';
import { CMSMainNotification } from '@models/notification';
import { NotificationsDataService } from '@core/notifications/notifications-data.service';
import { NotificationsAssigningFilterService } from '@shared/notifications/notifications-assigning-filter.service';
import { UserService } from '../../../core/user.service';


@Component({
  selector: 'cms-notifications-panel',
  templateUrl: './notifications-panel.component.html',
  styleUrls: ['./notifications-panel.component.scss'],
  providers: [FiltersService, NotificationsAssigningFilterService]
})
export class NotificationsPanelComponent extends AbstractNotificationsComponent {

  protected limit = 10;
  private readonly notifMainLink = '/notifications';

  @Output()
  panelIsClosed = new EventEmitter<void>();

  constructor(
    filtersService: FiltersService,
    notificationsService: NotificationsService,
    notificationsDataService: NotificationsDataService,
    private router: Router,
    protected userService: UserService
  ) {
    super(filtersService, notificationsService, notificationsDataService, userService);

    this.subscription.add(
      this.router.events
        .pipe(
          filter((event: RouterEvent) => (event instanceof NavigationStart))
        )
        .subscribe(() => this.closePanel())
    );
  }

  loadNotifications(): void {
    const page = this.page + 1;
    const limit = this.limit || 10;

    this.notificationsService.fetchNotifications({ page, limit }, this.currentFilters, 'active')
      .subscribe(({ data }: ParsedPaginatedApiResponse<CMSMainNotification[]>) => this.notifications = data );
  }

  closePanel(): void {
    this.markAsRead(this.notifications);
    this.panelIsClosed.emit();
  }

  seeAllNotifs(): void {
    this.router.navigateByUrl(this.notifMainLink).then(() => this.panelIsClosed.emit());
  }

  protected markAsRead(notifsGroups: CMSMainNotification[]): void {
    if (!this.router.routerState.snapshot.url.includes(this.notifMainLink)) {
      super.markAsRead(notifsGroups);
    }
  }
}

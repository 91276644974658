import {Component, Input} from '@angular/core';

@Component({
  selector: 'cms-circle-progress',
  templateUrl: './circle-progress.component.html',
  styleUrls: ['./circle-progress.component.scss']
})
export class CircleProgressComponent {

  @Input()
  progress = 0;
  @Input()
  finished = false;
  @Input()
  diameter = 94;
  @Input()
  strokeWidth = 8;
  @Input()
  checkSize: 'small' | 'large' = 'small';
}

import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import cloneDeep from 'lodash-es/cloneDeep';
import merge from 'lodash-es/merge';

import { PropertyDetails, PropertyDetailsGeneral } from '@models/property/property';

@Injectable()
export class PropertyStateService {
  propertyId: number = null;
  private needUpdateNotesListEmitter: EventEmitter<boolean> = new EventEmitter();
  private needUpdateHistoryEmitter: EventEmitter<boolean> = new EventEmitter();
  private state: BehaviorSubject<PropertyDetailsGeneral> = new BehaviorSubject(null);
  private state$: Observable<PropertyDetailsGeneral> = this.state.asObservable();
  private flipStageClicked: Subject<boolean> = new Subject();

  get needUpdateNotesList(): EventEmitter<boolean> {
    return this.needUpdateNotesListEmitter;
  }

  get needUpdateHistory(): EventEmitter<boolean> {
    return this.needUpdateHistoryEmitter;
  }

  get stateObservable(): Observable<PropertyDetailsGeneral> {
    return this.state$;
  }
  get currentValue(): PropertyDetails {
    return this.state.getValue();
  }

  get onFlipStage(): Subject<boolean> {
    return this.flipStageClicked;
  }

  updateState(changes: PropertyDetailsGeneral): void {
    this.state.next(cloneDeep(merge(this.state.getValue(), changes)));
  }

  updateActiveStage(stage: any): void {
    this.updateState({ property: { stage }});
  }

  updateArchivedStage(archived: boolean): void {
    const prop = cloneDeep(this.currentValue);
    prop.archived = archived;
    prop.archived_at = archived ? new Date().toISOString() : null;
    this.state.next( prop );
  }
}

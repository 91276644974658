<div class="circle-progress">
  <div class="circle-progress__placeholder"
       [ngStyle]="{ 'width.px': diameter, 'height.px': diameter, 'border-width.px': strokeWidth }">
       <ng-container *ngIf="!finished">
         {{progress}}%
       </ng-container>
      <mat-icon *ngIf="finished"
                class="finished-check"
                [ngClass]="{'small-check': checkSize === 'small', 'large-check': checkSize === 'large'}">
        done
      </mat-icon>
  </div>

  <mat-progress-spinner class="circle-progress__circle"
                        mode="determinate"
                        color="accent"
                        [strokeWidth]="strokeWidth"
                        [diameter]="diameter"
                        [value]="progress">
  </mat-progress-spinner>
</div>

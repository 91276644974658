import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RequestHelperService } from '@shared/request-helper.service';
import { InspectionValidation } from '@models/inspections';
import { ApiResponseItem } from '@models/response';


@Injectable()
export class AddToProjectsService {

  constructor(
    private http: HttpClient) { }

  fetchFieldsToValidate(id: number): Observable<InspectionValidation> {
    const url = RequestHelperService.apiPaths.inspections.validate(id);
    return this.http.get<ApiResponseItem<InspectionValidation>>(url)
      .pipe(
        map(response => response.data)
      );
  }

  addToProject(id: number): Observable<void> {
    const url = RequestHelperService.apiPaths.inspections.addToProject(id);
    return this.http.put<void>(url, {});
  }

  addToInspection(id: number): Observable<void> {
      const url = RequestHelperService.apiPaths.projects.addToInspection(id);
      return this.http.put<void>(url, {});
  }
}

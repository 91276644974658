import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';

import { PropertyService } from '../../property.service';

@Component({
  selector: 'cms-add-note-dialog',
  templateUrl: './delete-note-dialog.component.html',
  styleUrls: ['./delete-note-dialog.component.scss'],
})
export class DeleteNoteDialogComponent {

  propertyId: number;
  noteId: number;
  stage: string;
  requestAlreadySent = false;

  constructor(
    private dialogRef: MatDialogRef<DeleteNoteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: { propertyId: number, stage: string, noteId: number },
    private propertyService: PropertyService
  ) {
    this.propertyId = data.propertyId;
    this.noteId = data.noteId;
    this.stage = data.stage;
  }

  deleteNote(): void {
    this.propertyService.deletePropertyNote(this.propertyId, this.noteId, this.stage)
      .pipe(finalize(() => this.requestAlreadySent = false))
      .subscribe(() => this.dialogRef.close(true));
  }

}

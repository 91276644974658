<div class="toaster">
  <div class="toaster__message" data-toaster-message>
    {{data.message}}
  </div>

  <div class="toaster__close">
    <a *ngIf="data.actionName"
       mat-button
       color="accent"
       (click)="action()"
       data-toaster-action>
      {{data.actionName}}
    </a>

    <a mat-button
       color="accent"
       (click)="close()"
       data-toaster-close>
      Dismiss
    </a>
  </div>
</div>

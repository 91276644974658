import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AngularFireMessaging } from '@angular/fire/messaging';

import { RequestHelperService } from '@shared/request-helper.service';
import { ToasterService } from '@core/toaster/toaster.service';


@Injectable({ providedIn: 'root' })
export class CMSFirebaseService {

  constructor(
    private http: HttpClient,
    public angularFireMessaging: AngularFireMessaging,
    private toasterService: ToasterService
  ) { }

  static get deviceToken(): string {
    return localStorage.getItem('cms-firebaseToken');
  }

  private static saveDeviceToken(token: string): void {
    localStorage.setItem('cms-firebaseToken', token);
  }

  private static removeDeviceTokenFromStorage(): void {
    localStorage.removeItem('cms-firebaseToken');
  }

  requestPermission(): void {
    this.angularFireMessaging.requestPermission.subscribe(
      () => this.fetchDeviceTokenOnce(),
      () => {
        CMSFirebaseService.removeDeviceTokenFromStorage();
        this.toasterService.showToaster({ message: 'You\'ve turned off notifications' });
      }
    );
  }

  fetchDeviceTokenOnce(): void {
    this.angularFireMessaging.getToken.subscribe((token: string) => {
      this.sendTokenToBackend(token)
        .subscribe(
          () => CMSFirebaseService.saveDeviceToken(token),
          (err) => {
            CMSFirebaseService.removeDeviceTokenFromStorage();
            throw err;
          }
        );
    });
  }

  deleteDeviceToken(): void {
    if (CMSFirebaseService.deviceToken) {
      this.angularFireMessaging.deleteToken(CMSFirebaseService.deviceToken)
        .subscribe(() => CMSFirebaseService.removeDeviceTokenFromStorage());
    }
  }

  private sendTokenToBackend(token: string): Observable<void> {
    const url = RequestHelperService.apiPaths.users.self;
    return this.http.put<void>(url, { device_token: token });
  }
}

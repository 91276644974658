<div class="property-report-dialog" [class.property-report-dialog__mobile]="layoutService.isMobile">
  <div class="property-report-dialog__header">
    <h3 mat-dialog-title>Export Project Report</h3>
    <button mat-icon-button [mat-dialog-close]>
      <mat-icon>close</mat-icon>
    </button>
  </div>

<mat-dialog-content class="property-report-dialog__content">

  <div class="content-subtitle content-subtitle__pages">Pages</div>

  <mat-accordion class="property-report-dialog__panel-box" hideToggle="true">
    <mat-expansion-panel class="whole-expansion-panel">
      <mat-expansion-panel-header>
        <mat-radio-button
          class="property-report-dialog__main-radio-btn"
          (click)="selectedPageType = pageTypes.currentStage"
          name="pages"
          [value]="pageTypes.currentStage"
          [checked]="selectedPageType === pageTypes.currentStage"
        >
        <span class="radio-title">
          {{pageTypes.currentStage}}
        </span>
      </mat-radio-button>
      </mat-expansion-panel-header>
    </mat-expansion-panel>

    <mat-expansion-panel class="whole-expansion-panel">
      <mat-expansion-panel-header>
        <mat-radio-button
          class="property-report-dialog__main-radio-btn"
          (click)="selectedPageType = pageTypes.whole"
          name="pages"
          [value]="pageTypes.whole"
          [checked]="selectedPageType === pageTypes.whole"
        >
        <span class="radio-title">
          {{pageTypes.whole}}
        </span>
      </mat-radio-button>
      </mat-expansion-panel-header>
    </mat-expansion-panel>

    <mat-expansion-panel [disabled]="specificStagesPanel.expanded" #specificStagesPanel>
      <mat-expansion-panel-header>
        <mat-radio-button
          class="property-report-dialog__main-radio-btn"
          (click)="selectedPageType = pageTypes.specific"
          name="pages"
          [value]="pageTypes.specific"
          [checked]="selectedPageType === pageTypes.specific"
        >
        <span class="radio-title">
          {{pageTypes.specific}}
        </span>
        </mat-radio-button>

        <button *ngIf="specificStagesPanel.expanded && (!allPreConComplete || !allConComplete || !allSetComplete)"
                mat-button
                color="accent"
                (click)="selectAll($event)">
          Select all
        </button>
        <button *ngIf="specificStagesPanel.expanded && (allPreConComplete && allConComplete && allSetComplete)"
                mat-button
                color="accent"
                (click)="deselectAll($event)">
          Deselect all
        </button>
      </mat-expansion-panel-header>

      <div class="checkboxes-container">
        <!--Pre Construction section  -->
        <section>
          <span>
            <mat-checkbox [checked]="allPreConComplete"
                          [indeterminate]="somePreConComplete()"
                          (change)="setAllPreCon($event.checked)">
              {{task.preConName}}
            </mat-checkbox>
          </span>
          <span>
            <ul>
              <li *ngFor="let preConSubtasks of task.preConSubtasks">
                <mat-checkbox [(ngModel)]="preConSubtasks.completed"
                              (ngModelChange)="updatePreConAllComplete()">
                  {{preConSubtasks.preConName}}
                </mat-checkbox>
              </li>
            </ul>
          </span>
        </section>
        <!-- Construction section -->
        <section>
          <span>
            <mat-checkbox [checked]="allConComplete"
                          [indeterminate]="someConComplete()"
                          (change)="setAllCon($event.checked)">
              {{task.conName}}
            </mat-checkbox>
          </span>
          <span class="example-list-section">
            <ul>
              <li *ngFor="let conSubtasks of task.conSubtasks">
                <mat-checkbox [(ngModel)]="conSubtasks.completed"
                              (ngModelChange)="updateConAllComplete()">
                  {{conSubtasks.conName}}
                </mat-checkbox>
              </li>
            </ul>
          </span>
        </section>
        <!-- Settlement section -->
        <section>
          <span>
            <mat-checkbox [checked]="allSetComplete"
                          [indeterminate]="someSetComplete()"
                          (change)="setAllSet($event.checked)">
              {{task.setName}}
            </mat-checkbox>
          </span>
          <span class="example-list-section">
            <ul>
              <li *ngFor="let setSubtasks of task.setSubtasks">
                <mat-checkbox [(ngModel)]="setSubtasks.completed"
                              (ngModelChange)="updateSetAllComplete()">
                  {{setSubtasks.setName}}
                </mat-checkbox>
              </li>
            </ul>
          </span>
        </section>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <div class="format-block-container">
    <h3 class="content-subtitle">Format</h3>
    <mat-radio-group class="format-radiobutton-container" aria-label="Select an option">
      <mat-radio-button (change)="selectFormat($event)" value="pdf">pdf</mat-radio-button>
      <mat-radio-button (change)="selectFormat($event)" value="xlsx" checked>xlsx</mat-radio-button>
    </mat-radio-group>
  </div>

</mat-dialog-content>

<div mat-dialog-actions class="property-report-dialog__actions" align="end">
  <button mat-flat-button
          color="accent"
          [disabled]="checkExportEmptyState()"
          (click)="clickOnExport()">
    Export
  </button>
</div>
</div>

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ExportFormat } from '@models/core';
import {
  AddNote,
  FlipStageValidationData,
  PropertiesNote,
  PropertyDetailsGeneral,
  PropertyHistory,
  PropertySetOnHoldResponse
} from '@models/property/property';
import { ApiResponseItem, ApiResponseList } from '@models/response';
import { RequestHelperService } from '@shared/request-helper.service';
import { DailyImageGeneral } from '@models/property/daily-images';
import { ToasterService } from '@core/toaster/toaster.service';

@Injectable()
export class PropertyService {

  constructor(
    private http: HttpClient,
    private requestHelperService: RequestHelperService,
    private toasterService: ToasterService
  ) { }

  static toCamelCase(str: string): string {
    let cameCasedStr = '';

    if (str) {
      str.split(' ').forEach((word, index) => {
        if (index === 0) {
          return cameCasedStr += word.toLowerCase();
        }

        cameCasedStr += `${word[0].toUpperCase()}${word.slice(1)}`;
      });
    }

    return cameCasedStr;
  }

  fetchPropertyGeneralInfo(id: number): Observable<PropertyDetailsGeneral> {
    const url = RequestHelperService.apiPaths.property.main(id);
    return this.http.get<ApiResponseItem<PropertyDetailsGeneral>>(url)
      .pipe(
        map((response: ApiResponseItem<PropertyDetailsGeneral>) => response.data)
      );
  }

  makeReport(id: number, format: ExportFormat, stages?: any): Observable<void> {
    if (ExportFormat.xlsx === format && navigator.userAgent.includes('CriOS')){
      console.warn('PropertyReportDialogComponent: downloading of excel files not supported by IOS Chrome');
      this.toasterService.showToaster({message: 'Unable to download on chrome - please try on Safari'});
      return;
    }

    let paramsObj = { file_type: format };
    if (stages) {
      paramsObj = { ...paramsObj, ...RequestHelperService.prepareFilters({stages}) };
    }

    const url = RequestHelperService.apiPaths.property.export(id);
    const params = new HttpParams({ fromObject: paramsObj });
    const fileName = `${status}-${(new Date()).toISOString()}.${format}`;

    return this.requestHelperService.exportFile(url, fileName, params);
  }

  archiveUnarchiveProperty(id: number, action: string): Observable<void> {
    const url = RequestHelperService.apiPaths.property.archiveUnarchive(id, action);
    return this.http.put<void>(url, null);
  }

  putPropertyOnHold(
    propertyId: number,
    description: string
  ): Observable<ApiResponseItem<PropertySetOnHoldResponse>> {
    return this.http.put<ApiResponseItem<PropertySetOnHoldResponse>>(
      RequestHelperService.apiPaths.property.hold(propertyId),
      { description }
    );
  }

  unholdProperty(propertyId: number): Observable<ApiResponseItem<boolean>> {
    return this.http.put<ApiResponseItem<boolean>>(
      RequestHelperService.apiPaths.property.unhold(propertyId),
      {}
    );
  }

  fetchPropertiesNotes(id: number, stage?: string): Observable<PropertiesNote[]> {
    let url = RequestHelperService.apiPaths.property.notes(id);
    let paramsObj: { [key: string]: string } = {};

    if (stage) {
      paramsObj = { stage };
      if (stage === 'New'){
        url = `${url}?stage[0]=New&stage[1]=Drawing&stage[2]=Budget`;
        delete paramsObj.stage;
      }
    }

    const params = new HttpParams({ fromObject: paramsObj });

    return this.http.get<ApiResponseItem<{ notes: PropertiesNote[] }>>(url, { params })
      .pipe(
        map((response: ApiResponseItem<{ notes: PropertiesNote[] }>) => response.data.notes)
      );
  }

  fetchPropertyHistory(id: number): Observable<PropertyHistory[]> {
    const url = RequestHelperService.apiPaths.property.history(id);

    return this.http.get<ApiResponseItem<{ histories: PropertyHistory[] }>>(url)
      .pipe(
        map((response: ApiResponseItem<{ histories: PropertyHistory[] }>) => response.data.histories)
      );
  }

  addPropertyNote(id: number, data: AddNote): Observable<void> {
    const url = RequestHelperService.apiPaths.property.notes(id);
    return this.http.post<void>(url, data);
  }

  editPropertyNote(id: number, data: AddNote, noteId: number): Observable<void> {
    const url = RequestHelperService.apiPaths.property.note(id, noteId);
    return this.http.put<void>(url, data);
  }

  deletePropertyNote(id: number, noteId: number, stage: string = null): Observable<void> {
    const url = RequestHelperService.apiPaths.property.note(id, noteId);

    return this.http.request<void>('DELETE', url, { body: { stage } });
  }

  fetchFlipStageValidationData(id: number): Observable<FlipStageValidationData[]> {
    const url = RequestHelperService.apiPaths.property.pipeline.preFlipStageValidation(id);
    return this.http.get<ApiResponseItem<{ fields: FlipStageValidationData[] }>>(url)
      .pipe(
        map((response: ApiResponseItem<{ fields: FlipStageValidationData[] }>) => response.data.fields)
      );
  }

  flipToSelectedStage(id: number, stage: { stage: string, reason?: string }): Observable<void> {
    const url = RequestHelperService.apiPaths.property.pipeline.flipStage(id);
    return this.http.put<void>(url, stage);
  }

  fetchPropertyDailyImagesCount(id: number): Observable<number> {
    const url = RequestHelperService.apiPaths.property.dailyImages.main(id);
    return this.http.get<ApiResponseList<DailyImageGeneral>>(url)
      .pipe(
        map((response: ApiResponseList<DailyImageGeneral>) => response.data && response.data.length)
      );
  }
}

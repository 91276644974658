import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PermissionsService } from '../../../../../core/permissions.service';

@Component({
  selector: 'cms-edit-on-hold-message-dialog',
  templateUrl: './edit-on-hold-message-dialog.component.html',
  styleUrls: ['./edit-on-hold-message-dialog.component.scss']
})
export class EditOnHoldMessageDialogComponent implements OnInit {
  isEditMode = false;
  form = new FormGroup({
    reasonFormControl: new FormControl('')
  });

  constructor(
    private dialogRef: MatDialogRef<EditOnHoldMessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private permissionsService: PermissionsService
  ) {
    this.form.controls.reasonFormControl.valueChanges.subscribe((v) => {
      this.form.setErrors(v?.length > 255 || v?.length < 10 ? { lengthInvalid: true } : null, {
        emitEvent: true
      });
      this.form.controls.reasonFormControl.setErrors(
        v?.length > 255 || v?.length < 10 ? { lengthInvalid: true } : null,
        { emitEvent: true }
      );
    });
  }

  ngOnInit(): void {
    this.form.controls.reasonFormControl.setValue(this.data?.property?.hold?.description);
  }

  isHavePermissionForManageOnHold(): boolean {
    return this.permissionsService.hasPermission('property')('PUT_PROPERTY_ON_HOLD')();
  }

  editOnHoldMessage(): void {
    if (
      !this.form.controls.reasonFormControl.errors &&
      this.form.controls.reasonFormControl.value?.length
    ) {
      this.dialogRef.close(this.form.controls.reasonFormControl.value);
    }
  }

  toggleEdit(): void {
    this.isEditMode = !this.isEditMode;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

@Pipe({
  name: 'dropTimeZoneDate'
})
export class DropTimeZoneDatePipe extends DatePipe implements PipeTransform {
  transform(value: any, format?: string, timezone?: string, locale?: string): string | null {
    return super.transform(
      moment(`${moment.utc(value).format('YYYY-MM-DDTHH:mm:ss')}${moment().toString().slice(-5)}`), // set users timezone
      format,
      timezone,
      locale
    );
  }
}

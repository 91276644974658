import { Component } from '@angular/core';
import { LayoutService } from '@core/layout.service';

@Component({
  selector: 'cms-without-permissions',
  templateUrl: './without-permissions.component.html',
  styleUrls: ['./without-permissions.component.scss']
})
export class WithoutPermissionsComponent {

  isDesktop = false;
  constructor(private layoutService: LayoutService) {
    this.isDesktop = layoutService.isDesktop;
  }
}

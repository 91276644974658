<div class="notification-bell">
  <div *ngIf="notificationsCount" class="notification-bell__count"
       [ngClass]="{
         'notification-bell__count-2': notificationsCount > 9 && notificationsCount < 100,
         'notification-bell__count-3': notificationsCount > 99
       }">
    {{notificationsCount}}
  </div>

  <button class="notification-bell__icon"
          mat-icon-button
          (click)="isClicked.emit()">
    <mat-icon class="main-menu__notif-icon">notifications_none</mat-icon>
  </button>
</div>

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { ConvertedFiltersValues } from '@models/filters';
import { PropertyStage } from '@models/property/property';
import { ParsedPaginatedApiResponse } from '@models/response';
import { RoleRoutes } from '@models/roles';

@Injectable({
  providedIn: 'root'
})
export class RequestHelperService {

  constructor(private http: HttpClient) { }

  static readonly baseUrl = `${environment.backend.baseURL}/api/v1`;

  static readonly apiPaths = {
    auth: {
      login: `${RequestHelperService.baseUrl}/admin/login`,
      logout: `${RequestHelperService.baseUrl}/admin/logout`,
      resetPassword: `${RequestHelperService.baseUrl}/admin/password/email`,
      canResetPassword: `${RequestHelperService.baseUrl}/admin/password/email/check`,
      changePassword: `${RequestHelperService.baseUrl}/admin/password/reset`,
    },
    projects: {
      main: `${RequestHelperService.baseUrl}/admin/projects`,
      crmProject: `${RequestHelperService.baseUrl}/admin/crm/projects`,
      addFromCRM: `${RequestHelperService.baseUrl}/admin/crm/project`,
      report: `${RequestHelperService.baseUrl}/admin/projects/completed/report`,
      addToInspection: (id: number): string => `${RequestHelperService.apiPaths.projects.main}/${id}/to-inspection`
    },
    inspections: {
      main: `${RequestHelperService.baseUrl}/admin/inspections`,
      inspectors: `${RequestHelperService.baseUrl}/admin/inspections/search/inspectors`,
      inspectionsToAssign: `${RequestHelperService.baseUrl}/admin/inspections/search/inspections`,
      assign: (id: number): string => `${RequestHelperService.apiPaths.inspections.main}/${id}/assign`,
      validate: (id: number): string => `${RequestHelperService.apiPaths.inspections.main}/${id}/to-project/validate`,
      addToProject: (id: number): string => `${RequestHelperService.apiPaths.inspections.main}/${id}/to-project`
    },
    markets: {
      main: `${RequestHelperService.baseUrl}/admin/markets`,
      settingsList: `${RequestHelperService.baseUrl}/admin/settings/markets`,
    },
    counties: {
      settingsList: `${RequestHelperService.baseUrl}/admin/settings/counties`,
      statesList: `${RequestHelperService.baseUrl}/admin/settings/counties/filters/states`,
      marketsList: `${RequestHelperService.baseUrl}/admin/settings/counties/filters/markets`,
      availableList: `${RequestHelperService.baseUrl}/admin/settings/counties/filters/available-counties`
    },
    users: {
      self: `${RequestHelperService.baseUrl}/admin/user`,
      myPermissions: `${RequestHelperService.baseUrl}/admin/permissions`,
      myProfile: `${RequestHelperService.baseUrl}/admin/profile/info`,
      changeMyPassword: `${RequestHelperService.baseUrl}/admin/profile/password/change`,
      teammates: `${RequestHelperService.baseUrl}/admin/users/team`,
      all: `${RequestHelperService.baseUrl}/admin/users/all`,
      bambooHRUsers: `${RequestHelperService.baseUrl}/admin/bamboohr/users`,
      userBambooVacations: (id: number) => `${RequestHelperService.baseUrl}/admin/users/${id}/vacations`,
      userBambooVacationsSync: `${RequestHelperService.baseUrl}/admin/bamboohr/sync`,
      settings: {
        main: `${RequestHelperService.baseUrl}/admin/settings/users`,
        user: (id: number): string => `${RequestHelperService.apiPaths.users.settings.main}/${id}`,
        userGeneral: (id: number): string => `${RequestHelperService.apiPaths.users.settings.main}/${id}/general`,
        resetPassword: (id: number): string => `${RequestHelperService.apiPaths.users.settings.main}/${id}/reset-password`,
        departmentsRoles: (id: number): string => `${RequestHelperService.apiPaths.users.settings.main}/${id}/departments-roles`,
        marketsCounties: (id: number): string => `${RequestHelperService.apiPaths.users.settings.main}/${id}/markets-counties`,
        list: {
          departmentsRoles: `${RequestHelperService.baseUrl}/admin/settings/users/departments-roles/list`,
          marketsCounties: `${RequestHelperService.baseUrl}/admin/settings/users/markets-counties/list`
        },
        filters: {
          statuses: `${RequestHelperService.baseUrl}/admin/settings/users/filters/statuses`,
          departments: `${RequestHelperService.baseUrl}/admin/settings/users/filters/departments`,
          roles: `${RequestHelperService.baseUrl}/admin/settings/users/filters/roles`,
          markets: `${RequestHelperService.baseUrl}/admin/settings/users/filters/markets`,
          counties: `${RequestHelperService.baseUrl}/admin/settings/users/filters/counties`,
        }
      }
    },
    pipelines: {
      main: `${RequestHelperService.baseUrl}/admin/pipelines`,
      settings: `${RequestHelperService.baseUrl}/admin/settings/pipelines`,
      report: `${RequestHelperService.baseUrl}/admin/pipelines/report`,
    },
    departments: {
      settingsList: `${RequestHelperService.baseUrl}/admin/settings/departments`
    },
    holidays: {
      holidaysList: `${RequestHelperService.baseUrl}/admin/settings/holidays`,
      holidaysForDatePicker: (date: string): string => `${RequestHelperService.baseUrl}/admin/holidays?date=${date}`
    },
    roles: {
      settingsList: `${RequestHelperService.baseUrl}/admin/settings/roles`,
      perms: (id: number, permType: RoleRoutes) =>
        `${RequestHelperService.baseUrl}/admin/settings/roles/${id}/permissions/${permType}`
    },
    properties: {
      main: `${RequestHelperService.baseUrl}/admin/properties`,
      crmProperty: `${RequestHelperService.baseUrl}/admin/crm/properties`,
      addFromCRM: `${RequestHelperService.baseUrl}/admin/crm/property`
    },
    property: {
      main: (id: number) => `${RequestHelperService.baseUrl}/admin/properties/${id}`,
      users: `${RequestHelperService.baseUrl}/admin/properties/users`,
      notes: (id: number) => `${RequestHelperService.baseUrl}/admin/properties/${id}/notes`,
      note: (propertyId: number, noteId) => `${RequestHelperService.baseUrl}/admin/properties/${propertyId}/notes/${noteId}`,
      history: (id: number) => `${RequestHelperService.baseUrl}/admin/properties/${id}/histories`,
      export: (id: number) => `${RequestHelperService.baseUrl}/admin/properties/${id}/pipeline/export/report`,
      archiveUnarchive: (id: number, action: string) => `${RequestHelperService.baseUrl}/admin/properties/${id}/pipeline/details/${action}`,
      hold: (propertyId: number) => `${RequestHelperService.baseUrl}/admin/properties/${propertyId}/hold`,
      unhold: (propertyId: number) => `${RequestHelperService.baseUrl}/admin/properties/${propertyId}/unhold`,
      pipeline: {
        main: (id: number) => `${RequestHelperService.baseUrl}/admin/properties/${id}/pipeline`,
        stage: (id: number, stage: PropertyStage) => `${RequestHelperService.baseUrl}/admin/properties/${id}/pipeline/${stage}`,
        purchasingUsers: (id: number) => `${RequestHelperService.baseUrl}/admin/properties/${id}/purchasings`,
        projectsOfPurchasingUser: (propertyId: number, userId: number) => `${RequestHelperService.baseUrl}/admin/properties/${propertyId}/users/purchasings?user_id=${userId}`,
        updatePurchasingUser: (id: number) => `${RequestHelperService.baseUrl}/admin/properties/${id}/purchasing`,
        validatePurchasing: (propertyId: number) => `${RequestHelperService.baseUrl}/admin/properties/${propertyId}/purchasing/validate`,
        validatePTOConflict: (propertyId: number) => `${RequestHelperService.baseUrl}/admin/properties/${propertyId}/validate_pto`,
        preFlipStageValidation: (id: number) => `${RequestHelperService.baseUrl}/admin/properties/${id}/flip-stage/validate`,
        flipStage: (id: number) => `${RequestHelperService.baseUrl}/admin/properties/${id}/flip-stage`,
        preFinishValidation: (id: number) => `${RequestHelperService.baseUrl}/admin/properties/${id}/finish/validate`,
        finishProperty: (id: number) => `${RequestHelperService.baseUrl}/admin/properties/${id}/finish`,
        purchasingContactList: () => `${RequestHelperService.baseUrl}/admin/purchasing`
      },
      dailyImages: {
        main: (id: number) => `${RequestHelperService.baseUrl}/admin/properties/${id}/daily-images`,
        onDate: (propertyId: number, diId: number) => `${RequestHelperService.baseUrl}/admin/properties/${propertyId}/daily-images/${diId}`,
        media: (propertyId: number, dateId: number) => `${RequestHelperService.baseUrl}/admin/properties/${propertyId}/daily-images/${dateId}/media`,
        mediaItem: (propertyId: number, dateId: number, mediaId: number) => `${RequestHelperService.baseUrl}/admin/properties/${propertyId}/daily-images/${dateId}/media/${mediaId}`,
        comments: (propertyId: number, dateId: number, mediaId: number) => `${RequestHelperService.baseUrl}/admin/properties/${propertyId}/daily-images/${dateId}/media/${mediaId}/comments`,
        comment: (propertyId: number, dateId: number, mediaId: number, commentId: number) =>
          `${RequestHelperService.baseUrl}/admin/properties/${propertyId}/daily-images/${dateId}/media/${mediaId}/comments/${commentId}`,
        setFeatureImage: (propertyId: number, dailyImageId: number, mediaId: number) =>
          `${RequestHelperService.baseUrl}/admin/properties/${propertyId}/daily-images/${dailyImageId}/media/${mediaId}/featured`,
        replaceMediaFile: (propertyId: number, visitId: number, mediaId: number) =>
          `${RequestHelperService.baseUrl}/admin/properties/${propertyId}/daily-images/${visitId}/media/${mediaId}/replace`,
      },
      inspection: {
        stagesReadyList: (propertyId: number) => `${RequestHelperService.baseUrl}/admin/properties/${propertyId}/inspection`,
        inspectionInfo: (propertyId: number) => `${RequestHelperService.baseUrl}/admin/properties/${propertyId}/inspections/report`,
        preInspection: (propertyId: number) => `${RequestHelperService.baseUrl}/admin/properties/${propertyId}/inspections/pre-inspections`,
        coversheet: (propertyId: number) => `${RequestHelperService.baseUrl}/admin/properties/${propertyId}/inspections/coversheet`,
        coversheetMultiselectsValues: (propertyId: number) =>
          `${RequestHelperService.baseUrl}/admin/properties/${propertyId}/inspections/coversheet/multi-selects`,
        interior: (propertyId: number) => `${RequestHelperService.baseUrl}/admin/properties/${propertyId}/inspections/interior`,
        interiorMultiselectsValues: (propertyId: number) =>
          `${RequestHelperService.baseUrl}/admin/properties/${propertyId}/inspections/interior/multi-selects`,
        exterior: (propertyId: number) => `${RequestHelperService.baseUrl}/admin/properties/${propertyId}/inspections/exterior`,
        exteriorMultiselectsValues: (propertyId: number) =>
          `${RequestHelperService.baseUrl}/admin/properties/${propertyId}/inspections/exterior/multi-selects`,
        worksheet: (propertyId: number) => `${RequestHelperService.baseUrl}/admin/properties/${propertyId}}/inspections/worksheet`
      }
    },
    notifications: {
      unreadCount: `${RequestHelperService.baseUrl}/admin/notifications/count`,
      teamMembers: `${RequestHelperService.baseUrl}/admin/notifications/filters/team-members`,
      markAsRead: `${RequestHelperService.baseUrl}/admin/notifications/read`,
      available: `${RequestHelperService.baseUrl}/admin/notifications/available`,
      archived: `${RequestHelperService.baseUrl}/admin/notifications/archived`
    },
    workScheduler: {
      list: (dateStart: string, dateEnd: string) => `${RequestHelperService.baseUrl}/admin/work-schedule?dateStart=${dateStart}&dateEnd=${dateEnd}`,
      project: (id: number) => `${RequestHelperService.baseUrl}/admin/work-schedule/projects/${id}`
    },
    dailyImagesScheduler: {
      list: (dateStart: string, dateEnd: string) => `${RequestHelperService.baseUrl}/admin/daily-images?dateStart=${dateStart}&dateEnd=${dateEnd}`,
      ownCountiesList: `${RequestHelperService.baseUrl}/admin/counties/me`,
      ownMarketsList: `${RequestHelperService.baseUrl}/admin/markets/me`
    }
  };

  static parseApiResponseWithPagination<T>(response):
    ParsedPaginatedApiResponse<T> {
    const { per_page, current_page, total } = response.meta || response;

    return {
      data: response.data,
      size: total,
      per: per_page,
      current: current_page,
      prev: current_page > 1 ? current_page - 1 : null,
      next: current_page * per_page < total ? current_page + 1 : null
    };
  }

  static prepareFilters(notPreparedFilters: ConvertedFiltersValues): ConvertedFiltersValues {
    const filters = { ...notPreparedFilters };

    Object.keys(filters).forEach(key => {
      const value = filters[key];
      if (Array.isArray(value)) {
        filters[`${key}[]`] = value;
        delete filters[key];
      }
    });

    return filters;
  }

  exportFile(filePath: string, fileName: string, params?: HttpParams): Observable<void> {
    let options: any = { responseType: 'blob' };
    if (params) {
      options = { ...options, params };
    }

    return this.http.get(filePath, options).pipe(
      map((blob) => {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
      })
    );
  }
}

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';

@Component({
  selector: 'cms-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {
  @Input()
  appearance: MatFormFieldAppearance = 'outline';
  @Input()
  withImage = false;
  @Input()
  imageLinkKey = null;
  @Input()
  label: string;
  @Input()
  customArrowIcon: string;
  @Input()
  selectFormControl: FormControl = new FormControl();
  @Input()
  values: any[];
  @Input()
  defaultSelectedValue: any;
  @Input()
  labelMapper: (item: any) => string;
  @Input()
  needPermission = false;
  @Output()
  selectionChanged = new EventEmitter<any>();
  @Input()
  compareWith: (a, b) => boolean = (a, b) => a == b

  ngOnInit(): void {
    if (this.defaultSelectedValue) {
      this.selectFormControl.setValue(this.defaultSelectedValue);
    }
  }

  onSelectionChanged(value: any): void {
    this.selectionChanged.emit(value);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

import { LayoutService } from '@core/layout.service';
import { StatusTab } from '@models/status';
import { HeaderTabsService } from '@shared/header-with-tabs/header-tabs.service';

@Component({
  selector: 'cms-header-with-tabs',
  templateUrl: './header-with-tabs.component.html',
  styleUrls: ['./header-with-tabs.component.scss']
})
export class HeaderWithTabsComponent implements OnInit {
  currentStatus: any;

  selectFormControl: FormControl = new FormControl('');

  @Input()
  title = '';

  @Input()
  statusesTabs: StatusTab[];

  tabsMapper: (tab: StatusTab) => string = (tab: StatusTab) => tab.value;

  get isDesktop(): boolean {
    return this.layoutService.isDesktop;
  }
  get isMobile(): boolean {
    return this.layoutService.isMobile;
  }

  constructor(
    private headerTabsService: HeaderTabsService,
    private router: Router,
    private layoutService: LayoutService
  ) {}

  ngOnInit(): void {
    this.headerTabsService.currentStatus().subscribe((currentStatus: any) => {
      setTimeout(() => {
        this.currentStatus = currentStatus;

        const currentStatusTab: StatusTab = this.statusesTabs.find(
          (tab: StatusTab) => tab.value === currentStatus
        );
        this.selectFormControl.setValue(currentStatusTab);
      });
    });
  }

  navigateTo(link: string): void {
    this.router.navigateByUrl(link).then();
  }

  isShowTitle(title: string): boolean {
    const restricted = [
      'Properties',
      'Inspections',
      'Projects',
      'Pipeline',
      'Work Schedule',
      'Daily Images'
    ];
    return restricted.every((name) => name !== title);
  }
}

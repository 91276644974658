<div class="vertical-nav-bar">
  <mat-nav-list color="accent">
    <ng-container *ngFor="let navLink of navLinks">
      <a *ngIf="navLink.permission"
         mat-list-item
         cmsAccessControl
         [moduleType]="navLink.permission?.moduleType"
         [accessType]="navLink.permission?.accessType"
         [routerLink]="navLink.path"
         [routerLinkActive]="'active'">
        <span matLine>{{navLink.label}}</span>
      </a>
      <a *ngIf="!navLink.permission"
         mat-list-item
         [routerLink]="navLink.path"
         [routerLinkActive]="'active'">
        <span matLine>{{navLink.label}}</span>
      </a>
    </ng-container>
  </mat-nav-list>
</div>

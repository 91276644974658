import { Component, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, Observable, Subject } from 'rxjs';

import { LayoutService } from '@core/layout.service';
import { PropertyDetailsGeneral } from '@models/property/property';
import { NavLink } from '@models/core';
import { JobType } from '@models/job-type';
import { PropertyRoutes, PropertyTitles } from '@models/property/property';
import { UserToValidateStatuses } from '@models/user/user';
import { PropertyStateService } from './property-state.service';
import { ChangeStageDialogComponent } from '@property/shared/change-stage-dialog/change-stage-dialog.component';
import { PropertyService } from '@property/shared/property.service';
import { FormControl } from '@angular/forms';
import { ReasonOfOnHoldDialogComponent } from '../pipeline/shared/reason-of-on-hold-dialog/reason-of-on-hold-dialog.component';
import { DoesUserShureToUnHoldDialogComponent } from '../pipeline/shared/does-user-shure-to-un-hold-dialog/does-user-shure-to-un-hold-dialog.component';
import { EditOnHoldMessageDialogComponent } from '../pipeline/shared/edit-on-hold-message-dialog/edit-on-hold-message-dialog.component';
import { PermissionsService } from '../../../core/permissions.service';


@Component({ template: '' })
export abstract class AbstractPropertyHeaderComponent implements OnDestroy {

  jobTypes = JobType;
  navLinks: NavLink[] = [];

  archived: boolean;
  finished: boolean;
  isProject: boolean;
  onHoldControl = new FormControl();
  readonly okUserStatus = UserToValidateStatuses.ok;

  property: PropertyDetailsGeneral;

  private readonly placeholderImageUrl = '/assets/images/property-placeholder.svg';

  get bgImageStyle(): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(`url(${(this.property && this.property.photo) || this.placeholderImageUrl})`);
  }

  protected subscription: Subscription = new Subscription();

  protected constructor(
    public router: Router,
    protected sanitizer: DomSanitizer,
    protected dialog: MatDialog,
    protected layoutService: LayoutService,
    protected propertyStateService: PropertyStateService,
    protected propertyService: PropertyService,
    protected permissionsService: PermissionsService
  ) {
    this.subscription.add(
      this.propertyStateService.stateObservable
        .subscribe((property) => {
          this.property = property;
          this.archived = property.archived;
          this.finished = property.finished;
          this.isProject = property.is_project;
          this.onHoldControl.setValue( this.property?.hold !== null );
          this.propertyService.fetchPropertyDailyImagesCount(this.property.id)
            .subscribe((imagesCount: number) => this.formNavLinks(imagesCount));

          if (this.property.finished) {
            this.property.progress = 100;
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  formNavLinks(imagesCount: number): void {
    const prefix = this.layoutService.isTablet ? `${this.property.id}/` : '';
    let links: NavLink[] = [];

    if (this.property?.is_project) {
      links.push({ label: PropertyTitles.pipeline, path: `${prefix}${PropertyRoutes.pipeline}` });
    }

    if (imagesCount) {
      links.push({ label: PropertyTitles.dailyImages, path: `${prefix}${PropertyRoutes.dailyImages}` });
    }

    if (!this.layoutService.isDesktop) {
      links = [
        ...links,
        { label: PropertyTitles.notes, path: `${prefix}${PropertyRoutes.notes}` },
        { label: PropertyTitles.history, path: `${prefix}${PropertyRoutes.history}` },
      ];
    }

    this.navLinks = links;
  }

  openChangeStageDialog(): void {
    this.propertyStateService.onFlipStage.next(true);
    const dialogRef = this.dialog.open(ChangeStageDialogComponent, {
      data: { propertyId: this.property.id, activeStage: this.property.stage },
      panelClass: this.layoutService.isMobile ? 'cms-dialog-mobile' : 'cms-dialog',
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(() => console.log('The dialog was closed'));
  }

  openHoldDialog(): Observable<boolean> {
    const dialogRef = this.dialog.open(ReasonOfOnHoldDialogComponent, {
      data: { propertyId: this.property.id, activeStage: this.property.stage },
      panelClass: this.layoutService.isMobile ? 'cms-dialog-mobile' : 'cms-dialog',
      autoFocus: false
    });
    const result = new Subject<boolean>();
    dialogRef.afterClosed().subscribe((onHoldDescription: string) => {
      console.log('The dialog was closed');
      if (onHoldDescription){
        this.propertyService.putPropertyOnHold(this.property.id, onHoldDescription).subscribe(response => {
          if ( response?.status === 200 ){
            this.updatePropertiesHoldField( response?.data );
            result.next(true);
          }else{
            result.next(false);
          }

        },
        err => result.next(false)
        );
      }else{
        result.next(false);
      }
    });
    return result;
  }

  openUnHoldDialog(): Observable<boolean> {
    const dialogRef = this.dialog.open(DoesUserShureToUnHoldDialogComponent, {
      data: { propertyId: this.property.id, activeStage: this.property.stage },
      panelClass: this.layoutService.isMobile ? 'cms-dialog-mobile' : 'cms-dialog',
      autoFocus: false
    });
    const result = new Subject<boolean>();
    dialogRef.afterClosed().subscribe(isNeedUnhold => {
      console.log('The dialog was closed');
      if (isNeedUnhold){
        this.propertyService.unholdProperty(this.property.id).subscribe(response => {
          if ( response?.status === 200 ){
            this.updatePropertiesHoldField( null );
            result.next(false);
          }else{
            result.next(true);
          }
        },
        err => result.next(false));
      }else{
        result.next(true);
      }
    });
    return result;
  }

  openEditOnHoldDialog(): Observable<boolean> {
    const dialogRef = this.dialog.open(EditOnHoldMessageDialogComponent, {
      data: { property: this.property },
      panelClass: this.layoutService.isMobile ? 'cms-dialog-mobile' : 'cms-dialog',
      autoFocus: false
    });
    const result = new Subject<boolean>();
    dialogRef.afterClosed().subscribe(editedMessage => {
      if ( editedMessage ){
        this.propertyService.putPropertyOnHold( this.property.id , editedMessage).subscribe(response => {
          if (response?.status === 200){
            this.updatePropertiesHoldField( response?.data );
            result.next(true);
          }else{
            result.next(true); // previous -> true coz can edit when true
          }
        },
        err => result.next(true));
      }else{
        result.next( true );
      }
    } );
    return result;
  }

  updatePropertiesHoldField(newHold): void{
    const newProperty = Object.assign({}, this.property);
    newProperty.hold = newHold; // TODO delete user
    this.propertyStateService.updateState( newProperty );
  }

  toggleOnChange(): void{
    const final = this.onHoldControl.value ?  this.openUnHoldDialog() : this.openHoldDialog();
    final.subscribe( finalToggleValue => {
      this.onHoldControl.setValue( finalToggleValue );
      this.propertyStateService.needUpdateNotesList.next(true);
      this.propertyStateService.needUpdateHistory.next(true);
    } );
    this.onHoldControl.setValue( !this.onHoldControl.value ); // set old value while request proceeds
  }
}

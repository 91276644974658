<div class="without-permissions">
  <cms-main-header [class.without-permissions__header]="isDesktop"></cms-main-header>

  <div class="page-content without-permissions__content">
    <div class="without-permissions__logo-box">
      <img src="/assets/images/logo3.png"
           alt="logo"
           [routerLink]="['/']">
    </div>
    <h2>You don't have permission to view the content</h2>
    <p>Please contact your administrator</p>
  </div>
</div>

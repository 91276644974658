import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { ToasterService } from '@core/toaster/toaster.service';

function isFirebaseSDKError(error: any): boolean {
  return error?.toString()?.includes('FirebaseError');
}

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {

  private static readonly errorMessage = 'Something went wrong. Try again or refresh the page.';
  private static readonly exceptionsNotShowMessage = [
    isFirebaseSDKError
  ];

  constructor(private readonly toasterService: ToasterService,
              private readonly ngZone: NgZone) {
    super();
  }

  handleError(error: any): void {
    this.ngZone.run(() => setTimeout(() => {
      if (this.isNotShow(error)){
        console.warn('Silent. GlobalErrorHandler.handleError', error);
      }else{
        console.error('GlobalErrorHandler.handleError', error);
        this.toasterService.showToaster({ message: GlobalErrorHandler.errorMessage });
      }
    }));
  }

  isNotShow(error: string): boolean {
    return GlobalErrorHandler.exceptionsNotShowMessage.some((exception) => exception(error));
  }
}

import { Component } from '@angular/core';

import { UserService } from '@core/user.service';
import { AssigningFilterComponent } from '@shared/filters/assigning-filter/assigning-filter.component';
import { NotificationsService } from '@core/notifications/notifications.service';
import { AssigningFilterValues } from '@models/filters';
import { NotificationsAssigningFilterService } from '@shared/notifications/notifications-assigning-filter.service';

@Component({
  selector: 'cms-notifications-assigning-filter',
  templateUrl: './notifications-assigning-filter.component.html',
  styleUrls: [
    '../../../shared/filters/assigning-filter/assigning-filter.component.scss',
    './notifications-assigning-filter.component.scss'
  ]
})
export class NotificationsAssigningFilterComponent extends AssigningFilterComponent {

  title = 'Related to';
  teamMemberParent = 'team-members';

  defaultAssignTo: AssigningFilterValues =
    NotificationsAssigningFilterService.getDefaultValue('all', 'team', 'me');
  assignedTo: AssigningFilterValues = this.defaultAssignTo;

  constructor(
    assigningFilterService: NotificationsAssigningFilterService,
    userService: UserService,
    private notificationsService: NotificationsService
  ) {
    super(assigningFilterService, userService);
  }

  getTeamMembers(): void {
    this.notificationsService.fetchTeammates()
      .subscribe(teamMembers => {
        this.teamMembers = teamMembers;

        this.selectTeamMembers(teamMembers);
      });
  }
}

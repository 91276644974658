import { Component } from '@angular/core';

@Component({
  selector: 'cms-content-or-absent-in-crm',
  templateUrl: './content-or-absent-in-crm.component.html',
  styleUrls: ['./content-or-absent-in-crm.component.scss']
})
export class ContentOrAbsentInCRMComponent {
  constructor() {}
}

import { JobType } from '@models/job-type';
import { UserInfoResponse } from '@models/user/user';
import { BaseEntityInfo, NavLink } from '@models/core';

export enum PropertyStage {
  new = 'New',
  drawing = 'Drawing',
  budget = 'Budget',
  cashout = 'Cashout',
  constructionStarted = 'Construction Started',
  purchased = 'Purchased',
  listed = 'Listed',
  constructionCompleted = 'Construction Completed',
  hiReceived = 'HI Received',
  hiCompleted = 'HI Completed',
  settled = 'Settled'
}

export enum PropertyStageRoutes {
  new = 'new',
  drawing = 'drawing',
  budget = 'budget',
  cashout = 'cashout',
  constructionStarted = 'construction-started',
  purchased = 'purchased',
  listed = 'listed',
  constructionCompleted = 'construction-completed',
  hiReceived = 'hi-received',
  hiCompleted = 'hi-completed',
  settled = 'settled'
}

export enum PropertyType {
  condo = 'Condo',
  singleFamily = 'Single Family',
  th = 'Town House',
  smallTh = 'Small TH'
}

export enum PropertyRoutes {
  pipeline = 'pipeline',
  dailyImages = 'daily-images',
  inspection = 'inspection',
  notes = 'notes',
  history = 'history',
  noData = 'no-data-to-display'
}

export enum PropertyTitles {
  pipeline = 'Pipeline',
  dailyImages = 'Daily Images',
  inspection = 'Inspection',
  notes = 'Notes',
  history = 'History'
}

export interface PropertiesNote extends AddNote, BaseEntityInfo {
  user: UserInfoResponse;
  sender_type?: {
    name: string;
    type: number;
  };
}

export interface FlipStageValidationData {
  isActive: boolean;
  name: string;
  required: string[];
}

export interface FinishProjectValidationData {
  isActive: boolean;
  required: string[];
}

export interface AddNote {
  stage: string;
  text: string;
}

export interface PropertyHistory extends BaseEntityInfo {
  user: UserInfoResponse;
  text: PropertyHistoryText;
}

export interface PropertyHistoryText {
  action: string;
  field: string;
  old_value?: string;
  new_value?: string;
  values?: ImageHistoryText;
  textArray?: { text: string; isBold: boolean; }[];
}

export interface ImageHistoryText {
  image_number: number;
  visit_date_time: string;
}

export interface PropertyDetailsGeneral {
  [key: string]: any;
}

export interface PropertyPipelineGeneral {
  [key: string]: any;
}

export interface PropertyDetails {
  [key: string]: any;
}

export interface PropertyUserProjectRole extends BaseEntityInfo {
  order_number: number;
  name: string;
  users: PropertyUserWithProjects[];
}

export interface PropertyUserWithProjects extends Exclude<UserInfoResponse, 'email' | 'avatar'> {
  projects?: PropertyUserProject[];
  projects_count: number;
}

export interface PropertyUserProject {
  id: number;
  full_address: string;
  job_type: JobType;
  budget_cap: string;
  budget: PropertyUserProjectBudget;
  cashout: PropertyUserProjectCashout;
  isExpanded?: boolean;
  purchasing: number;
  purchasingQueueParentsCount?: number;
  parent_id: number;
}

interface PropertyUserProjectBudget extends BaseEntityInfo {
  budget_start_date: string;
  estimated_end_date: string;
}

interface PropertyUserProjectCashout extends BaseEntityInfo {
  job_start_date: string;
}

export interface PurchasingDataToUpdate {
  user_id: number;
  project_id?: number;
}

export interface SelectedPurchasingData {
  user: PropertyUserWithProjects;
  project?: PropertyUserProject;
}

export interface PropertyUser extends UserInfoResponse {
  roles?: PropertyUserRole[];
  days_off?: UserDateRange[];
}

export interface UserDateRange {
  date_start: string;
  date_end: string;
}

interface PropertyUserRole extends BaseEntityInfo {
  order_number: number;
  name: string;
  report_to: any;
  department_id: number;
  pivot: RolePivot;
}

interface RolePivot {
  user_id: number;
  role_id: number;
}

export enum PropertyUserRoles {
  fieldUser = 'Field User',
  archit = 'Architect',
  purchUser = 'Purchasing User',
  purchManager = 'Sr. Purchasing Manager'
}

export interface PropertyPipelineNavLink extends NavLink {
  label: PropertyStage;
  cutLabel: string;
}

export interface PipelineMenuItem {
  label: string;
  stages: PropertyPipelineNavLink[];
}

export interface PropertySetOnHoldResponse{
  created_at: string;
  description: string;
  id: number;
  project_id: number;
  updated_at: string;
  user_id: number;
}

<div class="add-note-dialog">
  <div class="add-note-dialog__header">
    <h3 mat-dialog-title>{{title}}</h3>
    <button mat-icon-button [mat-dialog-close]>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-dialog-content class="add-note-dialog__content">
    <div class="add-note-dialog__note-textarea-box">
      <textarea class="add-note-dialog__note-textarea"
                matInput
                [(ngModel)]="noteValue">
      </textarea>
    </div>
  </mat-dialog-content>

  <div mat-dialog-actions class="add-note-dialog__actions" align="end">
    <button mat-flat-button
            color="accent"
            [disabled]="checkValidNote()||requestAlreadySent"
            (click)="handleNote()">
      {{mainBtnText}}
    </button>
  </div>
</div>

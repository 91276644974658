<div class="notes-history-container">
  <mat-tab-group animationDuration="0ms"
                 color="accent"
                 (selectedTabChange)="changeActiveTab($event)">
    <mat-tab *ngFor="let tab of tabs" [label]="tab"></mat-tab>
  </mat-tab-group>

  <cms-property-notes *ngIf="activeTab === propertyNotes.notes"></cms-property-notes>
  <cms-property-history *ngIf="activeTab === propertyNotes.history"></cms-property-history>
</div>

import { AfterViewInit, Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[cmsIsOutOfBound]',
  exportAs: 'outOfBound'
})
export class IsOutOfBoundDirective implements AfterViewInit {

  isOutOfBound = false;

  private domElement: any;

  constructor(private elementRef: ElementRef) {
    this.domElement = this.elementRef.nativeElement;
  }

  ngAfterViewInit(): void {
    this.checkThatToolTipIsNeeds();
  }

  @HostListener('window:resize', ['$event.target'])
  checkThatToolTipIsNeeds(): void {
    this.isOutOfBound = this.domElement.offsetWidth < this.domElement.scrollWidth;
  }
}

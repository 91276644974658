<div class="notes-block-container">
  <button mat-button
          class="add-note-btn"
          color="accent"
          cmsAccessControl
          [moduleType]="moduleType"
          [accessType]="addAccessType"
          (click)="openAddNoteDialog()">
    + ADD NOTE
  </button>

  <div class="notes-block-container__content" [class.notes__without-add-btn]="!canAddNote">
    <div class="single-note-container" *ngFor="let note of notes">
      <div class="single-note-container__header">
        <div class="single-note-container__header-main flex-1" [class.single-note-container__with-actions]="canBeUpdated">
          <div class="name-container">
            <cms-avatar class="avatar" [avatarUrl]="note.user?.avatar"></cms-avatar>
            <div class="name">{{note.user?.full_name}}</div>
          </div>
          <div class="date">{{note.updated_at | date:'short'}}</div>
        </div>

        <div class="single-note-container__actions-box"
             cmsAccessControl
             [moduleType]="moduleType"
             [accessType]="['EDIT_NOTES', 'DELETE_NOTES']">
          <button class="single-note-container__action-btn"
                  mat-icon-button
                  cmsAccessControl
                  [moduleType]="moduleType"
                  [accessType]="editAccessType"
                  (click)="openAddNoteDialog(note)">
            <mat-icon #editTooltip="matTooltip"
                      matTooltip="Edit note"
                      matTooltipPosition="above">
              edit
            </mat-icon>
          </button>

          <button class="single-note-container__action-btn"
                  mat-icon-button
                  cmsAccessControl
                  [moduleType]="moduleType"
                  [accessType]="deleteAccessType"
                  (click)="openDeleteNoteDialog(note.id)">
            <mat-icon #deleteTooltip="matTooltip"
                      matTooltip="Delete note"
                      matTooltipPosition="above">
              delete_outline
            </mat-icon>
          </button>
        </div>
      </div>

      <div class="single-note-container__content">{{note.text}}</div>
    </div>
  </div>
</div>

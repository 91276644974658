<div *ngIf="isMenuDropdownShown"
     class="main-menu-dropdown__overlay"
     (click)="isMenuDropdownShown = false">
</div>

<div class="main-menu-dropdown__box">
  <img class="main-menu-dropdown__box__logo"
       src="/assets/images/small-logo-with-CMS-text.svg"
       alt="logo"
       (click)="menuService.toggleMainMenu()">

  <div *ngIf="activeMenuItem" class="main-menu-dropdown" (click)="toggleMenuDropDown()">
    <mat-icon class="material-icons-outlined">{{activeMenuItem.icon.name}}</mat-icon>
    <span class="main-menu-dropdown__name">{{activeMenuItem.name}}</span>
    <mat-icon *ngIf="isMenuDropdownShown" class="main-menu-dropdown__icon">
      keyboard_arrow_up
    </mat-icon>
    <mat-icon *ngIf="!isMenuDropdownShown" class="main-menu-dropdown__icon">
      keyboard_arrow_down
    </mat-icon>
  </div>
  <mat-accordion *ngIf="isMenuDropdownShown" class="main-menu-dropdown__content" [displayMode]="'flat'" [multi]="true" hideToggle>
    <mat-expansion-panel *ngFor="let group of menuService.menu"
                         class="main-menu__panel"
                         [expanded]="true"
                         (opened)="group.isExpanded = true"
                         (closed)="group.isExpanded = false">
      <mat-expansion-panel-header class="main-menu__panel-header">
        <mat-panel-title class="main-menu__panel-title">
          {{group.name}}
        </mat-panel-title>

        <mat-icon *ngIf="!group.isExpanded" class="main-menu__icon">
          arrow_drop_down
        </mat-icon>
        <mat-icon *ngIf="group.isExpanded" class="main-menu__icon">
          arrow_drop_up
        </mat-icon>
      </mat-expansion-panel-header>
      <div class="list-container">
        <mat-list class="main-menu__list" role="list">
          <mat-list-item *ngFor="let item of group.items"

                         cmsAccessControl
                         [moduleType]="item.permission"

                         class="main-menu__list-item"
                         role="listitem"
                         [class.main-menu__list-item__active]="menuService.isItemActive(item.name)"
                         matRipple
                         (click)="toggleMenuDropDown()">
            <a class="main-menu__link" [routerLink]="item.url">
              <mat-icon *ngIf="!item.icon.isSvg"
                        class="main-menu__item-icon"
                        fontSet="material-icons-outlined">
                {{item.icon.name}}
              </mat-icon>
              <mat-icon *ngIf="item.icon.isSvg"
                        class="main-menu__item-icon"
                        fontSet="material-icons-outlined"
                        [svgIcon]="item.icon.name">
              </mat-icon>
              <span>{{item.name}}</span>
            </a>
          </mat-list-item>
        </mat-list>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>

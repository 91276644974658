import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cms-selectable-chips',
  templateUrl: './selectable-chips.component.html',
  styleUrls: ['./selectable-chips.component.scss']
})
export class SelectableChipsComponent {
  @Input()
  allowDeselect = true;
  @Input()
  chips: any[];
  @Input()
  labelMapper: (item: any) => string;
  @Input()
  identifier: string;
  @Output()
  chipSelected = new EventEmitter<any>();

  selectedChip: any;

  @Input()
  set defaultSelected(chip: any) {
    this.defaultSelectedChip = chip;
    this.selectedChip = this.defaultSelectedChip;
  }
  get defaultSelected(): any {
    return this.defaultSelectedChip;
  }
  private defaultSelectedChip: any;

  isSelected(chip: any): boolean {
    return this.identifier?.length
      ? chip && this.selectedChip && chip[this.identifier] === this.selectedChip[this.identifier]
      : chip === this.selectedChip;
  }

  selectChip(chip: any): void {
    this.selectedChip = chip;
    this.chipSelected.emit(chip);
  }

  toggleChip(chip: any): void {
    if (this.isSelected(chip)){
      this.selectedChip = null;
      this.chipSelected.emit(null);
    }else{
      this.selectedChip = chip;
      this.chipSelected.emit(chip);
    }
  }
}

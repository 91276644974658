import { Component, OnDestroy } from '@angular/core';
import isEmpty from 'lodash-es/isEmpty';

import { PropertyHistory, PropertyHistoryText } from '@models/property/property';
import { PropertyStateService } from '../../property-state.service';
import { PropertyService } from '../../property.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'cms-property-history',
  templateUrl: './property-history.component.html',
  styleUrls: ['../shared-property-notes.scss', './property-history.component.scss']
})
export class PropertyHistoryComponent implements OnDestroy {
  history: PropertyHistory[] = [];
  private subscription = new Subscription();
  private propertyId: number;

  constructor(
    private propertyService: PropertyService,
    private propertyStateService: PropertyStateService
  ) {
    this.propertyId = propertyStateService.propertyId;
    this.fetchHistory();
    this.subscription.add(
      this.propertyStateService.needUpdateHistory
        .pipe(filter((isNeedUpdate) => isNeedUpdate))
        .subscribe(() => {
          this.propertyId = propertyStateService.propertyId;
          this.fetchHistory();
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  fetchHistory(): void {
    this.propertyService.fetchPropertyHistory(this.propertyId).subscribe((history) => {
      history.forEach((item: PropertyHistory) => {
        item.text.textArray = [];

        if (item.text && !isEmpty(item.text.values) && item.text.field) {
          const textArray = item.text.field.split(' ');
          item.text.textArray = textArray.map((text: string) => {
            const textWithoutDots = text.replace(/:/g, '');
            const textItem = { text: textWithoutDots, isBold: false };

            if (Object.keys(item.text.values).includes(textWithoutDots)) {
              textItem.isBold = true;
              textItem.text = `${item.text.values[textWithoutDots]}`;
            }

            return textItem;
          });
        }
      });

      this.history = history;
    });
  }

  isDate(value: string): boolean {
    return isNaN(Number(value)) && !isNaN(Date.parse(value));
  }

  isOnHoldTypeText(text: PropertyHistoryText): boolean {
    return text?.action === 'ACTION_UNHOLD_PROPERTY' || text?.action === 'ACTION_HOLD_PROPERTY';
  }

  isSummaryOfScopeTypeText(text: PropertyHistoryText): boolean {
    return (
      text?.action === 'ACTION_CREATED_NOTE' ||
      text?.action === 'ACTION_UPDATED_NOTE' ||
      text?.action === 'ACTION_DELETED_NOTE'
    );
  }
}

<div *ngIf="user?.status !== possibleStatuses.ok" class="user-check">
  <ng-container *ngIf="user?.full_name">
    <span class="user-check__user-name">{{user?.full_name}}</span>

    <mat-icon #crmUserTooltip="matTooltip"
              [matTooltip]="possibleMessages[user?.status]"
              matTooltipPosition="below">
      error_outline
    </mat-icon>
  </ng-container>

  <ng-container *ngIf="!user?.full_name">
    <mat-icon>error_outline</mat-icon>
    <span>{{possibleMessages[user?.status]}}</span>
  </ng-container>
</div>

<ng-content *ngIf="user?.status === possibleStatuses.ok"></ng-content>
